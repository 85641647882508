/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {useHistory } from 'react-router-dom';
import { Navbar, FormGroup, Button, Input, Col, Nav, NavItem, Row, Card, CardBody, CardHeader, CardFooter, Modal, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip, UncontrolledDropdown } from "reactstrap";
import reactCSS from 'reactcss';
import { useAlert,positions } from 'react-alert'
import * as JSZip from 'jszip'
import { saveAs } from 'file-saver';

import DynamicToolbar from '../../components/DynamicToolbar/'
import Left_menu from '../../components/Left_menu/';
import NavBar_Dropdown from '../../components/NavBar_Dropdown/';
import DesignView_Dropdown from '../../components/DesignView_Dropdown/';
import Panel from '../../components/Panel/';
import PenguinSlider from '../../components/Slider/'
import CarouselImageBox from '../../components/Slider/ImageBox.js'
import Filter_Modal from '../../components/BottomCarousel/Filter.js'
import Filter_Info_Modal from '../../components/BottomCarousel/Filter_Info.js'
import Design_Revert_Modal from './Design_Revert_Modal.js'
import Modals from '../../components/Modals/index.js';
import {COLORS} from '../../utils/color.js'
import sort_by from '../../utils/sort_by.js'
import './style.css';
import { validate_design_name } from '../../utils/validate_name';
import dummy_tick from '../../utils/dummy_tick';
import general_fetch from '../../utils/fetch';
import { Image_Icon, Fa_Icon, Text_Icon, Left_menu_Image_Icon } from '../../components/Unit/'
import PanelPopup from '../../components/PanelPopup';
import NavBar_Dropdown_New from '../../components/NavBar_Dropdown_New';
import NavBar_Dropdown_Left from '../../components/NavBar_Dropdown_Left';
import NavBar_Dropdown_BF from '../../components/Navbar_Dropdown_BF';
import Trapezium from '../../components/TrapeziumDiv';
import AngleInput from '../../components/AngleInput';
import RotatableSlider from '../../components/RotatableSlider';
import { send_analytics } from '../../utils/send_analytics';
import repopulate_panel from '../../utils/panel_repopulation';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';
import { Tooltip, Menu, Space, Dropdown as AntDropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import NavigationInfo from '../../components/Panel/NavigationInfo';
import { Account_Settings_Modal } from '../../components/CommonModals/index.js';
import sleep from '../../utils/sleep.js';
import useStateCallback from '../../utils/useStateCallback.js';
import { IModalBody, IModalFooter, IModalHeader } from '../../components/IModal/index.js';
import { InfurniaPlansModal } from '../../components/CommonModals/index.js';
import {useTranslation} from 'react-i18next'
import Icons from '../../components/Icons/index.js'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ISingleSelectRSD } from '../../components/ISingleSelect/index.js';
import process3dModel from '../../utils/process3dModel.js';
import Multiselect1 from '../../components/Multiselect/index.js';
import BottomCarousel from '../../components/BottomCarousel/index.js';
import { wait_for_all_core_threads } from '../../utils/wait_for_all_core_threads';
import to_fixed from '../../utils/to_fixed.js';
import { is_valid_file_type } from '../../utils/is_valid_file.js';
import { LayersetPanelPopup } from '../../components/Create_card/index.js';
import { logToSentry } from '../../utils/errors.js';
// const view_options_tooltip_map = {
//     'isometric' : 'Isometric camera mode',
//     'textures' : 'Display Textures',
//     'lineweight' : 'Display Lineweight',
//     'tiling_layout' : 'Display Tiling layout',
//     'reflected_ceiling_plan' : 'Display Reflected ceiling plan',
//     'skeleton_mode' : 'Display Skeleton mode',
//     'tool_guide' : 'Tool guide',
// }


async function getNewFileHandle() {
    // const options = {
    //     types: [
    //     {
    //         description: 'Text Files',
    //         accept: {
    //         'text/plain': ['.txt'],
    //         },
    //     },
    //     ],
    // };
    const handle = await window.showOpenFilePicker({});
    return handle;
}

var current_alert = null;

const styles =  reactCSS({
    'default': {
        main_div:{
            background: 'url("./resources/images/site_image.jpeg") 50% fixed',
            height: '100vh',
            width : '100%',
            position: 'relative',
            backgroundSize: 'cover',
            overflow: 'hidden'

        },
        layer: {
            width: '100%',
            height: '100vh',
            position: 'absolute',
            left: '0',
            top: '0',
            background: COLORS.layer_color,
        },
        nav_bar: {
            backgroundColor: '#F9FAFB',
            // background: COLORS.primary,
            // color: COLORS.white,
            width:'100%',
            color: COLORS.black,
            flex: '1',
            // height:'52px'
            padding: '0px 16px',
            alignSelf: 'stretch',
            borderLeft: '1px solid ' + COLORS.gray3
        },
        nav_item: {
            display:'flex',
            alignItems:'center',
            background: 'linear-gradient(to right, #F3F4F6, #EBEDF1)',
            borderRadius: '6px 20px 20px 6px'
        },
        infurnia_logo:{
            maxWidth: '100px',
            maxHeight: '34px',
            objectFit: 'contain',
            marginTop: '2px'
        },
        nav_bar_icons: {
            marginBottom: '0px',
            padding:'8px',
            display:'flex',
            alignItems:'center',
            height:'40px'
        },
        vertical_nav_bar_icons: {
            lineHeight: '20px',
            marginBottom: '0px',
            padding:'8px',
        },
        recent_designs_title: {
            color: COLORS.white,
            borderLeft: '2px solid #1ab',
            fontSize:'16px', 
            paddingLeft:'10px',
            width:'100%',
            marginLeft:'15px'
        }
    },
});

const design_card_styles = reactCSS({
    'default':{
        design_card_outermost: {
            position: 'relative',
            paddingBottom:'calc(56.25% + 20px)',
            margin:'15px',
            marginTop:'20px'
        },
        design_card_outer: {
            position:'absolute',
            top:'0',
            bottom:'0',
            left:'0',
            right:'0',
        },
        design_card: {
            overflow:'hidden',
            textAlign:'center',
            height: '100%'
        },
        image: {
            height:'150px',
            marginLeft:'50%',
            transform:'translateX(-50%)',
            cursor:'pointer'
        },
    }
})

const styles1 = {
    owned_style: {
        display: "unset",
        position: "absolute", 
        backgroundColor:COLORS.white,
        color:COLORS.black,
        borderRadius:"5px",
        zIndex:250,
        top:'10px', 
        right:'20px',
        fontSize:'12px'
    },
    text_elipsis : {
        whiteSpace: "nowrap", 
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingTop: 5
    },
};

const build_path = (...args) => args.map((part, i) => {
    if (i === 0) {
        return part.trim().replace(/[\/]*$/g, '')
    } else {
        return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
    }
}).filter(x => x.length).join('/');

const isWhiteLabellingEnabled = () => {
    try {
        let active_user = window.Module.get_active_user()
        return active_user && active_user.white_labelling_url
    } catch (err) {
        console.log("Error when accessing user details")
    }
    return false
}

const ToolTipCanvas = ({display, info, x, y, color}) => {

	const [ textbox_style, set_textbox_style] = useState({left:0, top:0, maxWidth: 200});

    useEffect(() => {
        if(display){
            var offset = {left:0, top: 0}
            if(window.$('#canvas_container_div').offset()){
                offset = window.$('#canvas_container_div').offset();
            }

            set_textbox_style({
                left: offset.left + x + 10,
                top: offset.top + y + 10,
                maxWidth: 200, 
            })
        }
    }, [display,x,y,info]);

    return(
		<div style={{display:display ? 'block' : 'none',fontSize:'12px',position:'fixed',zIndex:10000,...textbox_style, padding:'6px 12px', color:color=="red"?"red":'white', backgroundColor:COLORS.gray8}} id="tooltip_canvas">{info}</div>
    )
}

const DesignNameModal = ({design_name, handle_ui_response, show_rename_design_modal, set_show_rename_design_modal, set_page_loader, project_client_name, project_id}) => {
    const alert = useAlert()
    const {t} = useTranslation()

	const rename_design = (name) => {
		if (validate_design_name(name)) {
			set_page_loader({
				show: true,
				text: 'Renaming design...'
			});

			setTimeout(async () => {
				let active_design = window.Module.get_active_design()
				try {
					var response = await window.Promisify(window.Module.rename_design(active_design.design_id, name))
					handle_ui_response(JSON.stringify({ "update_design_name": true }))
					alert.success("Design renamed successfully");
				} catch (error) {
					alert.error('Could not rename your design')
				}
				active_design.delete()

				set_page_loader({
					show: false,
					text: 'Please wait'
				});
			}, 0)
		} else {
			if (name && name.trim()) {
				alert.error('Special Characters Not permitted. Please modify the Design Name')
			} else {
				alert.error('Design Name cant be blank')
			}
		}
    }

	const closeModal = () => {
		set_show_rename_design_modal(false);
	}

    // useEffect(() => {
    //     console.log("show rename design modal is ", show_rename_design_modal)
    // }, [show_rename_design_modal])

	return (
	<Modal isOpen={show_rename_design_modal} toggle={closeModal} centered>
		<IModalHeader toggle={closeModal}>
            Design property
		</IModalHeader>

		<IModalBody style={{padding: "24px 48px"}}>
            <div className='mb-2' style={{ textAlign: 'left'}}>{t(`Design Name`)}</div>
			<div className='flex_property' style={{justifyContent:'space-between'}}>
				<Input id='header_design_name_input' spellCheck={false} style={{padding: '6px 12px', fontSize: '14px'}} defaultValue={design_name} />
			</div>
		</IModalBody>

		<IModalFooter>
			<Button onClick ={() => {closeModal(); rename_design(document.getElementById('header_design_name_input').value)}} className='primary_button_default' type="button">Update</Button> 
		</IModalFooter>
	</Modal>)
}

const EditDesign = ({org_key, wait_for_version_save_queue, component_id, work_order_id, active_branch_id, sku_id, set_page_loader, remote_view, design_version_only, baking_view, seller_specific, mes_editor, submit_for_delete_confirm, store_details,init, logout,login_error, is_user_admin}) => {
    const alert = useAlert()
    const {t} = useTranslation()
    
    const [ dropdownOpen, setDropdownOpen] = useState(false);
    const [ horizontal_json_file_2, left_memu_json_file_2] = useState(false);
    
    const [ show_design_revert_modal , set_show_design_revert_modal] = useState(false);
    
    const [ work_order_details , set_work_order_details] = useState(null);

    const [ carousel_show, set_carousel_show ] = useState(false);
    const [carousel_sku_parameters_show, set_carousel_sku_parameters_show] = useState(false);
    const [ carousel_search_mode, set_carousel_search_mode ] = useState(false);
    const [ carousel_heading , set_carousel_heading] = useState("Carousel");
    const [ carousel_sku_json , set_carousel_sku_json] = useState([]);
    const [ carousel_onclick , set_carousel_onclick] = useState("");
    const [ carousel_onclick_stringified_json , set_carousel_onclick_stringified_json] = useState("");
    const [ carousel_subcategories , set_carousel_subcategories] = useState({sub_categories:[]});
    const [ carousel_eligible_categories , set_carousel_eligible_categories] = useState([]);
    const [ carousel_search_sub_category_filter_options, set_carousel_search_sub_category_filter_options ] = useState([]);
    const [ carousel_search_sub_category_filter_value, set_carousel_search_sub_category_filter_value ] = useState([]);
    const [ carousel_search_category_filter_options, set_carousel_search_category_filter_options ] = useState([]);
    const [ carousel_search_category_filter_value, set_carousel_search_category_filter_value ] = useState([]);
    const [ carousel_search_string, set_carousel_search_string ] = useState('');
    const [carousel_minimize, set_carousel_minimize] = useState(false);
    
    
    const [ carousel_active_tab, set_carousel_active_tab] = useState(0);

    const [ active_category_id, set_active_category_id ] = useState('');
    const [ search_string, set_search_string ] = useState('')

    const [ project_name , set_project_name] = useState('Project');
    const [ design_name , set_design_name] = useState('Design');
    const [project_id, set_project_id] = useState('');
    const [client_name, set_client_name] = useState('');

    const [ metric_system , set_metric_system] = useState([]);
    const [ approximation_ft , set_approximation_ft] = useState([]);
    const [ approximation_mm , set_approximation_mm] = useState([]);

    const [ component_levels , set_component_levels] = useState([]);
    const [ interface_mode , set_interface_mode] = useState(''/*'design_edit_mode'*/);
    const [ perspective , set_perspective] = useState('floorplan'/*'3d'*/);
    const [ viewing_level , set_viewing_level] = useState('world');
    const [ design_views , set_design_views] = useState([]);
    const [ is_free_view_active , set_is_free_view_active] = useState(false);
    const [view_options, set_view_options] = useState([]);
    // const [view_options_map, set_view_options_map] = useState({
    //     'rich_view': 'textures',
    //     'visualize_line_weights': 'lineweight',
    //     'tiling_layout_plan': 'tiling_layout',
    //     'reflected_ceiling_plan': 'reflected_ceiling_plan',
    //     'switch_camera_type': 'isometric',
    //     'skeleton_mode': 'skeleton_mode',
    // })
    // const [all_view_options, set_all_view_options] = useState(['isometric', 'textures', 'lineweight', 'tiling_layout', 'reflected_ceiling_plan', 'skeleton_mode', 'tool_guide'])
    const [ level_arrays , set_level_arrays] = useState({buildings:[],floors:[],rooms:[]});
    const [ elevation_cameras , set_elevation_cameras] = useState([]);
    const [ custom_plans_cameras , set_custom_plans_cameras] = useState([]);
    const [ presentation_sheets , set_presentation_sheets] = useState({sheets:[],active_sheet:null});
    const [ boq_schedules , set_boq_schedules] = useState({schedules:[],active_schedule:null});
    const [ board_layout_sheets , set_board_layout_sheets] = useState({sheets:[],active_sheet:null});
    const [ board_layout_sheet_types , set_board_layout_sheet_types] = useState({sheet_types:[],active_sheet_type:null});
    const [isDraggingPresentation, setIsDraggingPresentation] = useState(false);
    const [startXPresentation, setStartXPresentation] = useState(0);
    const [startYPresentation, setStartYPresentation] = useState(0);
    const [startCoOrdinatesPresentation, setStartCoOrdinatesPresentation] = useState({x: 0, y:0});
    const [scrollLeftPresentation, setScrollLeftPresentation] = useState(0);
    const [scrollTopPresentation, setScrollTopPresentation] = useState(0);

    const [ toolbar_json_file, set_toolbar_json_file ] = useState([]);
    const [ left_menu_json_file, set_left_menu_json_file] = useState([]);
    const [ panel_toolbar_json, set_panel_toolbar_json] = useState([]);

    const [apply_discard, set_apply_discard] = useState(false);
    const [apply_discard_mode, set_apply_discard_mode] = useState("");

    // const [ modal_type , set_modal_type] = useState('none');
    // const [ modal_json , set_modal_json] = useState(null);

    const [ modal_properties, set_modal_properties ] = useState({
        type: 'none',
        json: null
    });

    const [panel_explode, set_panel_explode] = useState(false);
    const [ panel_show , set_panel_show] = useState(false);
    const [panel_collapsed, set_panel_collapsed] = useState(false);
    const [ panel_heading , set_panel_heading] = useState("Properties");
    const [panel_heading_icon, set_panel_heading_icon] = useState('');
    const [ panel_heading_icon_route, set_panel_heading_icon_route ] = useState('');
    // const [panel_heading_back_show, set_panel_heading_back_show] = useState('');
    const [ panel_json , set_panel_json] = useState([]);
    const [ panel_has_unapplied_edits , set_panel_has_unapplied_edits] = useState(false);
    const [ panel_key , set_panel_key] = useState("regular_panel");
	const [ scroll_pos, set_scroll_pos ] = useState(0);
    const [panel_heading_label, set_panel_heading_label] = useState({});
    const [panel_json_default_tree_selection, set_panel_json_default_tree_selection] = useState('');
    const [show_panel_info_at_bottom, set_show_panel_info_at_bottom] = useState(false);
    const [panel_info_list, set_panel_info_list] = useState([]);
    const [panel_info_setting, set_panel_info_setting] = useState('mouse');

    const [panel_pop_up_show, set_panel_pop_up_show] = useState(false);
    const [panel_pop_up_heading, set_panel_pop_up_heading] = useState("Settings");
    const [panel_pop_up_json, set_panel_pop_up_json] = useState({});
    const [panel_pop_up_has_unapplied_edits, set_panel_pop_up_has_unapplied_edits] = useState(false);
    const [panel_pop_up_key, set_panel_pop_up_key] = useState('panel_pop_up');
    const [scroll_pos_pop_up, set_scroll_pos_pop_up] = useState(0);
    const [panel_show_tree, set_panel_show_tree] = useState(false);

    const [panel_search_json, set_panel_search_json] = useState({});
    const [is_search_mode, set_is_search_mode] = useState(false);
    const [search_string_panel, set_search_string_panel] = useState('');
    const [panel_search_string, set_panel_search_string] = useState('');

    const [panel_current_page_show, set_panel_current_page_show] = useState(false);
    const [panel_current_page_json, set_panel_current_page_json] = useState([]);
    const [panel_current_page_heading, set_panel_current_page_heading] = useState('');
    const [ panel_current_page_has_unapplied_edits , set_panel_current_page_has_unapplied_edits] = useState(false);
    const [panel_current_page_key, set_panel_current_page_key] = useState('panel_current_page');


    const [panel_pop_up_search_json, set_panel_pop_up_search_json] = useState({});
    const [is_search_mode_panel_pop_up, set_is_search_mode_panel_pop_up] = useState(false);
    const [search_string_panel_pop_up, set_search_string_panel_pop_up] = useState('');
    const [panel_pop_up_search_string, set_panel_pop_up_search_string] = useState('');


    const [tooltip_for_cursor, set_tooltip_for_cursor] = useState(false);
    const [tooltip_for_cursor_content, set_tooltip_for_cursor_content] = useState('');
    const [tooltip_for_cursor_x, set_tooltip_for_cursor_x] = useState(0);
    const [tooltip_for_cursor_y, set_tooltip_for_cursor_y] = useState(0);
    const [tooltip_for_cursor_color, set_tooltip_for_cursor_color] = useState('white');

    const [show_account_settings, set_show_account_settings] = useState(false);
    const [show_current_subscription, set_show_current_subscription] = useState(false);
    const [is_profile_dropdown_open, set_is_profile_dropdown_open] = useState(false);
    const [is_help_section_dropdown_open, set_is_help_section_dropdown_open] = useState(false);
    const [modal_mode, set_modal_mode] = useState('');
    const [layerset_mode, set_layerset_mode] = useState('');
    const [given_layerset, set_given_layerset] = useState('');
	  const [selected_index, set_selected_index] = useState(['0']);
	  const [panel_json_id, set_panel_json_id] = useState('');
	  const [show_tree, set_show_tree] = useState(false);


    const [show_status_bar, set_show_status_bar] = useState(false);
    const [status_bar_content, set_status_bar_content] = useState('');
    const [status_bar_tooltip, set_status_bar_tooltip] = useState('');
    const [status_bar_buttons, set_status_bar_buttons] = useState([]);
    const [status_bar_type, set_status_bar_type] = useState('info');

    const [bottom_status_bar_message, set_bottom_status_bar_message] = useState('No message');
    const [bottom_status_bar_show, set_bottom_status_bar_show] = useState(false);
    const [bottom_status_bar_collapsed, set_bottom_status_bar_collapsed] = useState(false);
    
    // help center states below
    const [show_help_center, set_show_help_center] = useState(false);
    const [help_center_url, set_help_center_url] = useState('');
    // ---------------------
    
    const toggle_bottom_status_bar_collapsed = () => {
        set_bottom_status_bar_collapsed(!bottom_status_bar_collapsed)
    }

    const [show_command_line, set_show_command_line] = useState(false);
    const [command_line_icon, set_command_line_icon] = useState('');
    const [command_line_content, set_command_line_content] = useState('');
    const [command_line_options, set_command_line_options] = useState([]);
    const [command_line_valid, set_command_line_valid] = useState(true);
    const [command_line_valid_route, set_command_line_valid_route] = useState('');
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
    const [command_line_view_more, set_command_line_view_more] = useState(false);
    const [command_line_disable_false_blur, set_command_line_disable_false_blur] = useState(false);
    const [show_rename_design_modal, set_show_rename_design_modal] = useState(false);

    const [caraousel_loading, set_caraousel_loading] = useStateCallback({show: false, text: 'Loading Inventory...'});
	const [mes_component, set_mes_component] = useState();
	const [active_panel, set_active_panel] = useState();
	const [core_selection_ids, set_core_selection_ids] = useState([]);

    //comment mode specific
    const [is_comment_mode, set_is_comment_mode] = useState(false);
    const [dummy_comment_position_json, set_dummy_comment_position_json] = useState([
        {
            id:'c-1',
            x: '12px',
            y:'70px',
            user:'B',
            comment: 'content": "/resources/toolbar_icons_new/undo icon.svg'
        },
        {
            id:'c-2',
            x: '90px',
            y:'90px',
            user:'D',
            comment: 'Version History'
            
        }
    ]);
    const [comment_position_json, set_comment_position_json] = useState([])
    // const [comment_pin_divs, set_comment_pin_divs] = useState([]);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [canvasOffsetX, setcanvasOffsetX] = useState(0);
    const [canvasOffsetY, setcanvasOffsetY] = useState(0);

    const [seller_sku_details, set_seller_sku_details] = useState({
        category_name: 'category', sub_category_name: 'sub category', sku_name: 'sku name', group_name: 'group_name'
    })


    const [dummy_panel_json, set_dummy_panel_json] = useState(
        [
            
        ]
    
    );


    

    const [active_left_menu_item, set_active_left_menu_item] = useState('')
    
    const [active_tab, set_active_tab] = useState(0);
    const [new_tool_bar_menu, setnew_tool_bar_menu] = useState([
        {
            "id": "home",
            "name": "Home",
            "sections": [
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/branch.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "design_history_design_toolbar",
                            "name": "Version History",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/undo icon.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "undo_design_toolbar",
                            "name": "Undo (ctrl+Z)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/redo icon.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "redo_design_toolbar",
                            "name": "Redo (ctrl+Y)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/mouse.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "move_mode_floorplan",
                            "name": "Move mode",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "versioning",
                    "sub_menu": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/branch.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "design_history_design_toolbar",
                            "name": "Version History",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/undo icon.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "undo_design_toolbar",
                            "name": "Undo (ctrl+Z)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/redo icon.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "redo_design_toolbar",
                            "name": "Redo (ctrl+Y)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/mouse.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "move_mode_floorplan",
                            "name": "Move mode",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ]
                },
                {
                    "buttons": [],
                    "id": "lines",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/wall.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "drawWallsStraight",
                            "name": "Straight Walls (WA)",
                            "sub_buttons": [
                                {
                                    "active": "false",
                                    "content": "/resources/toolbar_icons_new/wall.svg",
                                    "disable": "false",
                                    "disabled_message": "Cannot draw walls in 3D/Elevation view or at building and room level",
                                    "id": "drawWallsStraight",
                                    "name": "Straight Walls (WA)",
                                    "sub_buttons": [],
                                    "type": "image"
                                },
                                {
                                    "active": "false",
                                    "content": "/resources/toolbar_icons_new/curved wall.svg",
                                    "disable": "false",
                                    "disabled_message": "Cannot draw walls in 3D/Elevation view or at building and room level",
                                    "id": "drawWallsCurved",
                                    "name": "Curved Walls",
                                    "sub_buttons": [],
                                    "type": "image"
                                },
                                {
                                    "active": "false",
                                    "content": "/resources/toolbar_icons_new/room divider.svg",
                                    "disable": "false",
                                    "disabled_message": "Cannot draw walls in 3D/Elevation view or at building and room level",
                                    "id": "drawDividers",
                                    "name": "Room Divider",
                                    "sub_buttons": [],
                                    "type": "image"
                                }
                            ],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/column.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add pillars in elevation view or building level",
                            "id": "drawPillars",
                            "name": "Pillars (PI)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/door.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add doors in elevation view or building level",
                            "id": "drawDoors",
                            "name": "Doors (DO)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/window.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add windows in elevation view or building level",
                            "id": "drawWindows",
                            "name": "Windows (WI)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/stairs.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw staircases in 3D/Elevation view or at building level",
                            "id": "drawStaircases",
                            "name": "Staircases (ST)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/roof.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw roofs at building level",
                            "id": "create_roof",
                            "name": "Roofs (RO)",
                            "sub_buttons": [],
                            "type": ""
                        }
                    ],
                    "id": "architecture",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/chair.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add furniture in elevation view or building level",
                            "id": "addFurniture",
                            "name": "Furniture (FU)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/tiling.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw tilings at building level",
                            "id": "drawTiling",
                            "name": "Add Tiling (TI)",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "furnish",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/trim and extend.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "trim_and_extend_floorplanner_2d",
                            "name": "Trim and Extend",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/offset.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "offset_floorplanner_2d",
                            "name": "Offset Tool (MV)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/chamfer.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "chamfer_floorplanner_2d",
                            "name": "Chamfer Tool",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "offset_chamfer",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/dimension.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "drawDimensions",
                            "name": "Draw Dimensions",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "resources/toolbar_icons_new/measure.svg",
                            "disable": "false",
                            "disabled_message": "2D Measure tool not available in 3D mode",
                            "id": "measure_toolbar",
                            "name": "Measure",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/rectangle zoom.svg",
                            "disable": "false",
                            "disabled_message": "2D Zoom tool not available in 3D mode",
                            "id": "zoom_tool",
                            "name": "Zoom Tool: Mouse press and drag a rectangular area to zoom into it",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "tools",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/presentation mode.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "viewpresentation_floorplan_toolbar",
                            "name": "Presentation",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "outputs",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/share.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "share_design_floorplan_toolbar",
                            "name": "Share Design",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "outputs",
                    "sub_menu": []
                }
            ]
        },
        {
            "id": "concept",
            "name": "Concept",
            "sections": []
        },
        {
            "id": "architecture",
            "name": "Architecture",
            "sections": [
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/wall.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw walls in 3D/Elevation view or at building and room level",
                            "id": "drawWallsStraight",
                            "name": "Straight Walls (WA)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/curved wall.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw walls in 3D/Elevation view or at building and room level",
                            "id": "drawWallsCurved",
                            "name": "Curved Walls",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/room divider.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw walls in 3D/Elevation view or at building and room level",
                            "id": "drawDividers",
                            "name": "Room Divider",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/icons/railing.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw walls in 3D/Elevation view or at building and room level",
                            "id": "drawBalconyWalls",
                            "name": "Balcony Wall/Railing",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/icons/fence.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "drawBoundaryWalls",
                            "name": "Boundary Wall/Fence",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "walls",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/door.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add doors in elevation view or building level",
                            "id": "drawDoors",
                            "name": "Doors (DO)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/window.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add windows in elevation view or building level",
                            "id": "drawWindows",
                            "name": "Windows (WI)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/column.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add pillars in elevation view or building level",
                            "id": "drawPillars",
                            "name": "Pillars (PI)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/stairs.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw staircases in 3D/Elevation view or at building level",
                            "id": "drawStaircases",
                            "name": "Staircases (ST)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/slab.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw slabs in at building level",
                            "id": "drawSlabs",
                            "name": "Slabs (SL)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/beam.svg",
                            "disable": "false",
                            "disabled_message": "Cannot add beams in elevation view or building level",
                            "id": "drawBeams",
                            "name": "Beams (BE)",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/toolbar_icons_new/roof.svg",
                            "disable": "false",
                            "disabled_message": "Cannot draw roofs at building level",
                            "id": "create_roof",
                            "name": "Roofs (RO)",
                            "sub_buttons": [],
                            "type": ""
                        }
                    ],
                    "id": "walls",
                    "sub_menu": []
                }
            ]
        },
        {
            "id": "view",
            "name": "View",
            "sections": [
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/icons/elevation_view.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "add_elevation_cam",
                            "name": "Add Elevation View",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "lock camera",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "lock_camera_position",
                            "name": "Lock Camera Position",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "skeleton mode",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "turn_on_skeleton",
                            "name": "Turn On Skeleton Mode",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "section1",
                    "sub_menu": []
                },
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "open_dimension_settings",
                            "name": "Open Dimension Settings",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "open_sunlight_settings",
                            "name": "Open Sunlight Settings",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "section1",
                    "sub_menu": []
                }
            ]
        },
        {
            "id": "render",
            "name": "Renders",
            "sections": [
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "/resources/panel_icons_new/render.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "rtx_render",
                            "name": "Render",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/panel_icons_new/rendered images.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "rtx_render_image_option",
                            "name": "Rendered Images",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/panel_icons_new/screenshot.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "screenshot_option",
                            "name": "Screenshot",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/panel_icons_new/set design thumbnail.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "design_thumbnail",
                            "name": "Set Design Thumbnail",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/panel_icons_new/export.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "export_settings",
                            "name": "Export 3D",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/panel_icons_new/environment map.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "environment_map_dropdown",
                            "name": "Environment Map",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "/resources/panel_icons_new/aspect ratio.svg",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "aspect_ratio_dropdown",
                            "name": "Aspect Ratio",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "render_tab",
                    "sub_menu": []
                }
            ]
        },
        {
            "id": "production",
            "name": "Production",
            "sections": [
                {
                    "buttons": [
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "lock_design",
                            "name": "Lock Design",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "presentation",
                            "name": "Presentation",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "pricing_quotation",
                            "name": "Pricing Quotation",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "composite_cabinet_boq",
                            "name": "Composite Cabinet BOQ",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "generate_schedule",
                            "name": "Generate Schedule",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "cut_list",
                            "name": "Cut List",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "open_custom_outputs",
                            "name": "Custom Outputs",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "spacewood_custom_outputs",
                            "name": "SpaceWood Custom Outputs",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "check_catalogue_constraints",
                            "name": "Check Catalogue Constraints",
                            "sub_buttons": [],
                            "type": "image"
                        },
                        {
                            "active": "false",
                            "content": "",
                            "disable": "false",
                            "disabled_message": "",
                            "id": "refresh_cache",
                            "name": "Refresh Cache",
                            "sub_buttons": [],
                            "type": "image"
                        }
                    ],
                    "id": "production_tab",
                    "sub_menu": []
                }
            ]
        }
    ]);

    const [view_manager_upper_menu, set_view_manager_upper_menu] = useState([
        {
            icon: '/resources/view_bar_icons/workbench_type.svg',
            name: t('Free View'),
            view_type: 'workbench',
        },
        {
            icon: '/resources/view_bar_icons/floorplan_type.svg',
            name: t('Floor plans'),
            view_type: 'floorplan',
        },
        {
            icon: '/resources/view_bar_icons/custom_plan_type.svg',
            name: t('Custom plans'),
            view_type: 'custom_plan',
        },
        {
            icon: '/resources/view_bar_icons/3d_type.svg',
            name: t('3D'),
            view_type: '3d',
        },
        {
            icon: '/resources/view_bar_icons/elevation_type.svg',
            name: t('Elevations'),
            view_type: 'elevation',
        },
    ])

    const [workbench_menu, set_workbench_menu] = useState([])
    const [current_view_layers, set_current_view_layers] = useState(null);

    const [view_layers_menu, set_view_layers_menu] = useState([
        {
            key: '1',
            type: 'group',
            label: ''
        }
    ])
    const [is_view_layers_open, set_is_view_layers_open] = useState(false);

    useEffect(() => {
        const handleCanvasClicked = () => {
            set_is_view_layers_open(false)
        }
        window.addEventListener('canvasClicked', handleCanvasClicked);

    return () => {
      window.removeEventListener('canvasClicked', handleCanvasClicked);
    };
    }, []);

    useEffect(() => {
        set_view_layers_menu([
            {
                key: '1',
                type: 'group',
                label: current_view_layers ? <div style={{background: COLORS.gray3, borderRadius: '8px'}}>
                <div className='flex_between' style={{padding: '4px 8px 0px 8px', color: 'black', fontFamily: 'DM Sans', fontWeight: 700}}>
                    View Specific Layers
                    <Icons name={'expand'} style={{fontSize: '16px', cursor: 'pointer'}} onClick={() => toolbar_on_click("open_layersets_detailed")}/>
                </div>
                <LayersetPanelPopup json_item={current_view_layers} update_view={update_view} set_layerset_mode={set_layerset_mode} set_given_layerset={set_given_layerset} set_modal_mode={set_modal_mode} common_onchange_handler={update_view_options} handle_ui_response={handle_ui_response} options={{show_overridden: true} } view_id={current_view_layers && current_view_layers.view_id}/>
                </div> : ''
            }
        ])
    }, [current_view_layers])

    useEffect(() => {
        set_current_view_layers(view_options && view_options.length && view_options[0].submenu)
    }, [view_options])

    useEffect(() => {
        if(design_views){
            let temp = view_manager_upper_menu.filter(item => item.view_type != 'workbench').map((item, idx) => {
                return {
                    key: String(idx+2),
                    label: <div className='view_type_menu' style={{display: "flex", gap: '10px', alignItems: 'center'}}> <img src={item.icon} height='20px' width='20px' style={{height: item.view_type=='3d'?'16px':''}}/> <div>{item.name + ' (' + String(design_views.filter(view => view.type && view.type.includes(item.view_type)).length) + ')'}</div></div>,
                    children: design_views.filter(view => view.type && view.type.includes(item.view_type)).map((filtered_view, view_idx) => {return {view_value: filtered_view, label: <Tooltip placement='right' title={filtered_view.name} mouseEnterDelay={0.5}><div onClick={(e) => {if(!filtered_view.open) {open_view(filtered_view);} switch_view_and_scroll_to_it(filtered_view); }} style={{display: 'flex', gap: '10px', maxWidth: '200px'}}><img src={filtered_view.locked?'/resources/view_bar_icons/locked_state.svg':'/resources/view_bar_icons/unlocked_state.svg'}></img> <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{filtered_view.name}</div> </div></Tooltip>, key: String(idx+2) + '-' + String(view_idx+1)}}),
                    disabled: design_views.filter(view => view.type && view.type.includes(item.view_type)).length ? false : true
                }
            })
            temp.unshift({
                key: '1',
                type: 'group',
                label: <div style={{margin: '-16px -16px 0 -16px'}}><div style={{ textAlign: 'center', background: '#788394', padding: '6px 0', color: 'white', borderRadius: '4px 4px 0 0'}}>View Manager</div></div>,
                children: [{key: '1-1', label: <div style={{display: "flex", alignItems: 'center', gap: '10px', marginLeft: '-9px'}}> <img src={view_manager_upper_menu.find(item => item.view_type == 'workbench').icon} height={'60%'} /> <div onClick={(e) => switch_free_view(e)}>{view_manager_upper_menu.find(item => item.view_type == 'workbench').name}</div></div>,}]
            });
            set_workbench_menu(temp);
        }
    }, [view_manager_upper_menu, design_views])


    const [camera_details, set_camera_details] = useState();
    const [camera_positioning_mode, set_camera_positioning_mode] = useState(false);
    const [camera_angle, set_camera_angle] = useState(0);
    const [render_preview_url, set_render_preview_url] = useState('');
    const [camera_position, set_camera_position] = useState(0);
    const [camera_max_height, set_camera_max_height] = useState(0);
    const [camera_min_height, set_camera_min_height] = useState(0);
    const [aspect_ratios, set_aspect_ratios] = useState({});

    const [camera_position_input, set_camera_position_input] = useState(0);
    const [camera_angle_input, set_camera_angle_input] = useState(0);
    const [camera_position_input_copy, set_camera_position_input_copy] = useState(0);
    const [camera_angle_input_copy, set_camera_angle_input_copy] = useState(0);

    const [active_view_name, set_active_view_name] = useState('Free View');
    const [isActiveViewVisible, setIsActiveViewVisible] = useState(true);
    const [scrollToActiveViewButtonPosition, setScrollToActiveViewButtonPosition] = useState('left');
    const [common_viewport_options, set_common_viewport_options] = useState([]);
    const [design_views_open, set_design_views_open] = useState([])

    const [selected_tool_guide, set_selected_tool_guide] = useState(true);

    const [show_share_navigation_info, set_show_share_navigation_info] = useState(true);
    const toggle_show_share_navigation_info = () => {set_show_share_navigation_info(!show_share_navigation_info)}

    const [hovered, set_hovered] = useState(false)
    const [hovered1, set_hovered1] = useState(false)
    const [hovered2, set_hovered2] = useState(false)
    const [hovered3, set_hovered3] = useState(false)
    const history = useHistory();

    const [show_arrows, set_show_arrows] = useState(false);


    const [ re_render, set_re_render ] = useState(false);
    const forceReRender = () => {set_re_render(!re_render)}

    useEffect(() => {
        set_show_arrows(isArrowVisible())
    })

    const handleMouseEnter = () => {
        set_hovered(true);
    }
    
    const handleMouseLeave = () => {
        set_hovered(false);
    }

    const handleMouseEnter1 = () => {
        set_hovered1(true);
    }
    
    const handleMouseLeave1 = () => {
        set_hovered1(false);
    }
    const handleMouseEnter2 = () => {
        set_hovered2(true);
    }
    
    const handleMouseLeave2 = () => {
        set_hovered2(false);
    }
    const handleMouseEnter3 = () => {
        set_hovered3(true);
    }
    
    const handleMouseLeave3 = () => {
        set_hovered3(false);
    }


    // const authenticate = async () => {
    //     try {
    //         var user_details = await general_fetch({ url: 'user/get_details', data_field: 'user' });
    //         set_user(user_details);
    //         console.log(user_details)
    //     } catch(err) {
    //         console.error(err);
    //     }
    // }

    const show_view_options = () => {
        return interface_mode=="design_edit_mode" && !seller_specific && !mes_editor
    }

    useEffect(() => {
        if(!design_views || !design_views.length) set_design_views_open([])
        else{
            set_design_views_open(design_views.filter(item => item.open))
        }
    }, [design_views])

    const left_menu_on_click = async id => {
		if(id == "quotation_export_csv" || id == "quotation_export_xlsx"){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}

		var promise_resp;
		try{
            if(window.debug_mode){
                if(id == "cancel_sketcher_2d" || id == "apply_sketcher_2d"){
                    window.add_debug_log(window.two_interface.em_interface.class_type+"->draw();");
                }
                window.add_debug_log("left_menu_router(\"" + id.replace(/"/g, '\\"') + "\");");
            }
			promise_resp = window.Module.left_menu_router(id);
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Left menu onclick error at " + id + " : " + err.name;
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			return;
		}

		var ui_response = await window.Promisify(promise_resp);
		
		handle_ui_response(ui_response);
		set_active_left_menu_item(id?id:'')
		update_view();

		if(id == "quotation_export_csv" || id == "quotation_export_xlsx"){
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}
    }

    const toolbar_on_click = async (id, item) => {
		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner" || id == "production_details_$fulfillment_tags"){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}


		var promise_resp;
		try{
            if(item){
                var analytics_data = item
                analytics_data.category = "toolbar button clicked";
                send_analytics(analytics_data,true)
            };
            if(window.debug_mode){
                window.add_debug_log("toolbar_router(\"" + id.replace(/"/g, '\\"') + "\");");
            }
            promise_resp = window.Module.toolbar_router(id);
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			return;
		}

		var ui_response = await window.Promisify(promise_resp);
		handle_ui_response(ui_response);
		
		update_view();

		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner" || id == "production_details_$fulfillment_tags"){
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}
	}

    const approxMM = (mm) => {
        if(mm == undefined || mm == null){
            return null;
        }

        if(approximation_mm == "1_mm"){
          return mm.toFixed(0);
      }else if(approximation_mm == "0.1_mm"){
          return mm.toFixed(1);
      }else if(approximation_mm == "0.01_mm"){
          return mm.toFixed(2);
      }else{
          return String(mm);
      }
    }

    const get_units_preview = (units, precesion) => {
        if(units === 'mm'){
            if(precesion === '1_mm'){
                return '1 mm'
            }else if(precesion === '0.1_mm'){
                return '0.1 mm'
            }else if(precesion === '0.01_mm'){
                return '0.01 mm'
            }else{
                return 'mm'
            }
        }else{
            if(precesion === '1_inch'){
                return 'ft in 1"'
            }if(precesion === '1/2_inch'){
                return 'ft in 1/2"'
            }else if(precesion === '1/4_inch'){
                return 'ft in 1/4"'
            }else if(precesion === '1/8_inch'){
                return 'ft in 1/8"'
            }else if(precesion === '1/16_inch'){
                return 'ft in 1/16"'
            }else{
                return 'ft in'
            }
        }
    }

    const mmToFeetParts = (mm, approximation_ft = "1/16_inch") => {
		var realFeet = (mm / 304.8);
		var feet = Math.floor(realFeet);
		var inches = Math.floor((realFeet - feet) * 12);
		if(inches == 12){
			inches = 11;
		}
		var remainder_inch = ((realFeet - feet) * 12) - inches;

		var inch_fraction = remainder_inch;
		var fraction_number = 0;
        if(approximation_ft == "1/2_inch"){
			var test_rounded_fraction = Math.round(remainder_inch*2);
			if(test_rounded_fraction == 2){
				inches += 1;
				test_rounded_fraction = 0;
			}
			fraction_number = test_rounded_fraction;
		}else if(approximation_ft == "1/4_inch"){
			var test_rounded_fraction = Math.round(remainder_inch*4);
			if(test_rounded_fraction == 4){
				inches += 1;
				test_rounded_fraction = 0;
			}
			fraction_number = test_rounded_fraction;
		}else if(approximation_ft == "1/8_inch"){
			var test_rounded_fraction = Math.round(remainder_inch*8);
			if(test_rounded_fraction == 8){
				inches += 1;
				test_rounded_fraction = 0;
			}
			fraction_number = test_rounded_fraction;
		}else if(approximation_ft == "1/16_inch"){
			var test_rounded_fraction = Math.round(remainder_inch*16);
			if(test_rounded_fraction == 16){
				inches += 1;
				test_rounded_fraction = 0;
			}
			fraction_number = test_rounded_fraction;
		}
		if (inches == 12){
            feet +=1;
            inches = 0;
        }
		return [feet,inches,fraction_number];
	}

    const feetTomm = (feet,inches,fraction_number) => {
        return approximation_ft==='1/8_inch' ? approxMM(((fraction_number/8)+(inches)+(feet*12))*(25.4)) : approxMM(((fraction_number/16)+(inches)+(feet*12))*(25.4))
    }
  

  	const toggle = () => {
        return setDropdownOpen(!dropdownOpen)
    }

    const scrollToActiveView = () => {
        const activeViewElement = document.querySelector('.active_view_container');
        // if(activeViewElement && activeViewElement.id == 'free_view_div') return;
        setIsActiveViewVisible(true)
        // Scroll to the active view
        // setTimeout(
        //     () => {
                if (activeViewElement) {
                    activeViewElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'nearest',
                    });
                }
        //     }, 300
        // )
    };

    const open_view = async(view) => {
        var promise_resp;
        try{
            window.Module.open_close_design_view(view.id, true);
        }catch(err){
            err.constructor.name.includes("Error") != true?err=new Error(err):null;
            err.name = "Captured error in View specific settings at open_close_design_view : " + err.name;
            console.error(err);
            window.sentry_capture_exception("",err);
            alert.error("Something went wrong")
            return;
        }
        update_view()
    }

    const scrollToViewEnd = () => {
        const viewsContainerElement = document.querySelector('.all_views_div_inner');
		if(viewsContainerElement){
			viewsContainerElement.scrollLeft = viewsContainerElement.scrollWidth
		}
    };

    const updateButtonVisibility = () => {
        const activeViewElement = document.querySelector('.active_view_container');
        const viewsContainerElement = document.getElementById('all_views_div_inner');
        // if(activeViewElement && activeViewElement.id == 'free_view_div') return ;
    
        if (activeViewElement && viewsContainerElement) {
          const containerRect = viewsContainerElement.getBoundingClientRect();
          const activeViewRect = activeViewElement.getBoundingClientRect();
    
          const isLeftInVisible = activeViewRect.right < containerRect.left;
          const isRightInVisible = activeViewRect.left > containerRect.right;
    
          setIsActiveViewVisible(!(isLeftInVisible || isRightInVisible));
          setScrollToActiveViewButtonPosition(isLeftInVisible ? 'left' : 'right');
        }
    };
    


    const close_modal = () => {
        if(window.debug_mode){
            window.add_debug_log("active_design->active_view->hide_modal();");
        }
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.hide_modal();
        update_view();
        active_design.delete();
        active_view.delete();
        set_layerset_mode('')
    }


    const update_sku_thumbnails = async (e) => {
        console.log(e.nativeEvent.custom_data);
        var sku_ids = e.nativeEvent.custom_data;
        for(var i=0;i<sku_ids.length;i++){
            console.log("-------------");
            console.log("UPDATING THUMBNAIL " + (i+1) + "/" + sku_ids.length);
            console.log("SKU ID - " + sku_ids[i]);
            var resp = await load_and_update_thumbnail_of_sku(sku_ids[i]);
            if(resp && resp.response_code == 1){
                console.log("SUCCESS");
                alert.success("SUCCESS - " + sku_ids[i]);
            }else{
                console.log("FAIL");
                alert.error("FAILURE - " + sku_ids[i]);
            }
            console.log("-------------");
        }
    }

    const dummy_tick_sku_thumbnail = (t) => (new Promise((res,rej) => {
        var timer = setTimeout(() => {
            res();
            clearTimeout(timer);
        },t)
    })); 

    const load_and_update_thumbnail_of_sku = async (sku_id) => {
        //loading sku
        window.destroy_graphics();
        await dummy_tick_sku_thumbnail(50);
        window.init_graphics();
        await dummy_tick_sku_thumbnail(50);
        window.Module.load_cabinet_in_dummy_design(sku_id,false);

        window.three_interface.needs_redraw = true;

        await dummy_tick_sku_thumbnail(500);
    
        //generating preview and updating thumbnail
        window.Module.open_seller_preview(sku_id)
        close_modal();
        var form_data = new FormData();
        var blob = new Blob([window.FS.root.contents["preview.png"]["contents"].slice(0,window.FS.root.contents["preview.png"]["usedBytes"])], {type: "image/png"});
        var file_json = new File([blob], sku_id + "_autogenerated_preview.png");
        form_data.append('sku_id', sku_id)
        form_data.append("upl",file_json);
        var resp = await fetch(window.Module.API.server_path + "/sku/update",{
            method:"POST",
            body:form_data,
            credentials: "include",
            headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
        });

        return resp.json();
    }

    const bulk_update_thumbnail_sku = async(sku_ids) => {
        for(var i=0;i<sku_ids.length;i++){
            console.log("-------------");
            console.log("UPDATING THUMBNAIL " + (i+1) + "/" + sku_ids.length);
            console.log("SKU ID - " + sku_ids[i]);
            var resp = await load_and_update_thumbnail_of_sku(sku_ids[i]);
            if(resp && resp.response_code == 1){
                console.log("SUCCESS");
                alert.success("SUCCESS - " + sku_ids[i]);
            }else{
                console.log("FAIL");
                alert.error("FAILURE - " + sku_ids[i]);
            }
            console.log("-------------");
        }
    }

    window.get_skus_in_subcat = async(sub_category_id) => {
        var active_user = window.Module.get_active_user();
        let business_unit_id = active_user.business_unit_id
        // var resp = await fetch(window.Module.API.server_path + "/inventory/get_groups",{
        //     method:"POST",
        //     body:JSON.stringify({sku_sub_category_id: sub_category_id, business_unit_id: business_unit_id}),
        //     credentials: "include",
        //     headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
        // });

        var resp = await general_fetch({full_url: window.Module.API.server_path + "/inventory/get_groups", body: {sku_sub_category_id: sub_category_id, business_unit_id: business_unit_id}, return_raw_response: true})

        if(resp && resp.response_code == 1){
            let sku_ids = []
            let temp = resp && resp.data && resp.data.map(x => x.sku && x.sku.map(sku => sku.id && sku_ids.push(sku.id)))
            // console.log(sku_ids, "get sku subcat")
            await bulk_update_thumbnail_sku(sku_ids)
        }else{
            console.log("FAIL");
            alert.error("FAILURE UPDATING SUB CAT - " + sub_category_id);
            return
        }
    }

    window.get_skus_in_cat = async(cat) => {
        var active_user = window.Module.get_active_user();
        let business_unit_id = active_user.business_unit_id
        // var resp = await fetch(window.Module.API.server_path + "/inventory/get_sub_categories",{
        //     method:"POST",
        //     body:JSON.stringify({sku_category_id: cat, business_unit_id: business_unit_id}),
        //     credentials: "include",
        //     headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
        // });
        var resp = await general_fetch({full_url: window.Module.API.server_path + "/inventory/get_sub_categories", body: {sku_category_id: cat, business_unit_id: business_unit_id}, return_raw_response: true})

        if(resp && resp.response_code == 1){
            let sku_ids = []
            let temp = resp && resp.data && resp.data.map(x => x.sku_group && x.sku_group.map(sku_group => sku_group.sku && sku_group.sku.map(sku => sku.id && sku_ids.push(sku.id))))
            // console.log(sku_ids, "get sku cat")
            await bulk_update_thumbnail_sku(sku_ids)
        }else{
            console.log("FAIL");
            alert.error("FAILURE UPDATING CAT - " + cat);
            return
        }
    }

    const update_common_viewport_options = () => {
        let new_common_viewport_options = JSON.parse(window.Module.get_common_viewport_options())
        if(!window._.isEqual(new_common_viewport_options,common_viewport_options)){
            set_common_viewport_options(new_common_viewport_options);
        }
    }

    const update_view_options = () => {
        let new_view_options = JSON.parse(window.Module.get_view_specific_options())
        if (!window._.isEqual(view_options, new_view_options)) {
            set_view_options(new_view_options);
        }
    }

    const update_status_bar = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        var new_show_status_bar = active_view.show_status_bar;
        var old_show_status_bar = show_status_bar
        if(old_show_status_bar != new_show_status_bar){
            set_show_status_bar(new_show_status_bar);
        }

        var new_status_bar_content = active_view.status_bar_content;
        var old_status_bar_content = status_bar_content
        if(old_status_bar_content != new_status_bar_content){
            set_status_bar_content(new_status_bar_content);
        }

        var new_status_bar_tooltip = active_view.status_bar_tooltip;
        var old_status_bar_tooltip = status_bar_tooltip
        if(old_status_bar_tooltip != new_status_bar_tooltip){
            set_status_bar_tooltip(new_status_bar_tooltip);
        }

        var new_status_bar_type = active_view.status_bar_type;
        var old_status_bar_type = status_bar_type
        if(old_status_bar_type != new_status_bar_type){
            set_status_bar_type(new_status_bar_type);
        }

        var new_status_bar_buttons = JSON.parse(active_view.get_status_bar_buttons());
        var old_status_bar_buttons = status_bar_buttons;

        if(!window._.isEqual(new_status_bar_buttons,old_status_bar_buttons)){  
            set_status_bar_buttons(new_status_bar_buttons);
        }
        

        

        active_design.delete();
        active_view.delete();
    }

    const update_bottom_status_bar = () => {
        var active_design = window.Module.get_active_design();

        var new_bottom_status_bar_message = active_design.bottom_status_bar_message;
        var old_bottom_status_bar_message = bottom_status_bar_message
        if (old_bottom_status_bar_message != new_bottom_status_bar_message) {
            set_bottom_status_bar_message(new_bottom_status_bar_message);
        }

        var new_bottom_status_bar_show = active_design.bottom_status_bar_show;
        var old_bottom_status_bar_show = bottom_status_bar_show
        if (old_bottom_status_bar_show != new_bottom_status_bar_show) {
            set_bottom_status_bar_show(new_bottom_status_bar_show);
        }

        active_design.delete();
    }

    const update_help_center = () => {
        var active_design = window.Module.get_active_design();

        var new_help_center_url = active_design.help_center_url;
        var old_help_center_url = help_center_url
        if (old_help_center_url != new_help_center_url) {
            set_help_center_url(new_help_center_url);
        }

        var new_show_help_center = active_design.show_help_center;
        var old_show_help_center = show_help_center
        if (old_show_help_center != new_show_help_center) {
            set_show_help_center(new_show_help_center);
        }

        active_design.delete();
    }

    const update_command_line = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        var new_show_command_line = active_view.show_command_line;
        var old_show_command_line = show_command_line
        if(old_show_command_line != new_show_command_line){
            set_show_command_line(new_show_command_line);
        }        
        
        var new_command_line_icon = active_view.command_line_icon;
        var old_command_line_icon = command_line_icon
        if(old_command_line_icon != new_command_line_icon){
            set_command_line_icon(new_command_line_icon);
        }

        var new_command_line_content = active_view.command_line_content;
        var old_command_line_content = command_line_content
        if(old_command_line_content != new_command_line_content){
            set_command_line_content(new_command_line_content);
        }

        var new_command_line_valid = active_view.command_line_valid;
        var old_command_line_valid = command_line_valid
        if(old_command_line_valid != new_command_line_valid){
            set_command_line_valid(new_command_line_valid);
        }

        var new_command_line_valid_route = active_view.command_line_valid_route;
        var old_command_line_valid_route = command_line_valid_route
        if(old_command_line_valid_route != new_command_line_valid_route){
            set_command_line_valid_route(new_command_line_valid_route);
        }

        

        var new_command_line_options = JSON.parse(active_view.get_command_line_buttons());
        // window.Module.toolbar_router(buttons.route)
        var old_command_line_options = command_line_options;

        if(!window._.isEqual(new_command_line_options,old_command_line_options)){  
            set_command_line_options(new_command_line_options);
        }

        active_design.delete();
        active_view.delete();
    }

    const update_comment_mode = () => {
        var active_design = window.Module.get_active_design();
        // var active_view = active_design.active_view;

        var comment_mode_new = active_design.is_comment_mode;
        if(is_comment_mode != comment_mode_new){
            set_is_comment_mode(comment_mode_new);
            if(window.two_interface){window.two_interface.handleWindowResize()}
        }

        active_design.delete();
        // active_view.delete();
    }

    const update_camera_positioning_mode = () => {
        var active_design = window.Module.get_active_design();
        // var active_view = active_design.active_view;

        var camera_positioning_mode_new = active_design.camera_positioning_mode;
        if(camera_positioning_mode != camera_positioning_mode_new){
            set_camera_positioning_mode(camera_positioning_mode_new);
            // set_render_mini_url()
            if(camera_positioning_mode_new){
                update_camera_and_render_preview()
            }
        }


        active_design.delete();
        // active_view.delete();
    }

    const update_camera_position_value = () => {
        let active_design = window.Module.get_active_design()
            if(active_design){
                let angle = window.Module.rotatable_slider_get_camera_vertical_angle()
                set_camera_angle(angle + Math.PI)
                set_camera_angle_input(angle)
                set_camera_angle_input_copy(angle)
                let camera_position_json = (JSON.parse(window.Module.rotatable_slider_get_camera_height()))
                if(camera_position_json && camera_position_json.bottom !== undefined && camera_position_json.top !== undefined && camera_position_json.height !== undefined){
                    // console.log("camera height react init values 1", camera_position_json, (camera_position_json.height-camera_position_json.bottom), (camera_position_json.top-camera_position_json.bottom), (camera_position_json.height-camera_position_json.bottom)/(camera_position_json.top-camera_position_json.bottom) )
                    set_camera_position(100 - (camera_position_json.height-camera_position_json.bottom) / (camera_position_json.top-camera_position_json.bottom) * 100)
                    set_camera_position_input(camera_position_json.height)
                    set_camera_position_input_copy(camera_position_json.height)
                    set_camera_max_height(camera_position_json.top)
                    set_camera_min_height(camera_position_json.bottom)
                }
            }
        active_design.delete()
    }

    const update_comment_pins = () => {
        var active_design = window.Module.get_active_design();
        // var active_view = active_design.active_view;

        var new_comment_position_json = JSON.parse(active_design.stringified_comment_positions);
        if(!window._.isEqual(new_comment_position_json,comment_position_json)){
            // console.log("comment postion json", new_comment_position_json)
            set_comment_position_json(new_comment_position_json);
        }

        active_design.delete();
        // active_view.delete();
    }

    const update_panel_json = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        var new_panel_show = active_view.panel_show;
        var old_panel_show = panel_show
        // if(panel_show != new_panel_show){
        //     set_panel_show(new_panel_show);
        // }

        set_panel_show((prev) => {
            if(prev != new_panel_show){
                return new_panel_show
            }else{
                return prev
            }
        })

        if(!new_panel_show || window.Module.is_current_panel_fresh()){
            set_scroll_pos(0)
        }

        var new_panel_show_tree = active_view.panel_show_tree;
        if(panel_show_tree != new_panel_show_tree){
            set_panel_show_tree(new_panel_show_tree);
        }

        var new_panel_collapsed = active_view.panel_collapse;
        var old_panel_collapsed = panel_collapsed
        if(panel_collapsed != new_panel_collapsed){
            set_panel_collapsed(new_panel_collapsed);
        }

        var new_panel_explode = active_view.panel_explode;
        var old_panel_explode = panel_explode
        if(panel_explode != new_panel_explode){
            set_panel_explode(new_panel_explode);
        }

        var new_panel_heading = active_view.panel_heading;
        if(panel_heading != new_panel_heading){
            set_panel_heading(new_panel_heading);
        }

        var new_panel_heading_icon = active_view.panel_heading_icon;
        if(panel_heading_icon != new_panel_heading_icon){
            set_panel_heading_icon(new_panel_heading_icon);
        }

        var new_panel_heading_icon_route = active_view.panel_heading_icon_route;
        if(panel_heading_icon_route != new_panel_heading_icon_route){
            set_panel_heading_icon_route(new_panel_heading_icon_route);
        }

        // var new_panel_heading_back_show = active_view.show_panel_back_button;
        // if(panel_heading_back_show != new_panel_heading_back_show){
        //     set_panel_heading_back_show(new_panel_heading_back_show);
        // }

        var new_panel_json_default_tree_selection = active_view.active_tree_level_id;
        if(panel_json_default_tree_selection != new_panel_json_default_tree_selection){
            set_panel_json_default_tree_selection(new_panel_json_default_tree_selection);
        }

        var new_panel_json = JSON.parse(active_view.get_panel_menu());
        var old_panel_json = panel_json;

        if(!window._.isEqual(new_panel_json,old_panel_json) || (old_panel_show != new_panel_show) || panel_has_unapplied_edits || window.Module.is_current_panel_fresh()){
            if(window.debug_mode){
                console.log("new_panel_json", new_panel_json);
            }
            if(new_panel_json && !Array.isArray(new_panel_json)){
                logToSentry(`Invalid panel json detected, panel json - ${new_panel_json}`, "Invalid panel json detected")
            }
            set_panel_json(new_panel_json);
            if((window._.isEqual(new_panel_json,old_panel_json) && (panel_has_unapplied_edits || window.Module.is_current_panel_fresh())) || (!window._.isEqual(new_panel_json,old_panel_json) && !panel_has_unapplied_edits)){
                if(!active_design.is_comment_mode) set_panel_key(Date.now() + Math.random());
            }
            set_panel_has_unapplied_edits(false);
        }
        var new_panel_heading_label;
        if(active_view.panel_heading_label == ""){
            new_panel_heading_label = {}
        }else{
            new_panel_heading_label = JSON.parse(active_view.panel_heading_label);
        }
        if(!window._.isEqual(new_panel_heading_label, panel_heading_label)){
            set_panel_heading_label(new_panel_heading_label);
        }

        var new_show_panel_info_at_bottom = active_view.show_panel_info_at_bottom;
        if(show_panel_info_at_bottom != new_show_panel_info_at_bottom){
            set_show_panel_info_at_bottom(new_show_panel_info_at_bottom);
        }

        var new_panel_info_list = JSON.parse(active_view.get_empty_panel_info_list())
        var old_panel_info_list = panel_info_list
        if(!window._.isEqual(new_panel_info_list,old_panel_info_list)){
            set_panel_info_list(new_panel_info_list)
        }

        active_design.delete();
        active_view.delete();
    }

    const update_panel_pop_up_json = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        var new_panel_pop_up_show = active_view.panel_pop_up_show;
        var old_panel_pop_up_show = panel_pop_up_show
        if(panel_pop_up_show != new_panel_pop_up_show){
            set_panel_pop_up_show(new_panel_pop_up_show);
        }

        // var new_panel_pop_up_collapsed = active_view.panel_pop_up_collapse;
        // var old_panel_pop_up_collapsed = panel_pop_up_collapsed
        // if(panel_pop_up_collapsed != new_panel_pop_up_collapsed){
        //     set_panel_pop_up_collapsed(new_panel_pop_up_collapsed);
        // }

        // var new_panel_pop_up_explode = active_view.panel_pop_up_explode;
        // var old_panel_pop_up_explode = panel_pop_up_explode
        // if(panel_pop_up_explode != new_panel_pop_up_explode){
        //     set_panel_pop_up_explode(new_panel_pop_up_explode);
        // }

        var new_panel_pop_up_heading = active_view.panel_pop_up_heading;
        if(panel_pop_up_heading != new_panel_pop_up_heading){
            set_panel_pop_up_heading(new_panel_pop_up_heading);
        }

        var new_panel_pop_up_json = JSON.parse(active_view.get_panel_pop_up_menu());

        if(!window._.isEqual(new_panel_pop_up_json,panel_pop_up_json) || (!new_panel_pop_up_show) || panel_pop_up_has_unapplied_edits){
            if(window.debug_mode){
                console.log(new_panel_pop_up_json);
            }
            let old_panel_pop_up_json = panel_pop_up_json;
            set_panel_pop_up_json(new_panel_pop_up_json);
            if((window._.isEqual(new_panel_pop_up_json, old_panel_pop_up_json) && panel_pop_up_has_unapplied_edits) || (!window._.isEqual(new_panel_pop_up_json, old_panel_pop_up_json) && !panel_pop_up_has_unapplied_edits) ){
                set_panel_pop_up_key(Date.now() + Math.random());
            }
            set_panel_pop_up_has_unapplied_edits(false);
            // console.log('layerset toolbar click 1', new_panel_pop_up_json)
        }

        // var new_panel_pop_up_heading = active_view.panel_pop_up_heading;
        // if(panel_pop_up_heading != new_panel_pop_up_heading){
        //     set_panel_pop_up_heading(new_panel_heading);
        // }

        active_design.delete();
        active_view.delete();
    }

    const update_panel_search_json = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        // var new_panel_pop_up_show = active_view.panel_pop_up_show;
        // var old_panel_pop_up_show = panel_pop_up_show
        // if(panel_pop_up_show != new_panel_pop_up_show){
        //     set_panel_pop_up_show(new_panel_pop_up_show);
        // }

        // var new_panel_pop_up_collapsed = active_view.panel_pop_up_collapse;
        // var old_panel_pop_up_collapsed = panel_pop_up_collapsed
        // if(panel_pop_up_collapsed != new_panel_pop_up_collapsed){
        //     set_panel_pop_up_collapsed(new_panel_pop_up_collapsed);
        // }

        // var new_panel_pop_up_explode = active_view.panel_pop_up_explode;
        // var old_panel_pop_up_explode = panel_pop_up_explode
        // if(panel_pop_up_explode != new_panel_pop_up_explode){
        //     set_panel_pop_up_explode(new_panel_pop_up_explode);
        // }

        // var new_panel_pop_up_heading = active_view.panel_pop_up_heading;
        // if(panel_pop_up_heading != new_panel_pop_up_heading){
        //     set_panel_pop_up_heading(new_panel_pop_up_heading);
        // }

        var new_panel_search_json = JSON.parse(active_view.get_panel_search_menu());

        if(!window._.isEqual(new_panel_search_json,panel_search_json)){
            if(window.debug_mode){
                console.log(new_panel_search_json);
            }
            let old_panel_search_json = panel_search_json;
            set_panel_search_json(new_panel_search_json);
            // if((window._.isEqual(new_panel_search_json, old_panel_search_json) && panel_pop_up_has_unapplied_edits) || (!window._.isEqual(new_panel_search_json, old_panel_search_json) && !panel_pop_up_has_unapplied_edits) ){
            //     set_panel_pop_up_key(Date.now() + Math.random());
            // }
            // set_panel_pop_up_has_unapplied_edits(false);
            // console.log('layerset toolbar click 1', new_panel_pop_up_json)
        }

        var new_is_search_mode = active_view.get_panel_search_show();
        if(new_is_search_mode != is_search_mode){
            set_is_search_mode(new_is_search_mode);
        }
        var new_panel_search_string = active_view.panel_search_string;
        if(window.debug_mode){
            console.log("panel search string",new_panel_search_string);
        }
        if(new_panel_search_string != panel_search_string){
            set_panel_search_string(new_panel_search_string);
        }
        if(!new_is_search_mode){
            set_search_string_panel('');
        }

        // var new_panel_pop_up_heading = active_view.panel_pop_up_heading;
        // if(panel_pop_up_heading != new_panel_pop_up_heading){
        //     set_panel_pop_up_heading(new_panel_heading);
        // }

        active_design.delete();
        active_view.delete();
    }

    const update_panel_current_page_json = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        var new_panel_current_page_json = JSON.parse(active_view.get_panel_current_page_json());

        if(!window._.isEqual(new_panel_current_page_json,panel_current_page_json)){
            
            if(window.debug_mode){
                console.log("panel current page json", new_panel_current_page_json);
            }
            set_panel_current_page_json(new_panel_current_page_json);
            set_panel_current_page_has_unapplied_edits(false)
        }else{
            if(panel_current_page_has_unapplied_edits){
                set_panel_current_page_has_unapplied_edits(false)
                set_panel_current_page_key(Date.now() + Math.random())
            }
        }

        var new_panel_current_page_heading = active_view.panel_current_page_heading;
        if(panel_current_page_heading != new_panel_current_page_heading){
            set_panel_current_page_heading(new_panel_current_page_heading);
        }

        var new_panel_current_page_show = active_view.panel_current_page_show;
        if(panel_current_page_show != new_panel_current_page_show){
            set_panel_current_page_show(new_panel_current_page_show);
        }

        active_design.delete();
        active_view.delete();
    }

    const update_panel_pop_up_search_json = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        var new_panel_pop_up_search_json = JSON.parse(active_view.get_panel_pop_up_search_menu());

        if(!window._.isEqual(new_panel_pop_up_search_json,panel_pop_up_search_json)){
            if(window.debug_mode){
                console.log(new_panel_pop_up_search_json);
            }
            let old_panel_pop_up_search_json = panel_pop_up_search_json;
            set_panel_pop_up_search_json(new_panel_pop_up_search_json);
        }

        var new_is_search_mode_panel_pop_up = active_view.get_panel_pop_up_search_show();
        if(new_is_search_mode_panel_pop_up != is_search_mode_panel_pop_up){
            set_is_search_mode_panel_pop_up(new_is_search_mode_panel_pop_up);
        }
        var new_panel_pop_up_search_string = active_view.panel_pop_up_search_string;
        if(window.debug_mode){
            console.log("panel_pop_up search string",new_panel_pop_up_search_string);
        }
        if(new_panel_pop_up_search_string != panel_pop_up_search_string){
            set_panel_pop_up_search_string(new_panel_pop_up_search_string);
        }
        if(!new_is_search_mode_panel_pop_up){
            set_search_string_panel_pop_up('');
        }

        active_design.delete();
        active_view.delete();
    }

    const update_tooltip_canvas = () => {
        var active_design = window.Module.get_active_design();
        if(!active_design){
            return;
        }

        var active_view = active_design.active_view;

        if(!active_view){
            return;
        }

        var new_tooltip_for_cursor = active_view.tooltip_for_cursor;
        if(tooltip_for_cursor != new_tooltip_for_cursor){
            set_tooltip_for_cursor(new_tooltip_for_cursor);
        }

        var new_tooltip_for_cursor_content = active_view.tooltip_for_cursor_content;
        if(tooltip_for_cursor_content != new_tooltip_for_cursor_content){
            set_tooltip_for_cursor_content(new_tooltip_for_cursor_content);
        }

        var new_tooltip_for_cursor_x = active_view.tooltip_for_cursor_x;
        if(tooltip_for_cursor_x != new_tooltip_for_cursor_x){
            set_tooltip_for_cursor_x(new_tooltip_for_cursor_x);
        }

        var new_tooltip_for_cursor_y = active_view.tooltip_for_cursor_y;
        if(tooltip_for_cursor_y != new_tooltip_for_cursor_y){
            set_tooltip_for_cursor_y(new_tooltip_for_cursor_y);
        }

        var new_tooltip_for_cursor_color = active_view.tooltip_for_cursor_color;
        if(tooltip_for_cursor_color != new_tooltip_for_cursor_color){
            set_tooltip_for_cursor_color(new_tooltip_for_cursor_color);
        }

        // var new_panel_pop_up_collapsed = active_view.panel_pop_up_collapse;
        // var old_panel_pop_up_collapsed = panel_pop_up_collapsed
        // if(panel_pop_up_collapsed != new_panel_pop_up_collapsed){
        //     set_panel_pop_up_collapsed(new_panel_pop_up_collapsed);
        // }

        // var new_panel_pop_up_explode = active_view.panel_pop_up_explode;
        // var old_panel_pop_up_explode = panel_pop_up_explode
        // if(panel_pop_up_explode != new_panel_pop_up_explode){
        //     set_panel_pop_up_explode(new_panel_pop_up_explode);
        // }

        // var new_panel_pop_up_heading = active_view.panel_pop_up_heading;
        // if(panel_pop_up_heading != new_panel_pop_up_heading){
        //     set_panel_pop_up_heading(new_panel_pop_up_heading);
        // }

        // var new_panel_pop_up_json = JSON.parse(active_view.get_panel_pop_up_menu());

        // if(!window._.isEqual(new_panel_pop_up_json,panel_pop_up_json) || (!new_panel_pop_up_show) || panel_pop_up_has_unapplied_edits){
        //     if(window.debug_mode){
        //         console.log(new_panel_pop_up_json);
        //     }
        //     set_panel_pop_up_json(new_panel_pop_up_json);
        //     set_panel_pop_up_has_unapplied_edits(false);
        //     // console.log('layerset toolbar click 1', new_panel_pop_up_json)
        //     set_panel_pop_up_key(Date.now() + Math.random());
        // }

        // var new_panel_pop_up_heading = active_view.panel_pop_up_heading;
        // if(panel_pop_up_heading != new_panel_pop_up_heading){
        //     set_panel_pop_up_heading(new_panel_heading);
        // }

        active_design.delete();
        active_view.delete();
    }

    const update_carousel_json = (new_search_string) => {
        if(new_search_string==undefined){
            //this is only to avoid async error of set_search_string
            new_search_string = search_string;
        }

        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        
        var new_carousel_show = active_view.carousel_show;
        if(carousel_show != new_carousel_show){
            if(new_carousel_show){
                new_search_string = ''
                set_search_string('')
            }else{
                set_carousel_active_tab(0)
            }
            set_carousel_show(new_carousel_show);
        }

        var new_carousel_minimize = active_view.carousel_minimize;
        if(new_carousel_minimize != carousel_minimize){
            set_carousel_minimize(new_carousel_minimize)
        }

        var new_carousel_onclick = active_view.carousel_onclick;
        if(carousel_onclick != new_carousel_onclick){
            set_carousel_onclick(new_carousel_onclick);
        }

        var new_carousel_onclick_stringified_json = active_view.carousel_onclick_stringified_json;
        if(carousel_onclick_stringified_json != new_carousel_onclick_stringified_json){
            set_carousel_onclick_stringified_json(new_carousel_onclick_stringified_json);
        }

        var new_carousel_subcategories = JSON.parse(active_view.get_carousel_sub_categories(new_search_string));
        if(!window._.isEqual(carousel_subcategories,new_carousel_subcategories)){
            set_carousel_subcategories(JSON.parse(JSON.stringify(new_carousel_subcategories)));
        }

        var new_carousel_eligible_categories = JSON.parse(active_view.get_carousel_eligible_categories());
        if(!window._.isEqual(carousel_eligible_categories,new_carousel_eligible_categories)){
            // console.log(new_carousel_eligible_categories);
            set_carousel_eligible_categories(JSON.parse(JSON.stringify(new_carousel_eligible_categories)));
        }

        var new_active_category_id = active_view.active_carousel_category_id
        if(active_category_id != new_active_category_id){
            set_carousel_active_tab(0);
            set_active_category_id(new_active_category_id);
        }

        var new_carousel_search_mode = active_view.carousel_search_mode
        if(carousel_search_mode != new_carousel_search_mode){
            set_carousel_search_mode(new_carousel_search_mode);
            if(!new_carousel_search_mode){
                set_carousel_search_string("")
                set_carousel_search_category_filter_value([])
                set_carousel_search_sub_category_filter_value([])
            }
        }

        var new_carousel_search_category_filter_options = JSON.parse(active_view.get_carousel_search_filter_options("category"));
        if(!window._.isEqual(carousel_search_category_filter_options,new_carousel_search_category_filter_options)){
            set_carousel_search_category_filter_options(JSON.parse(JSON.stringify(new_carousel_search_category_filter_options)));
        }

        var new_carousel_search_sub_category_filter_options = JSON.parse(active_view.get_carousel_search_filter_options("sub_category"));
        if(!window._.isEqual(carousel_search_sub_category_filter_options,new_carousel_search_sub_category_filter_options)){
            set_carousel_search_sub_category_filter_options(JSON.parse(JSON.stringify(new_carousel_search_sub_category_filter_options)));
        }


        

        var new_carousel_sku_json = JSON.parse(active_view.get_carousel_sku_parameters(search_string));
        if(!window._.isEqual(carousel_sku_json,new_carousel_sku_json)){
            set_carousel_sku_json(new_carousel_sku_json);
            if(!(new_carousel_sku_json && new_carousel_sku_json.length)){
                set_carousel_sku_parameters_show(false)
            }
            console.log(new_carousel_sku_json, "sku data");
        }


        active_design.delete();
        active_view.delete();
    }

    const update_modal_json = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        var new_modal_json = JSON.parse(active_view.get_modal_menu())

        let _re_render = false
        
        var new_modal_type = active_view.modal_type;
        if(modal_properties.type != new_modal_type){
            // set_modal_type(new_modal_type);
            // set_modal_type(new_modal_type)
            modal_properties.type = new_modal_type
            _re_render = true
        }

        if(!window._.isEqual(new_modal_json, modal_properties.json)){
            // set_modal_json(new_modal_json);
            modal_properties.json = new_modal_json
            _re_render = true
        }

        if(_re_render){
            forceReRender()
        }

        active_design.delete();
        active_view.delete();
    }

    const search_carousel_onchange = (val) => {
        set_search_string(val.toLowerCase())
        update_carousel_json(val.toLowerCase())
    }    

    const navbar_component_click = () => {
        window.Module.navbar_component_click();
        update_view();
    }

    const navbar_floorplan_click = () => {
        if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
            if(window.debug_mode){
                window.add_debug_log("navbar_floorplan_click();");
            }
            window.Module.navbar_floorplan_click();
            update_view();
        }else{
            alert.error("Unlock the view to switch modes");
        }
    }

    const navbar_3d_click = () => {
        if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
            if(window.debug_mode){
                window.add_debug_log("navbar_3d_click();");
            }
            window.Module.navbar_3d_click();
            update_view();
            window.three_interface.em_interface.needs_redraw = true;
        }else{
            alert.error("Unlock the view to switch modes");
        }
    }

    const navbar_elevation_click = (id) => {
        if(id == "add_elevation_view"){
            if(window.debug_mode){
                window.add_debug_log("add_elevation_view_frontend(\"\");");
            }
            window.Module.add_elevation_view_frontend("");
            update_view();
        }else{
            if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
                if(window.debug_mode){
                    var cam_index = 0;
                    elevation_cameras.map((o, idx) => {
                        if(o.id == id){
                            cam_index = idx;
                        }
                    });

                    window.add_debug_log("navbar_elevation_click(active_design->world->elevation_cameras["+cam_index+"]->id);");
                }
                window.Module.navbar_elevation_click(id);
                update_view();
            }else{
                alert.error("Unlock the view to switch modes");
            }
        }
    }

    const go_to_world = () => {
        if(interface_mode == "design_edit_mode"){
            if(window.debug_mode){
                window.add_debug_log("go_top()");
            }
            window.Module.go_top();
            update_view();
            window.three_interface.em_interface.needs_redraw = true;
        }
    }
    const is_view_locked = () => {

        let locked = false;
        let active_design = window.Module.get_active_design()

        if (active_design){

            let active_view = active_design.active_view

            locked = active_view && active_view.locked

            if (active_view) {
                active_view.delete()
            }
            active_design.delete()
        }

        return locked
    }

    const switch_material = async (mat_id, json_item, is_panel_pop_up) => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.active_material_select_item_id = json_item.id;
        var onclick_params = {}
        onclick_params.panel_id = json_item.id
        onclick_params.sku_id = mat_id

        var final_params = json_item.pass_params_to_carousel_onclick?JSON.stringify(onclick_params):mat_id;
        if(window.debug_mode){
            window.add_debug_log(json_item.route + "(\"" + final_params.replace(/"/g, '\\"') + "\");");
        }
        var resp = await window.Promisify(window.Module[json_item.route](final_params));
        handle_ui_response(resp);
        if(is_panel_pop_up){
            repopulate_panel_pop_up();
        }else{
            repopulate_panel();
        }
        update_view();

        active_design.delete();
        active_view.delete();
    }

    const handle_ui_response = async (stringified_response) => {
        try{
        if(!stringified_response){
            return;
        }

        //optimization code
        if(stringified_response.nativeEvent){
            stringified_response = stringified_response.nativeEvent.custom_data;

            if(!stringified_response){
                return;
            }
        }

        var resp = JSON.parse(stringified_response);

        if(!remote_view && !seller_specific && resp.wait_for_version_save_queue){
            await wait_for_version_save_queue();
        }
        
        if(resp.update_modal_json){
            update_modal_json();
        }

        if(resp.update_cursor_tooltip){
            update_tooltip_canvas();
        }

        if(resp.update_panel_json){
            update_panel_json();
        }

        if(resp.update_design_name){
            update_design_name();
        }

        if(resp.update_command_line){
            update_command_line()
        }

        if (resp.update_bottom_status_bar) {
            update_bottom_status_bar()
        }

        if (resp.update_help_center) {
            update_help_center()
        }

        if (resp.recreate_panel) {
            set_panel_key(Date.now() + Math.random())
        }

        if (resp.material_apply) {
            let data = resp.material_apply.data;
            if(data){
                let is_panel_pop_up = data.is_panel_pop_up;
                switch_material(resp.material_apply.id, data, is_panel_pop_up);
            }
        }

        if(resp.loader){
            if(resp.loader.show_loader){
                var text = resp.loader.text
                setTimeout(async() => {
                    set_page_loader({
                        show: true,
                        text: text
                    });	
                },0)
            }else if(resp.loader.remove_loader){
                setTimeout(async() => {
                    set_page_loader({
                        show: false,
                        text: "Please wait"
                    });	
                },0)
            }
        }

        if(resp.call_core_with_loader){
            setTimeout(async() => {
                set_page_loader({
                    show: true,
                    text: resp.call_core_with_loader.text
                });	
            },1)
            setTimeout(async() => {
                var response = "";
                try{
                    response = await window.Promisify(window.Module.call_core_with_loader(resp.call_core_with_loader.purpose));
                }catch(err){
                    console.error(err);
                    response = "error";
                }
                handle_ui_response(response);
                set_page_loader({
                    show: false,
                    text: "Please wait..."
                });	
            },200)
        }

        if(resp.await_core_operation_non_blocking){
            setTimeout(async() => {
                set_page_loader({
                    show: true,
                    text: resp.await_core_operation_non_blocking.text
                });	
            },1)
            var id = resp.await_core_operation_non_blocking.id;
            setTimeout(async() => {
                var await_core_interval = setInterval(async () => {
                    try{
                        var resp = await window.Promisify(window.Module.get_await_core_status(String(id)));
                        var resp_json = JSON.parse(resp);
                        console.log(resp_json["status"] + " status")
                        if(resp_json["change_loader_text"]){
                            setTimeout(async() => {
                                set_page_loader({
                                    show: true,
                                    text: resp_json["change_loader_text"]
                                });	
                            },1)
                        }

                        var remove_loader = false;
                        if(id=="load_design"){
                            if(resp_json["status"] == "error"){
                                set_page_loader({
                                    show: true,
                                    text: 'design_fail'
                                });
                                console.log("Something went wrong, could not load design" );
                                remove_loader = true
                                var err = new Error("design_init returned error");

                                err.constructor.name.includes("Error") != true?err=new Error(err):null;
                                err.name = "Captured error in Initial design load (branch=" + active_branch_id + "): " + err.name;
                                console.error(err);
                                window.sentry_capture_exception("",err);

                                throw err;
                                // alert.error("Something went wrong, could not load design");
                            }else if(resp_json["status"] == "DESIGN_BRANCH_DOES_NOT_EXIST" || resp_json["status"] == "DESIGN_VERSION_DOES_NOT_EXIST"){
                                console.log("DESIGN_BRANCH_DOES_NOT_EXIST" );
            
                                var url = '/';
                                (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                                history.push(url);
                                
                                alert.error("Design does not exist");
                                remove_loader = true
                            }else if(resp_json["status"] == "no_write_access"){
                                console.log("no_write_access" );
            
                                var url = '/';
                                (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                                history.push(url);
                                var active_user = window.Module.get_active_user();
                                alert.error("The current user "+ active_user.designer_email + " does not have write access to this design in " + active_user.store_name + " workspace",{timeout:10000});
                                remove_loader = true
                            }else if(resp_json["status"] == "UNAUTHORIZED_DESIGN_BRANCH_ACCESS") {
                                console.log("UNAUTHORIZED_DESIGN_BRANCH_ACCESS" );
                                set_page_loader({
                                    show: true,
                                    text: 'unauthorized_access'
                                });
                                remove_loader = true
                            }else if(resp_json["status"] == "success"){
                                remove_loader = true
                            }
                        }
                        if(resp_json["status"] == "execution_done"){
                            remove_loader = true;
                        }

                        if(mes_editor && remove_loader && id == "load_design"){
                            navbar_3d_click();
                            window.Module.mes_3d_viewer_focus_component(component_id);
                            window.Module.set_work_order_id(work_order_id);
                            window.Module.set_work_order_component_id(component_id);
                            set_seller_sku_details(JSON.parse(window.Module.mes_get_sku_details(component_id)));

                            const urlParams = new URLSearchParams(window.location.search);
                            if(urlParams.get("p")){
                                var panel_id = urlParams.get("p");
                                window.Module.mes_select_element_by_id(panel_id);
                            }
                        }

                        if(id=="sku_load"){
                            if(resp_json["status"]=="failed"){
                                var url = '/';
                                // (org_key&&window.location.origin.includes("infurnia.com")?(url):null);
                                history.push(url);
                                var active_user = window.Module.get_active_user();
                                alert.error("SKU does not exist!",{timeout:5000});
                                remove_loader = true
                            }
                        }
                        if(id=="mes_editor_init"){
                            if(resp_json["status"]=="failed"){
                                var url = '/';
                                // (org_key&&window.location.origin.includes("infurnia.com")?(url):null);
                                history.push(url);
                                var active_user = window.Module.get_active_user();
                                alert.error("Work order component does not exist!",{timeout:5000});
                                remove_loader = true
                            }
                        }

                        if(remove_loader){
                            clearInterval(await_core_interval);
                            setTimeout(async() => {
                                set_page_loader({
                                    show: false,
                                    text: "Please wait..."
                                });	
                            },1)
                        }
                    }catch(err){
                        clearInterval(await_core_interval);
                        setTimeout(async() => {
                            set_page_loader({
                                show: true,
                                text: "design_fail"
                            });	
                        },1)
                        alert.error("Something went wrong, could not load design");
                    }
                },100)
    
            },200)
        }

        if(resp.update_comments_position){
            update_comment_pins()
        }

        if(resp.update_panel_json){
            update_panel_json();
        }

        if(resp.update_modal_json){
            update_modal_json();
        }

        if(resp.update_camera_positioning_mode){
            update_camera_positioning_mode()
        }

        if(resp.update_camera_positioning_value){
            update_camera_position_value()
        }

        if(resp.update_camera_preview){
            set_render_mini_url()
        }

        if(resp.update_panel_heading){
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            var new_panel_heading_label;
            if(active_view.panel_heading_label == ""){
                new_panel_heading_label = {}
            }else{
                new_panel_heading_label = JSON.parse(active_view.panel_heading_label);
            }
            if(!window._.isEqual(new_panel_heading_label, panel_heading_label)){
                set_panel_heading_label(new_panel_heading_label);
            }
            active_design.delete()
            active_view.delete()
        }

        if(resp.upload_texture){
            try{
                set_page_loader({
                    show:true,
                    text:'Please wait, uploading texture...'
                })
                let fd;
                if(resp.upload_texture.form_data)
                    fd = resp.upload_texture.form_data
                var resp_texture = await general_fetch({ full_url: window.Module.API.server_path + '/texture/add', body:fd, is_form_data:true });
                if(resp.upload_texture.callback && resp.upload_texture.type){
                    resp_texture && resp_texture.id && resp.upload_texture.callback(resp.upload_texture.type, resp_texture.id)
                }
            } catch(err){
                console.log(err)
            }
            set_page_loader({
                show:false,
                text:''
            })
        }

        if(resp.upload){
            var a = document.createElement('input');
            a.type = "file";
            a.accept = ".jpg, .jpeg, .png"
            a.onchange = async (e) => {
                if(e.target.value != ""){
                    // var reader = new FileReader();
                    // var filename = e.target.files[0].name;
                    // reader.onload = async () => {
                    //     await window.FS.writeFile(filename,reader.result);
                    //     window.Module[resp.upload.callback](filename);
                    // };
                    // reader.readAsBinaryString(e.target.files[0])

                    let file = e.target.files[0];

                    if(!is_valid_file_type(file, ['jpeg','jpg', 'png'])){
                        alert.error("Invalid file format! Please upload a file with a valid format(jpeg, jpg, png).")
                    }else{
                        var form_data = new FormData();
                        form_data.append("file",file);
                        form_data.append("user_id",window.Module.get_active_user().designer_id);
                        var response = await fetch(window.Module.API.server_path + "/file_transfer/upload",{
                            method:"POST",
                            body:form_data,
                            credentials: "include",
                            headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
                        });

                        var response_json = await response.json();

                        var params = {
                            filename: response_json.file.filename,
                            file_src: response_json.file.path,
                            id: resp.upload.id
                        }

                        window.Module[resp.upload.callback](JSON.stringify(params));
                        update_view();
                    }
                }
            }
            a.click();
        }

        if(resp.fire_dwg_upload){
            // var form_data = {}
            var a = document.createElement('input');
            var analytics_data = {}
            analytics_data.category = "Import DWG";
            send_analytics(analytics_data,true)
            a.type = "file";
            a.accept = ".dwg"
            a.onchange = async (e) => {
                if(e.target.value != ""){
                    if(!e.target.value.endsWith(".dwg")){
                        alert.error("Files with extension .dwg are only allowed for upload.")
                    }else{
                    var form_data = new FormData();
                    form_data.append("dwg_file",e.target.files[0]);
                    form_data.append("design_branch_id", (window._.last(window.location.pathname.split('/'))));
                    form_data.append("action","import");
                    if(resp.fire_dwg_upload.for_blocks && resp.fire_dwg_upload.for_blocks == true){
                        form_data.append("args","import_as_json");
                    }else{
                        form_data.append("args","import_as_svg");
                    }

                    set_page_loader({
                        show: true,
                        text: 'Uploading DWG...'
                    });

                    var response = await fetch(window.Module.API.server_path + "/dwg/fire",{
                        method:"POST",
                        body:form_data,
                        credentials: "include",
                        headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
                    });

                    var response_json = await response.json();
                    

                    if(response_json.response_code == 1){
                        alert.success("Your dwg has been added to the queue")
                        set_page_loader({
                            show:true,
                            text: 'Processing DWG data...'
                        });
                        var dwg_upload_interval = setInterval(async () => {
                            var status_response = await fetch(window.Module.API.server_path + "/dwg/get_status",{
                                method:"POST",
                                body:JSON.stringify({ids:[response_json.data]}),
                                credentials: "include",
                                headers:{'Content-Type':'application/json','X-Request-Id':window.Module.generateRandomHex32String()}
                            });
                            console.log(response_json.data + " dwg id")

                            var status = await status_response.json()

                            if(status.response_code == 1){
                                if(status.data[response_json.data].status == "completed"){
                                    clearInterval(dwg_upload_interval)
                                    var download_link_path = status.data[response_json.data].output_file_path
                                    window.ignore_onbeforeunload = true;
                                    setTimeout(() => window.ignore_onbeforeunload = false, 200);
                                    window.Module[resp.fire_dwg_upload.callback](JSON.stringify({output_file_paths:download_link_path,id:resp.fire_dwg_upload.id}));
                                    set_page_loader({
                                        show:false,
                                        text: 'Processing DWG...'
                                    })
                                    // alert.success("DWG inserted into floorplan")
                                }else if(status.data[response_json.data].status == "failed"){
                                    alert.error("Your output could not be generated. Please try again later.", {timeout:10000000})
                                    clearInterval(dwg_upload_interval)
                                }
                            }else{
                                clearInterval(dwg_upload_interval)
                                console.log("Checking status failed");
                                alert.error("Something went wrong with output generation. Please try again later")
                            }
                        },5000)
                    }else{
                        if(response_json.response_code == -1 && response_json.code == "API_RATE_LIMIT_EXCEEDED"){
                            alert.info("Already in progress, the output request is in the queue.")
                        }else{
                            alert.error("Something went wrong while adding output to the download queue. Please try again later")
                        }
                        set_page_loader({
                            show:false,
                            text: 'Processing DWG...'
                        })
                    }
                    }
                }
            }
            a.click()
        }

        if(resp.export_dwg){
            // var form_data = {}
            var analytics_data = {}
            analytics_data.category = "Export DWG";
            analytics_data.DWG_For = resp.export_dwg.purpose;
            send_analytics(analytics_data,true)
            var form_data = new FormData();
            console.log(resp.export_dwg.data)
            form_data.append("data",resp.export_dwg.data);
            form_data.append("action","export");
            var response = await fetch(window.Module.API.server_path + "/dwg/fire",{
                method:"POST",
                body:form_data,
                credentials: "include",
                headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
            });

            var response_json = await response.json();

            if(response_json.response_code == 1){
                const alert_1 = alert.info("Your output has been added to the download queue", {timeout:10000000})
                var json_service_interval = setInterval(async () => {
                    var status_response = await fetch(window.Module.API.server_path + "/dwg/get_status",{
                        method:"POST",
                        body:JSON.stringify({ids:[response_json.data]}),
                        credentials: "include",
                        headers:{'Content-Type':'application/json','X-Request-Id':window.Module.generateRandomHex32String()}
                    });

                    var status = await status_response.json()

                    if(status.response_code == 1 && Object.keys(status.data).length > 0){
                        if(status.data[Object.keys(status.data)[0]].status == "completed"){
                            alert.success("Your output is ready")
							alert.remove(alert_1)
                            clearInterval(json_service_interval)

                            var download_link_path = window.Module.API.server_path + "/" + JSON.parse(status.data[Object.keys(status.data)[0]].output_file_path).prefix+"/final.dwg"
                            var dataMain = JSON.parse(resp.export_dwg.data)
                            if(dataMain.output_file_name){
                                download_link_path = window.Module.API.server_path + "/" + JSON.parse(status.data[Object.keys(status.data)[0]].output_file_path).prefix+"/"+dataMain.output_file_name
                            }

                            var downloadLink = document.createElement("a");
                            console.log('download_name:: ',resp.export_dwg.download_name);
                            if(resp.export_dwg.download_name){
                                downloadLink.download = resp.export_dwg.download_name;
                            }else {
                                downloadLink.download = "";
                            }

                            window.ignore_onbeforeunload = true;
                            setTimeout(() => window.ignore_onbeforeunload = false, 200);
                            downloadLink.href = download_link_path;
                            downloadLink.click();
                        }else if(status.data[Object.keys(status.data)[0]].status == "failed"){
                            alert.error("Your output could not be generated. Please try again later.", {timeout:10000000})
                            clearInterval(json_service_interval)
                        }
                    }else{
                        console.log("Checking status failed");
                        clearInterval(json_service_interval)
                        alert.error("Something went wrong with output generation. Please try again later")
                    }
                },5000)
            }else{
                if(response_json.response_code == -1 && response_json.code == "API_RATE_LIMIT_EXCEEDED"){
                    alert.info("Already in progress, the output request is in the queue.")
                }else{
                    alert.error("Something went wrong while adding output to the download queue. Please try again later")
                }
            }
        }

        if(resp.upload_dwg){
            var a = document.createElement('input');
            a.type = "file";
            a.accept = ".dwg"
            a.onchange = async (e) => {
                if(e.target.value != ""){
                    // var reader = new FileReader();
                    // var filename = e.target.files[0].name;
                    // reader.onload = async () => {
                    //     await window.FS.writeFile(filename,reader.result);
                    //     window.Module[resp.upload.callback](filename);
                    // };
                    // reader.readAsBinaryString(e.target.files[0])

                    var form_data = new FormData();
                    form_data.append("dwgFile",e.target.files[0]);
                    // form_data.append("user_id",window.Module.get_active_user().designer_id);
                    var response = await fetch(window.Module.API.dwg_server_path + "/dwg2json",{
                        method:"POST",
                        body:form_data,
                        credentials: "include",
                        headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
                    });

                    set_page_loader({
                        show: true,
                        text: 'Processing...'
                    });

                    var response_json = await response.json();

                    // window.dwg = response_json;
                    // fetch(window.Module.API.dwg_server_path + "/" + response_json.data.output_file_path,{mode:'no-cors'})
                    // .then(p => p.json()).then(p => {
                    //     window.dwg = p
                    // })

                    if(response_json.response_code==1){
                        window.Module[resp.upload_dwg.callback](JSON.stringify(response_json));
                    }else{
                        alert.error("Could not import dwg file.");
                    }


                    set_page_loader({
                        show: false,
                        text: 'Please wait...'
                    });
                }
            }
            a.click();
        }

        if(resp.fire_print_json){
            var form_data = new FormData();
            var active_user = window.Module.get_active_user();
            let business_unit_id = active_user.business_unit_id;

            var split = resp.fire_print_json.link.split("/")
            var file = window.FS.root;
            split.map(o => {
                file = file["contents"][o]
            })
            // var file = window.FS.root.contents[resp.download.link].contents;
            var blob = new Blob([file["contents"].slice(0,file["usedBytes"])], {type: "text/json"});
            var file_json = new File([blob], "print_json_"+window.Module.uuidv4() + ".json");

            form_data.append("file",file_json);
            form_data.append("output_format",resp.fire_print_json.output_format);
            form_data.append("type",resp.fire_print_json.type);
            form_data.append("design_version_id",resp.fire_print_json.design_version_id);
            form_data.append("business_unit_id",business_unit_id);
            if(resp.fire_print_json.hasOwnProperty('portrait')){
                form_data.append("portrait",resp.fire_print_json.portrait)
            }
            if(resp.fire_print_json.hasOwnProperty('upload_images') && resp.fire_print_json.upload_images == true){
                var cached_image_paths = JSON.parse(window.Module.get_pricing_quotation_images_for_print());
                for(var i=0;i<cached_image_paths.length;i++){
                    var split = cached_image_paths[i].split("/")
                    var file = window.FS.root;
                    var filename_exact = split[split.length-1];
                    split.map(o => {
                        file = file["contents"][o]
                    })
                    var blob = new Blob([file["contents"].slice(0,file["usedBytes"])], {type: "image/png"});
                    var file_json = new File([blob], filename_exact);
    
                    form_data.append("images",file_json,filename_exact);
                }
            }
            var response = await fetch(window.Module.API.server_path + "/json_to_xlsx_request/fire",{
                method:"POST",
                body:form_data,
                credentials: "include",
                headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
            });

            var response_json = await response.json();

            if(response_json.response_code == 1){
                const alert_1 = alert.info("Your output has been added to the download queue", {timeout:10000000})
                var json_service_interval = setInterval(async () => {
                    var status_response = await fetch(window.Module.API.server_path + "/json_to_xlsx_request/get_status",{
                        method:"POST",
                        body:JSON.stringify({ids:[response_json.data]}),
                        credentials: "include",
                        headers:{'Content-Type':'application/json','X-Request-Id':window.Module.generateRandomHex32String()}
                    });

                    var status = await status_response.json()

                    if(status.response_code == 1 && Object.keys(status.data).length > 0){
                        if(status.data[Object.keys(status.data)[0]].status == "completed"){
                            alert.success("Your output is ready")
							alert.remove(alert_1)
                            clearInterval(json_service_interval)

                            var download_link_path = window.Module.API.server_path + "/" + status.data[Object.keys(status.data)[0]].output_path

                            var downloadLink = document.createElement("a");
                            console.log('download_name:: ',resp.fire_print_json.download_name);
                            if(resp.fire_print_json.download_name){
                                downloadLink.download = resp.fire_print_json.download_name;
                            }else {
                                downloadLink.download = "";
                            }

                            window.ignore_onbeforeunload = true;
                            setTimeout(() => window.ignore_onbeforeunload = false, 200);
                            downloadLink.href = download_link_path;
                            downloadLink.click();
                        }else if(status.data[Object.keys(status.data)[0]].status == "failed"){
                            alert.error("Your output could not be generated. Please try again later.", {timeout:10000000})
                            clearInterval(json_service_interval)
                        }
                    }else{
                        console.log("Checking status failed");
                        clearInterval(json_service_interval)
                        alert.error("Something went wrong with output generation. Please try again later")
                    }
                },5000)
            }else{
                if(response_json.response_code == -1 && response_json.code == "API_RATE_LIMIT_EXCEEDED"){
                    alert.info("Already in progress, the output request is in the queue.")
                }else{
                    alert.error("Something went wrong while adding output to the download queue. Please try again later")
                }
                
            }
        }

        if (resp.set_design_thumbnail){
            var imgData = window.get_snapshot_data(450,253);
            if(imgData != null && imgData != undefined && imgData != "") {
                window.Promisify(window.Module.set_design_thumbnail(imgData)).then(
                    resp => {
                        if (resp == "success"){
                            alert.success("Successfully saved snapshot");
                        }else{
                            alert.error("Snapshot could not be generated");
                        }
                    })
            }
        }

        if(resp.fire_presentation_print){
            // var form_data = {}
            var form_data = new FormData();
            var active_user = window.Module.get_active_user();
            let business_unit_id = active_user.business_unit_id;

            form_data.append("commands", JSON.stringify([resp.fire_presentation_print.command]));
            form_data.append("design_branch_id", (window._.last(window.location.pathname.split('/'))));
            form_data.append("design_version_id",resp.fire_presentation_print.design_version_id);
            form_data.append("business_unit_id",business_unit_id);

            form_data.append("comment", "from design app");
            
            //removing cached images from presentation print
            // var cached_image_paths = JSON.parse(window.Module.get_presentation_cached_images_for_print());
            // for(var i=0;i<cached_image_paths.length;i++){
            //     var split = cached_image_paths[i].split("/")
            //     var file = window.FS.root;
            //     var filename_exact = split[split.length-1];
            //     split.map(o => {
            //         file = file["contents"][o]
            //     })
            //     // var file = window.FS.root.contents[resp.download.link].contents;
            //     var blob = new Blob([file["contents"].slice(0,file["usedBytes"])], {type: "image/png"});
            //     var file_json = new File([blob], filename_exact);

            //     // console.log(filename_exact, file["contents"].slice(0,file["usedBytes"]))

            //     form_data.append("images",file_json,filename_exact);
            // }

            if(resp.fire_presentation_print.command == "GetActivePresentationSheetPdf"){
                form_data.append("additional_params", JSON.stringify({presentation_sheet_index: resp.fire_presentation_print.presentation_sheet_index}));
            }else if (resp.fire_presentation_print.command == "GetSelectedPresentationSheetsPdf"){
                form_data.append("additional_params", JSON.stringify({presentation_sheet_indices: resp.fire_presentation_print.presentation_sheet_indices}));
            }
            
            var response = await fetch(window.Module.API.server_path + "/production_detail/get_output",{
                method:"POST",
                body:form_data,
                credentials: "include",
                headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
            });

            var response_json = await response.json();

            if(response_json.response_code == 1){
                const alert_1 = alert.info("Your output has been added to the download queue", {timeout:10000000})
                var presentation_print_interval = setInterval(async () => {
                    var status_response = await fetch(window.Module.API.server_path + "/production_detail/get_status",{
                        method:"POST",
                        body:JSON.stringify({ids:[response_json.data.request_batch_id]}),
                        credentials: "include",
                        headers:{'Content-Type':'application/json','X-Request-Id':window.Module.generateRandomHex32String()}
                    });

                    var status = await status_response.json()

                    if(status.response_code == 1 && status.data.length > 0){
                        if(status.data[0].status == "completed"){
                            alert.success("Your output is ready")
							alert.remove(alert_1)
                            clearInterval(presentation_print_interval)

                            var download_link_path = window.Module.API.server_path + "/" + status.data[0].output_file_path

                            var downloadLink = document.createElement("a");
                            console.log('download_name:: ',resp.fire_presentation_print.download_name);
                            if(resp.fire_presentation_print.download_name){
                                downloadLink.download = resp.fire_presentation_print.download_name;
                            }else {
                                downloadLink.download = "";
                            }

                            window.ignore_onbeforeunload = true;
                            setTimeout(() => window.ignore_onbeforeunload = false, 200);
                            downloadLink.href = download_link_path;
                            downloadLink.click();
                        }else if(status.data[0].status == "failed"){
                            alert.error("Your output could not be generated. Please try again later.", {timeout:10000000})
                            clearInterval(presentation_print_interval)
                        }
                    }else{
                        console.log("Checking status failed");
                        alert.error("Something went wrong with output generation. Please try again later")
                        clearInterval(presentation_print_interval)
                    }
                },5000)
            }else{
                if(response_json.response_code == -1 && response_json.code == "API_RATE_LIMIT_EXCEEDED"){
                    alert.info("Already in progress, the output request is in the queue.")
                }else{
                    alert.error("Something went wrong while adding output to the download queue. Please try again later")
                }
            }
        }

        if(resp.download){
            if(resp.download.download_type == "local"){
                var split = resp.download.link.split("/")
                var file = window.FS.root;
                split.map(o => {
                    file = file["contents"][o]
                })
                // var file = window.FS.root.contents[resp.download.link].contents;
                if(file && file["contents"]){
                var blob = new Blob([file["contents"].slice(0,file["usedBytes"])], {type: resp.download.mime_type || "application/pdf"});
                var link=document.createElement('a');
                let blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl
                link.download = resp.download.download_name;
                link.design_version_id = resp.download.design_version_id;
                link.click();
				alert.success('Successfully downloaded files')
                URL.revokeObjectURL(blobUrl);
            }else{
                    alert.error("Cannot download empty content")
                }
            }else{
                var link = resp.download.link;
                var downloadLink = document.createElement("a");
                console.log('download_name:: ',resp.download.download_name);
                if(resp.download.download_name){
                    downloadLink.download = resp.download.download_name;
                }else {
                    downloadLink.download = "";
                }

                window.ignore_onbeforeunload = true;
                setTimeout(() => window.ignore_onbeforeunload = false, 200);
                downloadLink.href = link;
                // downloadLink.target = '_';
                // document.body.appendChild(downloadLink);
                downloadLink.click();
                // document.body.removeChild(downloadLink);
            }
        }

        if(resp.zip_download){
            var zip = new JSZip.default();
            
            // var folder = zip.folder("folder_name");
            // folder.file("file1",data);
            var has_empty_content = false
            for(var i=0;i<resp.zip_download.downloads.length;i++){
                var split = resp.zip_download.downloads[i].link.split("/")
                var file = window.FS.root;
                split.map(o => {
                    file = file["contents"][o]
                })
                if(file && file["contents"]){
                    var blob = new Blob([file["contents"].slice(0,file["usedBytes"])], {type: resp.zip_download.downloads[i].mime_type || "application/pdf"});
                    zip.file(resp.zip_download.downloads[i].download_name,blob, {
                        comment: resp.zip_download.design_version_id
                    });
                }else{
                    has_empty_content = true;
                }
            }
            if(has_empty_content === true){
                alert.error("Cannot download empty content")
            }

            var content = await zip.generateAsync({type:"blob"})
            saveAs(content, resp.zip_download.download_name);
            alert.success('Successfully downloaded files')
        }

        if(resp.add_sku){
            var form_data = new FormData();
            form_data.append("name",resp.add_sku.name);
            form_data.append("placement_id","base");
            form_data.append("store_id",window.Module.get_active_user().store_id);
            form_data.append("global",false);
            form_data.append("order",1);
            form_data.append("sku_group_id",resp.add_sku.sku_group_id);
            if(resp.add_sku.component_file){
                form_data.append("component_file",resp.add_sku.component_file);
            }
            form_data.append("business_unit_id",resp.add_sku.business_unit_id);

            if(resp.add_sku.model_id){
                form_data.append('low_model_3d_id', resp.add_sku.model_id)
            }

            if(resp.add_sku.owned_by_designer){
                form_data.append('owned_by_designer', resp.add_sku.owned_by_designer)
            }

            var new_dims = window.calculate_presentation_aspect();

            if(resp.add_sku.attach_screenshot){
                var p_aspect = Number(new_dims[0])/Number(new_dims[1]);

                var image_blob;
					
                if (resp.add_sku.use_fs_image){
                    image_blob = new Blob([window.FS.root.contents[resp.add_sku.use_fs_image].contents]);
                }else{
                    image_blob = window.dataURItoBlob(window.get_snapshot_data(800,800/p_aspect,"webgl_main_canvas",true));
                }
                form_data.append("upl",image_blob);
            }
            if(resp.add_sku.attach_schedule_snap){
                var image_blob = window.dataURItoBlob(window.get_snapshot_data(1600,800,"webgl_main_canvas",true));
                form_data.append("upl",image_blob);
            }

            var response = await fetch(window.Module.API.server_path + "/sku/create",{
                method:"POST",
                body:form_data,
                credentials: "include",
                headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
            });

            var response_json = await response.json();

            if(resp.add_sku.model_id){
                let dim = await process3dModel(resp.add_sku.glb_src, response_json.data.id, set_page_loader)
                let component_file = [{ "id": "7d977730-291a-4259-", "type": "obj_unit", "name": "Core_Element", "container_component_id": null, data: { width: Number(dim.width), depth: Number(dim.depth), height: Number(dim.height) } }]
                let body = { sku_id: response_json.data.id, component_file: JSON.stringify(component_file) }
                let resp_2 = await general_fetch({ url: '/sku/update', body })
            }
            if(response_json.response_code == 1 && !resp.add_sku.no_toast){
                alert.success("Successfully published");
            }
            if(resp.add_sku.call_core_function){
                let ui_response = await window.Promisify(window.Module[resp.add_sku.call_core_function](response_json.data.id) );
                handle_ui_response(ui_response);
            }
        }

        if(resp.add_sku_from_design){
            var form_data = new FormData();
            form_data.append("name",resp.add_sku_from_design.name);
            form_data.append("placement_id",resp.add_sku_from_design.placement_id);
            form_data.append("store_id",window.Module.get_active_user().store_id);
            form_data.append("global",false);
            form_data.append("order",1);
            form_data.append("sku_group_id",resp.add_sku_from_design.sku_group_id);
            if(resp.add_sku_from_design.component_file){
                form_data.append("component_file",resp.add_sku_from_design.component_file);
            }
            form_data.append("business_unit_id",resp.add_sku_from_design.business_unit_id);

            if(resp.add_sku_from_design.owned_by_designer){
                form_data.append('owned_by_designer', resp.add_sku_from_design.owned_by_designer)
            }

            if(resp.add_sku_from_design.model_id){
                let component_file = [{ "id": "7d977730-291a-4259-", "type": "obj_unit", "name": "Core_Element", "container_component_id": null, data: { width: Number(resp.add_sku_from_design.width), depth: Number(resp.add_sku_from_design.depth), height: Number(resp.add_sku_from_design.height) } }]
                form_data.append("component_file", JSON.stringify(component_file) )
                form_data.append("low_model_3d_id", resp.add_sku_from_design.model_id)
            }

            if (resp.add_sku_from_design.use_fs_image){
                image_blob = new Blob([window.FS.root.contents[resp.add_sku_from_design.use_fs_image].contents]);
                form_data.append("upl",image_blob);
            }

            if(resp.add_sku_from_design.top_view_colour_id){
                let top_colour_id
                try{
                    let body = {image_paths: [resp.add_sku_from_design.top_view_colour_id]}
                    top_colour_id = await general_fetch({url: '/image/get_by_path', body})
                }catch(err){
                    console.error("Could not find the image ", resp.add_sku_from_design.top_view_colour_id)
                }
                if(top_colour_id && top_colour_id.length){
                    form_data.append("top_view_colour_id", top_colour_id[0])
                }
            }

            if(resp.add_sku_from_design.top_view_grayscale_id){
                let top_grayscale_id
                try{
                    let body = {image_paths: [resp.add_sku_from_design.top_view_grayscale_id]}
                    top_grayscale_id = await general_fetch({url: '/image/get_by_path', body})
                }catch(err){
                    console.error("Could not find the image ", resp.add_sku_from_design.top_view_grayscale_id)
                }
                if(top_grayscale_id && top_grayscale_id.length){
                    form_data.append("top_view_grayscale_id", top_grayscale_id[0])
                }
            }


            var response = await fetch(window.Module.API.server_path + "/sku/create",{
                method:"POST",
                body:form_data,
                credentials: "include",
                headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
            });

            var response_json = await response.json();
            if(response_json.response_code == 1 && !resp.add_sku_from_design.no_toast){
                alert.success("Successfully published");
            }
        }

        if(resp.update_sku){
            var form_data = new FormData();
            form_data.append("sku_id",resp.update_sku.sku_id);
            form_data.append("placement_id","base");
            form_data.append("store_id",window.Module.get_active_user().store_id);
            form_data.append("global",false);
            form_data.append("order",1);
            if (resp.update_sku.component_file){
                form_data.append("component_file",resp.update_sku.component_file);
            }
            form_data.append("business_unit_id",resp.update_sku.business_unit_id);
            if(resp.update_sku.attach_screenshot){
                var p_aspect = 1.414;
                var cnvs = document.getElementById('webgl_main_canvas');
                if(cnvs){
                    p_aspect = Number(cnvs.clientWidth)/Number(cnvs.clientHeight);
                }

                var image_blob = window.dataURItoBlob(window.get_snapshot_data(800,800/p_aspect,undefined,true));

                if (resp.update_sku.use_fs_image){
                    image_blob = new Blob([window.FS.root.contents[resp.update_sku.use_fs_image].contents]);
                }
                form_data.append("upl",image_blob);
            }
            if(resp.update_sku.attach_schedule_snap){
                var image_blob = window.dataURItoBlob(window.get_snapshot_data(1600,800,undefined,true));
                form_data.append("upl",image_blob);
            }

            var response = await fetch(window.Module.API.server_path + "/sku/update",{
                method:"POST",
                body:form_data,
                credentials: "include",
                headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
            });

            var response_json = await response.json();

            if(response_json.response_code == 1 && !resp.update_sku.no_toast){
                if (resp.update_sku.toast){
                    alert.success(resp.update_sku.toast);
                }
                else {
                    alert.success("Successfully updated in catalogue");
                }
            }
            if(resp.update_sku.call_core_function){
                window.Module[resp.update_sku.call_core_function](resp.update_sku.sku_id);
            }
        }

        if(resp.toast){
            if(resp.toast.type == "info" || resp.toast.type == "success" || resp.toast.type == "error"){
                let alertArg = [resp.toast.parse ? JSON.parse(resp.toast.message) : resp.toast.message, {type: resp.toast.type}]
                if(resp.toast.timeout != undefined){
                    alertArg[1].timeout = resp.toast.timeout
                }
                alert.show(...alertArg);
            }
            else if(resp.toast.type == "save_response"){
                if(current_alert){
                    alert.remove(current_alert);
                    current_alert = null;
                }
                current_alert = alert.show(resp.toast.message,{type:"status",position:positions.TOP_RIGHT})
            }
        }
        
        if(resp.refresh_page){
            window.location.reload(true);
        }

        if(resp.download_screenshot){
           var canvas_id = "webgl_main_canvas";
           
           var canvas = document.getElementById(canvas_id);
           canvas.toBlob((blob) => {
            // Create a temporary URL for the Blob object
            const blobUrl = URL.createObjectURL(blob);

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = blobUrl;
            downloadLink.download = 'Screenshot.png'; // You can set the filename here
            downloadLink.click();

            // Clean up the temporary URL
            URL.revokeObjectURL(blobUrl);
            });
        }

        if(resp.trigger_window_resize){
            window.two_interface.handleWindowResize()
        }

        if(resp.trigger_feature_guide){
            window.trigger_feature_guide()
        }

        if(resp.update_view){
            update_view();
        }

        if (resp.update_presentation_zoom){
            update_presentation_zoom();
        }

        if(resp.update_panel_toolbar){
            update_panel_toolbar();
        }
        }catch(err){
            console.log(err);
            window.sentry_capture_exception("",err);
        }
    }

    window.handle_ui_response = handle_ui_response

    const update_panel_toolbar = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        var new_panel_toolbar_menu = JSON.parse(active_view.get_panel_toolbar());
        if(!window._.isEqual(new_panel_toolbar_menu,panel_toolbar_json)){
            set_panel_toolbar_json(new_panel_toolbar_menu)
        }
        active_view.delete();
        active_design.delete();
    }

    const update_presentation_zoom = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        if(active_view.interface_mode == "presentation"){
            window.two_interface.em_interface = window.Module.get_sheet_interface();
            window.two_interface.em_interface.needs_redraw = true;
            window.two_interface.handleWindowResize();
        }
        active_view.delete();
        active_design.delete();
    }

    const update_view = () => {
        try{
        // console.log("update_view_triggered");
        var t = performance.now();
        var active_design = window.Module.get_active_design();

        if(!active_design){
            return;
        }

        var active_view = active_design.active_view;

        if(!active_view){
            active_design.delete();
            return;
        }
        
        if(active_view.do_not_update_view){
            active_design.delete();
            active_view.delete();
            return;
        }

        var old_perspective = perspective;
        var old_interface_mode = interface_mode;

        var viewing_mode = active_view.viewing_mode;

        var new_left_menu = JSON.parse(active_view.get_left_menu());
        var new_toolbar_menu = JSON.parse(active_view.get_new_toolbar());
        var new_panel_toolbar_menu = JSON.parse(active_view.get_panel_toolbar());

        // console.log("left_menu_json_file", left_menu_json_file);
        
        if(!window._.isEqual(new_left_menu,left_menu_json_file)){
            set_left_menu_json_file(new_left_menu)
        }

        if(!window._.isEqual(new_toolbar_menu,toolbar_json_file)){
            set_toolbar_json_file(new_toolbar_menu)
        }

        if(!window._.isEqual(new_panel_toolbar_menu,panel_toolbar_json)){
            set_panel_toolbar_json(new_panel_toolbar_menu)
        }

        if(viewing_mode.perspective != perspective){
            set_perspective(viewing_mode.perspective);
        }

        if(viewing_mode.mode != viewing_level){
            set_viewing_level(viewing_mode.mode);
        }

        update_design_views();
        update_level_arrays();
        update_elevation_cameras();
        update_custom_plan_cameras();

        if(active_view.interface_mode != interface_mode){
            set_interface_mode(active_view.interface_mode);
        }

        if(active_design.project_name != project_name){
            set_project_name(active_design.project_name)
        }

        if(active_design.design_name != design_name){
            set_design_name(active_design.design_name)
        }
        if(active_design.project_order_id != project_id){
            set_project_id(active_design.project_order_id)
        }
        if(active_design.project_client_name != client_name){
            set_client_name(active_design.project_client_name)
        }

        if(active_design.metric_system != metric_system){
            set_metric_system(active_design.metric_system)
        }
        if(active_design.approximation_ft != approximation_ft){
            set_approximation_ft(active_design.approximation_ft)
        }
        if(active_design.approximation_mm != approximation_mm){
            set_approximation_mm(active_design.approximation_mm)
        }
        if(active_view.apply_discard != apply_discard){
            set_apply_discard(active_view.apply_discard)
        }
        if(active_view.apply_discard_mode != apply_discard_mode){
            set_apply_discard_mode(active_view.apply_discard_mode)
        }

        if(mes_editor){
            var temp_mes_component = window.Module.get_single_modular_elements_for_mes(component_id);
            if(temp_mes_component){
                temp_mes_component = JSON.parse(temp_mes_component);
                set_mes_component(temp_mes_component);
                if(active_panel){
                    var new_panel = temp_mes_component.panels.find(o => o.id == active_panel.id);
                    if(new_panel){
                        set_active_panel(new_panel);
                    }
                }
            }

            set_core_selection_ids(JSON.parse(window.Module.get_current_selection_ids()));
        }

        update_modal_json();
        update_panel_json();
        update_panel_pop_up_json();
        update_panel_search_json();
        update_panel_current_page_json()
        update_panel_pop_up_search_json();
        update_carousel_json();
        if(active_view.interface_mode == "presentation"){
            update_presentation_aspect();
        }
        update_presentation_sheets();
        update_boq_schedules();
        update_board_layout_sheets();
        update_board_layout_sheet_types();
        update_status_bar()
        update_command_line()
        update_bottom_status_bar()
        update_help_center()
        // update_tooltip_canvas();
        update_comment_mode()
        update_camera_positioning_mode()
        update_common_viewport_options()
        update_view_options()
        
        //TODO update only if new different from old
        if(old_interface_mode != active_view.interface_mode || old_perspective != viewing_mode.perspective){
            window.two_interface.perspective = viewing_mode.perspective;
            window.two_interface.interface_mode = active_view.interface_mode;
            
            if(active_view.interface_mode == "presentation"){
                window.two_interface.em_interface = window.Module.get_sheet_interface();
                window.two_interface.em_interface.needs_redraw = true;
                window.two_interface.handleWindowResize();
            }else if(viewing_mode.perspective == '3d'){
                window.two_interface.handleWindowResize();
            }else if(viewing_mode.perspective == 'floorplan'){
                if(active_view.interface_mode == "design_edit_mode" || active_view.interface_mode == "dynamic_component_sketcher_mode"){
                    window.two_interface.em_interface = window.Module.get_floorplanner();
                    window.two_interface.em_interface.needs_redraw = true;
                    // window.two_interface.em_interface.handleWindowResize(window.$('#canvas_container_div').width(),window.$('#canvas_container_div').height())
                    window.two_interface.handleWindowResize();
                }else if(active_view.interface_mode == "sketcher_2d" || active_view.interface_mode == "tiling_cutting" || active_view.interface_mode == "panel_2d_d2m"){
                    window.two_interface.em_interface = window.Module.get_sketcher_2d();
                    window.two_interface.em_interface.needs_redraw = true;
                    window.two_interface.handleWindowResize();
                }else if(active_view.interface_mode == "boq_mode"){
                    window.two_interface.em_interface = window.Module.get_boq_interface();
                    window.two_interface.em_interface.needs_redraw = true;
                    window.two_interface.handleWindowResize();
                }else if(active_view.interface_mode == "board_layout_mode"){
                    window.two_interface.em_interface = window.Module.get_board_layout_interface();
                    window.two_interface.em_interface.needs_redraw = true;
                    setTimeout(()=>{window.two_interface.handleWindowResize();},2)
                }else if(active_view.interface_mode == "quotation_mode"){
                    window.two_interface.em_interface = window.Module.get_quotation_interface();
                    window.two_interface.em_interface.needs_redraw = true;
                    window.two_interface.handleWindowResize();
                }
            }else if(viewing_mode.perspective == 'component'){
                window.two_interface.em_interface = window.Module.get_floorplanner();
                window.two_interface.em_interface.needs_redraw = true;
                window.two_interface.em_interface.handleWindowResize(window.$('#canvas_container_div').width(),window.$('#canvas_container_div').height());
            }else if(viewing_mode.perspective == 'elevation'){
                window.two_interface.em_interface = window.Module.get_elevationplanner();
                window.two_interface.em_interface.needs_redraw = true;
                window.two_interface.handleWindowResize();
            }
        }

        // console.log("update_view took "+(performance.now()-t)+ " milliseconds")

        active_design.delete();
        active_view.delete();
        viewing_mode.delete();
        }catch(err){
            console.log(err);
            window.sentry_capture_exception("",err);
        }
    }

    window.update_view = update_view

    const update_design_views = () => {
        var new_design_views = JSON.parse(window.Module.get_design_views_info());
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        var new_is_free_view_active = active_design.is_free_view(active_view)
        var new_active_view_name = window.Module.get_active_view_name()

        if(new_active_view_name != active_view_name){
            set_active_view_name(new_active_view_name)
        }

        if(!window._.isEqual(design_views,new_design_views)){
            set_design_views(new_design_views);
        }

        if(new_is_free_view_active != is_free_view_active){
            set_is_free_view_active(new_is_free_view_active);
        }

        active_design.delete();
        active_view.delete();
    }

    const add_design_view = () => {
        if(window.debug_mode){
            window.add_debug_log("add_design_view();");
        }
        window.Module.add_design_view();
        update_view();

        requestAnimationFrame(scrollToViewEnd)
    }

    const switch_view = (view) => {
        if(!view.active_view){
            window.Module.switch_design_view(view.id);
            try { 
                let options = JSON.parse(window.Module.get_view_specific_options());
                // set_view_options(JSON.parse(window.Module.get_view_specific_options()));
                set_view_options(options);
            } catch (err) {
                console.log(err)
            }
            
            update_view();
        }
    }

    const switch_view_and_scroll_to_it = async(view) => {
        switch_view(view);
        requestAnimationFrame(scrollToActiveView);
    }

	// useEffect(() => {
	// 	if(design_views && design_views.length){
	// 		// check_views_sizes()
	// 	}
	// }, [design_views]);

    //not using the below funtion. maybe useful in future
	// const check_views_sizes = () => {
	// 	let available_length = (window.innerWidth/2) - 150 
	// 	let visible_length = document.getElementById('all_views_div_parent')? (document.getElementById('all_views_div_parent').getBoundingClientRect().width ) : 0
	// 	let actual_length = document.getElementById('all_views_div_inner')? document.getElementById('all_views_div_inner').scrollWidth : 0

	// 	console.log('checking lengths', available_length, actual_length, )

	// 	if(actual_length > (available_length - 45)){
	// 		let left_over = actual_length - (available_length - 45)
	// 		let number_of_views = design_views.length 
	// 		let subtractable = left_over/(number_of_views-1)
	// 		console.log('checking lengths entered', available_length, actual_length, left_over, subtractable)
	// 		let non_active_view_idx
	// 		for(let i=0;i<number_of_views;i++){
	// 			if(!design_views[i].active_view){
	// 				non_active_view_idx = i
	// 				break
	// 			}
	// 		}
	// 		let non_active_view_length = document.getElementById('view_div'+non_active_view_idx) ? document.getElementById('view_div'+non_active_view_idx).clientWidth : 0
	// 		let show_navigate_buttons = non_active_view_length - subtractable < 32

	// 		for(let i=0;i<number_of_views;i++){
	// 			let elem = document.getElementById('view_div'+i)
	// 			let child = elem.children[0]
	// 			let grandchild = child.children[0]
	// 			if(!design_views[i].active_view){
	// 				if(show_navigate_buttons){
	// 					grandchild.style.width = '32px'
	// 				}else{
	// 					grandchild.style.width = (non_active_view_length - subtractable) + 'px'
	// 				}
	// 			}else{
	// 				grandchild.style.width = 'auto'
	// 				let scrollX = grandchild.offsetLeft - document.getElementById('all_views_div_inner').offsetLeft
	// 				grandchild.scrollLeft = scrollX
	// 			}
	// 		}
	// 	}else {
	// 		let number_of_views = design_views.length 
	// 		for(let i=0;i<number_of_views;i++){
	// 			let elem = document.getElementById('view_div'+i)
	// 			let child = elem.children[0]
	// 			let grandchild = child.children[0]
	// 			grandchild.style.width = 'auto'
	// 		}
	// 		if(document.getElementById('all_views_div_inner').scrollWidth > document.getElementById('all_views_div_inner').clientWidth) check_views_sizes()
	// 	}
	// }

    const switch_free_view = (e) => {
        if(e && e.target && e.target.nodeName == "IMG"){
            return;
        }
        if(window.debug_mode){
            window.add_debug_log("switch_free_view();");
        }
        window.Module.switch_free_view();
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        
        window.three_interface.em_interface.needs_redraw = true;
        update_view();

        active_design.delete();
        active_view.delete();
    }

    const delete_seller_sku = () => {
        window.Promisify(window.Module.delete_seller_sku(sku_id))
        .then((resp) => {
            console.log(resp);
            if(resp == "success"){
                alert.success("Saved changes succesfully!");
                window.location.reload();
            }else if(resp == "no_elements"){
                alert.info("No elements to publish")
            }else{
                alert.error("Something went wrong.")
            }
        })
    }

    const component_editor_save_onclick = () => {
        // if(mes_editor){
        //     var resp = window.Module.update_work_order_component(work_order_component_id);
		//     handle_ui_response(resp);
        // }else{
            window.Promisify(window.Module.open_seller_preview(sku_id))
            .then((resp) => {
                console.log(resp);
                handle_ui_response(resp)
            })
        // }
    }

    const refresh_seller_sku = () => {
        window.onbeforeunload = function (e) {
            if (window.Module.get_offline_save_queue_length() > 0 && !window["ignore_onbeforeunload"]) {
                e = e || window.event;
                // For IE and Firefox prior to version 4
                if (e) {
                    e.returnValue = 'You might loose some unsaved changes. Do you want to quit ?';
                }
                // For Safari
                return 'You might loose some unsaved changes. Do you want to quit ?';
            }
        };
        window.location.reload()
    }

    const add_seller_sku = async () => {
        var ui_response = await window.Promisify(window.Module.toolbar_router("addFurniture"));
		handle_ui_response(ui_response);
        update_view();
    }

    const show_seller_parameters_modal = () => {
        var ui_response = window.Module.show_seller_parameters_modal();
		handle_ui_response(ui_response);
        update_view();
    }

    const update_presentation_aspect = () => {
        var new_dims = window.calculate_presentation_aspect();
        var cnvs = document.getElementById('webgl_main_canvas');

        if(cnvs && (Math.abs(new_dims[0] - cnvs.clientWidth) > 1 || Math.abs(new_dims[1] - cnvs.clientHeight) > 1)){
            window.two_interface.handleWindowResize();
        }
    }

    const update_presentation_sheets = () => {
        var active_design = window.Module.get_active_design();
        var new_presentation_sheets = JSON.parse(active_design.get_presentation_sheets());
        if(!window._.isEqual(presentation_sheets,new_presentation_sheets)){
            set_presentation_sheets(new_presentation_sheets)
        }

        active_design.delete();
    }

    const update_boq_schedules = () => {
        var active_design = window.Module.get_active_design();
        var new_boq_schedules = JSON.parse(active_design.get_schedules());
        if(!window._.isEqual(boq_schedules,new_boq_schedules)){
            set_boq_schedules(new_boq_schedules)
        }

        active_design.delete();
    }

    const update_board_layout_sheets = () => {
        var active_design = window.Module.get_active_design();
        var new_board_layout_sheets = JSON.parse(active_design.get_board_layout_panel_sheets())
        if(!window._.isEqual(board_layout_sheets,new_board_layout_sheets)){
            set_board_layout_sheets(new_board_layout_sheets)
        }
        active_design.delete();
    }

    const update_board_layout_sheet_types = () => {
        var active_design = window.Module.get_active_design();
        var new_board_layout_sheet_types = JSON.parse(active_design.get_board_layout_panel_sheet_types())
        if(!window._.isEqual(board_layout_sheet_types,new_board_layout_sheet_types)){
            set_board_layout_sheet_types(new_board_layout_sheet_types)
        }
        active_design.delete();
    }

    const update_level_arrays = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        var new_level_arrays = JSON.parse(active_view.getLevelArrays())
        if(!window._.isEqual(level_arrays,new_level_arrays)){
            set_level_arrays(new_level_arrays)
        }
        active_design.delete();
        active_view.delete();
    }

    const update_elevation_cameras = () => {
        var cams = JSON.parse(window.Module.get_elevation_cameras_info());
        // cams.push({
        //     id: "add_elevation_view",
        //     name: "Add Elevation View"
        // })

        if(!window._.isEqual(cams,elevation_cameras)){
            set_elevation_cameras(cams)
        }
    }

    const update_custom_plan_cameras = () => {
        try{
            var cams = JSON.parse(window.Module.get_section_views_info());
            
            if(!window._.isEqual(cams,custom_plans_cameras)){
                set_custom_plans_cameras(cams)
            }
        }catch(err){
            console.log(err)
        }
    }

    const update_design_name = () => {
        var active_design = window.Module.get_active_design();

        if(active_design.design_name != design_name){
            set_design_name(active_design.design_name)
        }
        active_design.delete();
    }

    const switch_world = () => {
        if(interface_mode == "design_edit_mode"){
            if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
                var active_design = window.Module.get_active_design();
                var active_view = active_design.active_view;
                if(window.debug_mode){
                    window.add_debug_log("switchWorld()");
                }
                active_view.switchWorld();
                active_view.trigger_vr_news_based_on_level();
                update_view();
                active_design.delete();
                active_view.delete();
            }else{
                alert.error("Unlock the view to switch modes");
            }
        }
    }

    const switch_building = (id) => {
        if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            if(window.debug_mode){
                var building_index = 0;
                level_arrays.buildings.map((o, idx) => {
                    if(o.id == id){
                        building_index = idx;
                    }
                });
                
                window.add_debug_log("switchBuilding(active_design->world->buildings["+building_index+"]->id);");
            }
            active_view.switchBuilding(id);
            active_view.trigger_vr_news_based_on_level();
            update_view();
            active_design.delete();
            active_view.delete();
        }else{
            alert.error("Unlock the view to switch modes");
        }
    }

    const switch_mode = (id) => {
        // if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
            if(window.debug_mode){
                window.add_debug_log("toolbar_on_click(\"" + id + "\");");
            }
            toolbar_on_click(id)
        // }else{
        //     alert.error("Unlock the view to switch modes");
        // }
    }

    const switch_floor = (id) => {
        if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            if(window.debug_mode){
                var floor_index = 0;
                level_arrays.floors.map((o, idx) => {
                    if(o.id == id){
                        floor_index = idx;
                    }
                });
                
                window.add_debug_log("switchFloor(active_design->active_view->get_active_building()->floors["+floor_index+"]->id);");
            }
            active_view.switchFloor(id);
            active_view.trigger_vr_news_based_on_level();
            update_view();
            update_camera_position_value()
            active_design.delete();
            active_view.delete();
        }else{
            alert.error("Unlock the view to switch modes");
        }
    }

    const switch_room = (id) => {
        if(window.Module.is_view_perspectiveInterfaceModeOrLevel_change_allowed()){
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            if(window.debug_mode){
                var room_index = 0;
                level_arrays.rooms.map((o, idx) => {
                    if(o.id == id){
                        room_index = idx;
                    }
                });
                
                window.add_debug_log("switchRoom(active_design->active_view->get_active_floor()->rooms["+room_index+"]->id);");
            }
            active_view.switchRoom(id);
            active_view.trigger_vr_news_based_on_level();
            update_view();
            active_design.delete();
            active_view.delete();
        }else{
            alert.error("Unlock the view to switch modes");
        }
    }

    const switch_presentation_sheet = async(id) => {
        if(window.debug_mode){
            window.add_debug_log("switch_presentation_sheet(" + id + ");");
        }
        set_page_loader({show: true, text: "Loading Presentation Sheet..."}, async() => {
            await sleep(10)
            window.Module.switch_presentation_sheet(Number(id));
            set_page_loader({show: false, text: ""})
            window.two_interface.handleWindowResize()
            update_view();
        })
    }

    const switch_boq_schedule = (id) => {
        if(window.debug_mode){
            window.add_debug_log("switch_schedule(" + id + ");");
        }
        window.Module.switch_schedule(Number(id));
        update_view();
    }

    const switch_board_layout_sheet = (id) => {
        if(window.debug_mode){
            window.add_debug_log("switch_board_layout_sheet(" + id + ");");
        }
        window.Module.switch_board_layout_sheet(Number(id));
        update_view();
    }

    const switch_board_layout_sheet_type = (id) => {
        if(window.debug_mode){
            window.add_debug_log("switch_board_layout_sheet_type(" + id + ");");
        }
        window.Module.switch_board_layout_sheet_type(Number(id));
        update_view();
    }

    const is_seller_component_a_user_element = () => {
        return window.Module.is_seller_component_a_user_element();
    }

    const toggle_fullscreen = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {  
                document.documentElement.requestFullScreen();  
            } else if (document.documentElement.mozRequestFullScreen) {  
                document.documentElement.mozRequestFullScreen();  
            } else if (document.documentElement.webkitRequestFullScreen) {  
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);  
            }  
        }else {  
            if (document.cancelFullScreen) {  
                document.cancelFullScreen();  
            } else if (document.mozCancelFullScreen) {  
                document.mozCancelFullScreen();  
            } else if (document.webkitCancelFullScreen) {  
                document.webkitCancelFullScreen();  
            }  
        } 
    }

    const clear_design = async () => {
        if(window.Module.are_other_core_threads_busy() || window.Module.are_internal_core_threads_busy() || window.Module.are_graphics_core_threads_busy()){
            window.location.reload()
        }else{
            console.log("clearing design");
            window.destroy_graphics();
            window.Module.close_design();
        }
    }


    const close_design = async () => {
        try{
        set_page_loader({
            show: true,
            text: 'Closing Design. Please Wait...'
        },async () => {
            setTimeout(async () => {		
                var active_design = window.Module.get_active_design();
                var project_id = active_design.project_id;
                active_design.delete();

                if(!remote_view && !seller_specific){
                    var imgData = window.get_snapshot_data(450,253);
                    try{

                    if(imgData != null && imgData != undefined && imgData != "") {
                        await window.Promisify(window.Module.set_design_thumbnail(imgData));
                    }
                    
                    await wait_for_version_save_queue();

                    }catch(err){
                        if(window['sentry_capture_exception']){
                            window['sentry_capture_exception']("",err);
                        }
                    }
                }

                set_page_loader({
                    show: false,
                    text: ''
                })   

                var url = '/';
                if(window.redirect_status === 'infurnia'){
                    history.goBack()
                }else{
                    (org_key && window.location.origin.includes("infurnia.com") ? (url = "/" + org_key + url) : null);
                    history.push(url)
                }
            }, 0);
        })
        }catch(err){
            window.sentry_capture_exception("",err);
        }
    }

    const go_back_onclick = () => {
        if(window.debug_mode){
            window.add_debug_log(window.two_interface.em_interface.class_type+"->draw();");
            window.add_debug_log("go_back_onclick();");
        }
        window.Module.go_back_onclick();
        update_view();
    }

    const close_account_settings = () => {
        set_show_account_settings(false)
    }
    

    const handleKeyDown = async(event) => {
        if (event.key === 'ArrowDown' || event.key === 'Tab') {
          event.preventDefault();
          const nextIndex = (focusedOptionIndex + 1)%command_line_options.length;
        //   if (nextIndex < command_line_options.length) {
            setFocusedOptionIndex(nextIndex);
        //   }
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          let prevIndex = (focusedOptionIndex - 1)%command_line_options.length;
          if(prevIndex < 0) prevIndex = prevIndex+command_line_options.length
        //   if (prevIndex >= 0) {
            setFocusedOptionIndex(prevIndex);
        //   }
        } else if (event.key === 'Enter' || event.key == ' ') {
          event.preventDefault();
          if(!command_line_view_more && focusedOptionIndex === 4){
            onclick_view_more()
          }else if(focusedOptionIndex >= 0 && focusedOptionIndex < command_line_options.length){
            handleOptionSelect(command_line_options[focusedOptionIndex]);
          }
        }else if(event.key === 'Escape'){
            event.preventDefault();
            setFocusedOptionIndex(-1)
            set_command_line_view_more(false)
            if(window.debug_mode){
                window.add_debug_log("toolbar_router(\"close_command_line\");");
            }
            let ui_response = await window.Promisify(window.Module.toolbar_router("close_command_line"))
            handle_ui_response(ui_response);
        }
    }

    const handleOptionSelect = async(option) => {
        // setInputValue(option);
        if(option.disabled){
            alert.error('This option is disabled')
            return
        }
        set_command_line_disable_false_blur(false);
        setFocusedOptionIndex(-1);
        set_command_line_view_more(false)
        if(option){
            var analytics_data = option;
            analytics_data.category = "Command Line Shortcut Entered";
            send_analytics(analytics_data,true)
        };
        if(window.debug_mode){
            window.add_debug_log("toolbar_router(\"" + option.route.replace(/"/g, '\\"') + "\");");
        }
        let ui_response2 = await window.Promisify(window.Module.toolbar_router(option.route))
        handle_ui_response(ui_response2);
        if(window.debug_mode){
            window.add_debug_log("toolbar_router(\"close_command_line\");");
        }
        let ui_response = await window.Promisify(window.Module.toolbar_router("close_command_line"))
        handle_ui_response(ui_response);
    };

    const command_line_mousedown = (e) => {
        set_command_line_disable_false_blur(true);
    }

    const command_line_blur = async (event) => {
        if(command_line_disable_false_blur){
            if(document.getElementById('command_line_text')) document.getElementById('command_line_text').focus()
            set_command_line_disable_false_blur(false);
            return;   
        }
        // console.log(e)
        // if(e.target && e.target.id.includes("command_line")){
        //     console.log(e.target.id);
        //     return false;
        // }
        event.preventDefault();
        setFocusedOptionIndex(-1)
        set_command_line_view_more(false)
        if(window.debug_mode){
            window.add_debug_log("toolbar_router(\"close_command_line\");");
        }
        let ui_response = await window.Promisify(window.Module.toolbar_router("close_command_line"))
        handle_ui_response(ui_response);
    }

    const onchange_command = async(val) => {
        let ui_response = await window.Promisify(window.Module.command_line_on_change(val, command_line_view_more))
        handle_ui_response(ui_response);
    }
    
    const onclick_view_more = async() => {
        set_command_line_view_more(true)
        let ui_response = await window.Promisify(window.Module.command_line_on_change(command_line_content, true))
        handle_ui_response(ui_response);
        if(document.getElementById('command_line_text')) document.getElementById('command_line_text').focus()
    }


    //comments

    function createCommentPin(x, y, user, id) {
        const commentPin = document.createElement('div');
        commentPin.className = 'infurnia-comment-pin';
        commentPin.id = id
        commentPin.style.left = `${x}px`;
        commentPin.style.top = `${y}px`;
        commentPin.textContent = user;
        commentPin.borderRadius = '50%';
        commentPin.height='32px';
        commentPin.width='32px';


        // set_comment_pin_divs(comment_pin_divs.push(commentPin))
        // ... Add necessary event listeners or content to the comment pin element
        // return commentPin;
    }

    const toggle_comment_mode = async() => {
        let active_design = window.Module.get_active_design()
        let resp = await window.Promisify(active_design.set_comment_mode(!is_comment_mode))
        handle_ui_response(resp)
        
        active_design.delete()
    }

    const toggle_help_menu = () => {
        let active_design = window.Module.get_active_design()
        active_design.toggle_help_menu()
        active_design.delete()
        update_help_center()

        let analytics_data = {}
        analytics_data.category = show_help_center ? "Tool Guide Closed" : "Tool Guide Show More Clicked"
        analytics_data.link_opened = help_center_url
        send_analytics(analytics_data)

        // Manual toggle below
        // set_show_help_center(!show_help_center)
    }

    const toggle_camera_positioning_mode = () => {
        let active_design = window.Module.get_active_design()
        let resp = (active_design.set_camera_positioning_mode(!camera_positioning_mode))
        if(!camera_positioning_mode){
            if(active_design){
                update_camera_position_value()
            }
        }
        // update_view()
        update_camera_positioning_mode()
        if(camera_positioning_mode){
            var analytics_data = {}
            analytics_data.path = "Through canvas button";
            analytics_data.category = "Camera Positioning View Entered";
            send_analytics(analytics_data,true)
        }
        
        active_design.delete()
    }

    const convertToAbsolutePosition = (position, scale) => {
        const scaledX = position * (scale / 2);
        // const scaledY = position.y * (canvasHeight / 2);
        const absoluteX = scaledX + scale / 2;
        // const absoluteY = scaledY + canvasHeight / 2;
        // console.log('position, ', absoluteX)

        return absoluteX;
    };

    const clicking_of_comment = async(comment) => {
        let resp = await window.Promisify(window.Module.clicking_of_comment(comment.id))
        // handle_ui_response(resp)
        update_view()
    }

    const get_render_mini_url = () => {
        window.Module.update_preview_mini_frame();
		if(window.canvas_2d.images["preview.png"]){
			return window.canvas_2d.images["preview.png"].toDataURL()
		}
		return '';
	}

    const _set_render_preview_url = () => {
        set_render_preview_url(get_render_mini_url())
        set_aspect_ratios(JSON.parse(window.Module.get_aspect_ratios()))
        // update_camera_position_value()
        // console.log(window.Module.get_aspect_ratios(), "aspect ratios")
    }

    const set_render_mini_url = _set_render_preview_url;

    const update_camera_and_render_preview = () => {
        update_camera_position_value()
        set_render_mini_url()
    }

    const handleMouseDownPresentation = (e) => {
        // console.log(e.button, 'debug presentation pan 1')
        e.stopPropagation()
        if (e.button === 1) {
            // Middle mouse button
            setIsDraggingPresentation(true);
            // setStartXPresentation(e.clientX);
            // setStartYPresentation(e.clientY);
            startCoOrdinatesPresentation.x = e.clientX
            startCoOrdinatesPresentation.y = e.clientY
        }
    };

    const handleMouseMovePresentation = (e) => {
        e.stopPropagation()
        // console.log(isDraggingPresentation, 'debug presentation pan 2')
        if (isDraggingPresentation) {
            // const deltaX = e.clientX - startXPresentation;
            // const deltaY = e.clientY - startYPresentation;
            const deltaX = e.clientX - startCoOrdinatesPresentation.x;
            const deltaY = e.clientY - startCoOrdinatesPresentation.y;
            // console.log(deltaX, deltaY, 'debug presentation pan 3')

            // Update scroll position based on mouse movement
            let presentation_canvas = document.getElementById('canvas_container_inner')
            if(presentation_canvas){
                let presentationScrollLeft = scrollLeftPresentation - deltaX
                let presentationScrollTop = scrollTopPresentation - deltaY

                // if(presentationScrollLeft > presentation_canvas.scrollWidth || presentationScrollLeft < 0){
                //     startCoOrdinatesPresentation.x = e.clientX
                //     setScrollLeftPresentation(Math.min(Math.max(scrollLeftPresentation - deltaX,0), presentation_canvas.scrollWidth))
                // }else{
                    presentation_canvas.scrollLeft = 
                        Math.min(Math.max(scrollLeftPresentation - deltaX,0), presentation_canvas.scrollWidth);
                // }
                // if(presentationScrollTop > presentation_canvas.scrollHeight || presentationScrollLeft < 0){
                //     startCoOrdinatesPresentation.y = e.clientY
                //     setScrollTopPresentation(Math.min(Math.max(scrollTopPresentation - deltaY,0), presentation_canvas.scrollHeight));
                // }else{
                    presentation_canvas.scrollTop =
                        Math.min(Math.max(scrollTopPresentation - deltaY,0), presentation_canvas.scrollHeight);
                // }
            }
        }
    };

    const handleMouseUpPresentation = (e) => {
        e.stopPropagation()
        if (isDraggingPresentation) {
            setIsDraggingPresentation(false);
            setScrollLeftPresentation(
                document.getElementById('canvas_container_inner').scrollLeft
            );
            setScrollTopPresentation(
                document.getElementById('canvas_container_inner').scrollTop
            );
        }
    };

    const handleMouseLeavePresentation = () => {
        if (isDraggingPresentation) {
            setIsDraggingPresentation(false);
            setScrollLeftPresentation(
                document.getElementById('canvas_container_inner').scrollLeft
            );
            setScrollTopPresentation(
                document.getElementById('canvas_container_inner').scrollTop
            );
        }
    };

    const handleContextMenuPresentation = (e) => {
        // Prevent the context menu from appearing while panning
        e.preventDefault();
    };

    // useEffect(() => {
    //     // console.log(camera_position, "camera position react")
    //     set_camera_position_input(camera_position)
    // }, [camera_position]);

    // useEffect(() => {
    //     // console.log(camera_position, "camera position react")
    //     set_camera_angle_input(camera_angle)
    // }, [camera_angle]);

    useEffect(() => {
        //initiating design
        // window.update_view = update_view;
        // window.set_page_loader = set_page_loader;
        // window.handle_ui_response = handle_ui_response;
        if(login_error){
            console.log("Login error")
            return;
        }
        // Already handled in jsx by conditiional rendering. no need for manuplating display
        // try{
        //     if(window.Module.API.segment_id && (!(window.location.pathname.split('/')[1]) || (window.location.pathname.split('/')[1] != "livspace" && window.location.pathname.split('/')[1] != "zest"))){
        //         var intercom_div = document.getElementById('my_custom_intercom_link');
        //         if(intercom_div){
        //             intercom_div.style.display = "block"
        //         }
        //     }
        // }catch(err){
        //     console.error(err);
        // }
        
        if(!seller_specific){
            setTimeout(function(){ set_page_loader({
                show: true,
                text: 'Initializing...'
            });}, 1);
    
            setTimeout(async() => {
                try{
                window.init_graphics();

                if(mes_editor){
                    if(!component_id || !work_order_id){
                        //throw error
                        console.error("Missing work_order_id or component_id");
                    }
                    var work_order_stringified = await window.Promisify(window.Module.fetch_work_order_details(work_order_id));
                    if(work_order_stringified){
                        var work_order_response = JSON.parse(work_order_stringified);
                        if(work_order_response && work_order_response.response_code == 1){
                            set_work_order_details(work_order_response.data);
                            active_branch_id = work_order_response.data.mes_design_branch_id;
                        }else{
                            //throw error
                            console.error("Missing fetch_work_order_details failed ", work_order_response);
                        }
                    }
                }

                var resp;
                if(design_version_only){
                    resp = await window.Promisify(window.Module.design_init_version_id(active_branch_id));
                }else{
                    resp = await window.Promisify(window.Module.design_init(active_branch_id));
                }

                if(resp == "backward_compatibility"){
                
                }else if(resp == "error"){
                    set_page_loader({
                        show: true,
                        text: 'design_fail'
                    });
                    console.log("Something went wrong, could not load design" );

                    throw new Error("design_init returned error");
                    // alert.error("Something went wrong, could not load design");
                }else if(resp == "DESIGN_BRANCH_DOES_NOT_EXIST"){
                    console.log("DESIGN_BRANCH_DOES_NOT_EXIST" );

                    var url = '/';
                    (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                    history.push(url);
                    
                    alert.error("Design does not exist");
                }else if(resp == "no_write_access"){
                    console.log("no_write_access" );

                    var url = '/';
                    (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                    history.push(url);
                    var active_user = window.Module.get_active_user();
                    alert.error("The current user "+ active_user.designer_email + " does not have write access to this design in " + active_user.store_name + " workspace",{timeout:10000});
                }else if(resp == "UNAUTHORIZED_DESIGN_BRANCH_ACCESS") {
                    console.log("UNAUTHORIZED_DESIGN_BRANCH_ACCESS" );
                    set_page_loader({
                        show: true,
                        text: 'unauthorized_access'
                    });
                }
                else{
                    // update_view();
                    // set_page_loader({
                    //     show: false,
                    //     text: 'Loading your design'
                    // })    
                }
            }catch(err){
                set_page_loader({
                    show: true,
                    text: 'design_fail'
                });

                err.constructor.name.includes("Error") != true?err=new Error(err):null;
                err.name = "Captured error in Initial design load (branch=" + active_branch_id + "): " + err.name;
                console.error(err);
                window.sentry_capture_exception("",err);
            }
            },100)
        }/*else if(mes_editor){
            setTimeout(function(){ set_page_loader({
                show: true,
                text: 'Initializing...'
            });}, 1);
            setTimeout(async() => {
                try{
                    window.init_graphics();
                    var resp = await window.Promisify(window.Module.mes_editor_init(work_order_component_id,true));
                    set_work_order_details({});

                    // update_view();
                    set_page_loader({
                        show: false,
                        text: 'Loading your component'
                    })
                }catch(err){
                    set_page_loader({
                        show: true,
                        text: 'design_fail'
                    });
    
                    err.constructor.name.includes("Error") != true?err=new Error(err):null;
                    err.name = "Captured error in MES editor portal initial load (work_order_component_id=" + work_order_component_id + "): " + err.name;
                    console.error(err);
                    window.sentry_capture_exception("",err);
                }
            },100)
        }*/else{
            setTimeout(function(){ set_page_loader({
                show: true,
                text: 'Initializing...'
            });}, 1);
            setTimeout(async() => {
                try{
                    window.init_graphics();
                    var resp = await window.Promisify(window.Module.load_cabinet_in_dummy_design(sku_id,true));
                    set_seller_sku_details(JSON.parse(window.Module.get_sku_details_from_core()))
                    // update_view();
                    set_page_loader({
                        show: false,
                        text: 'Loading your sku'
                    })
                }catch(err){
                    set_page_loader({
                        show: true,
                        text: 'design_fail'
                    });
    
                    err.constructor.name.includes("Error") != true?err=new Error(err):null;
                    err.name = "Captured error in Seller portal initial load (sku=" + sku_id + "): " + err.name;
                    console.error(err);
                    window.sentry_capture_exception("",err);
                }
            },100)
        }

        const handleResize = () => {
            const canvasContainerDiv = document.getElementById('canvas_container_div');
            if (canvasContainerDiv) {
                setCanvasWidth(canvasContainerDiv.offsetWidth);
                setCanvasHeight(canvasContainerDiv.offsetHeight);
                setcanvasOffsetX(canvasContainerDiv.offsetLeft);
                setcanvasOffsetY(canvasContainerDiv.offsetTop);
            }
        };

        // Call the handleResize function once on initial render
        handleResize();

        // Add the event listener for window resize
        window.addEventListener('resize', handleResize);


        return () => {
            console.log("unmounting design");
            // Already handled in jsx by conditiional rendering. no need for manuplating display
            // try{
            //     var intercom_div = document.getElementById('my_custom_intercom_link');
            //     if(intercom_div){
            //         intercom_div.style.display = "none"
            //     }
            // }catch(err){
            //     console.error(err);
            // }
            clear_design();
            window.removeEventListener('resize', handleResize);
        }
    },[])


    useEffect(() => {
        const handleResize = () => {
            const canvasContainerDiv = document.getElementById('canvas_container_div');
            if (canvasContainerDiv) {
                setCanvasWidth(canvasContainerDiv.offsetWidth);
                setCanvasHeight(canvasContainerDiv.offsetHeight);
                setcanvasOffsetX(canvasContainerDiv.offsetLeft);
                setcanvasOffsetY(canvasContainerDiv.offsetTop);
            }
        };

        // Call the handleResize function once on initial render
        handleResize();

        // Add the event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [is_comment_mode]);


    useEffect(() => {
        if(remote_view){
            window.Module.set_remote_view();
        }
    },[remote_view])

    useEffect(() => {
        if(design_version_only){
            window.Module.set_design_version_only();
        }
    },[design_version_only])

    useEffect(() => {
        if(baking_view){
            window.Module.set_baking_view();
        }
    },[baking_view])

    useEffect(() => {
        if(seller_specific){
            console.log("setting seller specific");
            document.title = 'Component Editor'
        }

        if(mes_editor){
            console.log("setting mes editor");
            document.title = 'MES Editor'            
        }
    },[seller_specific])

    useEffect(() => {
        set_active_tab(0)
    }, [interface_mode]);

    useEffect(() => {
        if(!seller_specific){
            document.title = design_name + (store_details && store_details.store_name ? " - " + store_details.store_name : "");
        }
    }, [design_name]);

    useEffect(() => {
        if(is_free_view_active){
            set_view_options(JSON.parse(window.Module.get_view_specific_options()));
            // update_view()
        }
    }, [is_free_view_active, perspective, dropdownOpen]);

    const get_preview_as_designer_button_name = () => {
        var active_user = window.Module.get_active_user();
        if(active_user.admin_account){
            return "Preview as Designer";
        }else{
            return "Return to User View";
        }
    }

    const toggle_preview_as_designer = () => {
        window.Module.toggle_preview_as_designer();
        update_view();
    }
    useEffect(() => {
        // const match = command_line_options && command_line_options[0] && command_line_options[0].content ? command_line_options[0].content.match(/\((.*?)\)/) : ''
        // console.log('match', match)
        // const shortcut = match ? match[1].toLowerCase() : ''
        // const shorcuts = shortcut.split('/')
        // if(shorcuts.includes(command_line_content.toLowerCase())){
            // if(command_line_valid){
            //     setFocusedOptionIndex(0)
            // }else{
            //     setFocusedOptionIndex(-1)
            // }
            setFocusedOptionIndex(0)
            // }
    }, [command_line_valid, command_line_content]);

    useEffect(() => {
        if(show_command_line){
            if(document.getElementById('command_line_text')) document.getElementById('command_line_text').focus()
        }
    }, [show_command_line]);

    useEffect(() => {
        if (show_command_line && focusedOptionIndex !== -1) {
          const dropdownContainer = document.getElementsByClassName('command_line_options')[0]
          const focusedOption = document.getElementById('command_line_option'+focusedOptionIndex)
    
          if (focusedOption && dropdownContainer && focusedOption) {
            const containerRect = dropdownContainer.getBoundingClientRect();
            const optionRect = focusedOption.getBoundingClientRect();
    
            if (
              optionRect.top < containerRect.top ||
              optionRect.bottom > containerRect.bottom
            ) {
              focusedOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
          }
        }
      }, [focusedOptionIndex]);

      useEffect(() => {
        if(camera_positioning_mode && perspective == 'floorplan'){
            let active_design = window.Module.get_active_design()
            if(active_design){
                // update_camera_position_value()
                update_camera_and_render_preview()
            }
            active_design.delete()
        }
      }, [perspective]);

      const onDragEnd = async(result) => {
        set_show_arrows(isArrowVisible())
        // console.log("KPP dnd over", result);
        if(!result.destination) return;
        if(result.destination.index == result.source.index) return;
        const src = result.source;
        const dest = result.destination;

        let form = {}
        form['src_idx'] = design_views.findIndex(item => item.id == design_views_open[src.index].id);
        form['src_id'] = design_views_open[src.index].id;
        form['dest_idx'] = design_views.findIndex(item => item.id == design_views_open[dest.index].id);
        form['dest_id'] = design_views_open[dest.index].id;
        // console.log("KPP form", form, design_views, design_views_open)
        let resp = ''
        try{
            resp = await window.Promisify(window.Module.move_design_view(JSON.stringify(form)))
        }
        catch(err){
            console.log(err)
        }
        // TODO - handle with handle ui response
        // handle_ui_response(resp)
        update_view();
       };

       const isArrowVisible = () => {
        // set_show_arrows(document.getElementById('all_views_div_inner') && (document.getElementById('all_views_div_inner').scrollWidth > document.getElementById('all_views_div_inner').clientWidth))
        return document.getElementById('all_views_div_inner') && (document.getElementById('all_views_div_inner').scrollWidth > document.getElementById('all_views_div_inner').clientWidth)
       }

    // useEffect(() => {
    //     if(search_string_panel){
    //         set_panel_key(Date.now() + Math.random());
    //     }
    // }, [search_string_panel]);

    // useEffect(() => {
    //     console.log("icons", new_tool_bar_menu[active_tab]['section']['buttons'], new_tool_bar_menu)
    // });

    // useEffect(() => {
    //     console.log("left menu", toolbar_json_file)
    // }, [toolbar_json_file]);

    useEffect(() => {
        requestAnimationFrame(updateButtonVisibility)
    }, [design_views, design_views_open, is_free_view_active]);

	useEffect(() => {
		if(mes_component && mes_component.panels){
            if(core_selection_ids && core_selection_ids.length > 0){
                for(var i=0;i<mes_component.panels.length;i++){
                    var panel = mes_component.panels[i];
                    if(panel.id == core_selection_ids[0] && !(active_panel && active_panel.id == panel.id)){
                        set_active_panel(panel)
                        var div_id = "modular_elements_panel_listing_div_" + panel.id;
                        var el = document.getElementById(div_id);
                        if(el){
                            el.scrollIntoView({block:'nearest'});
                        }
                    }
                }
            }else{
                set_active_panel(null);
            }
			
		}
	},[core_selection_ids])

    const view_manager = () => {
        return (<Menu>
          {workbench_menu.map(item => {
            if (item.type === 'group') {
              return (
                <Menu.ItemGroup title={<div style={{ fontWeight: 'bold' }}>{item.label}</div>} key={item.key}>
                  {item.children.map(child => (
                    <Menu.Item key={child.key}>{child.label}</Menu.Item>
                  ))}
                </Menu.ItemGroup>
              );
            } else {
              return (
                <Menu.Item key={item.key} disabled={item.disabled}>{item.label}</Menu.Item>
              );
            }
          })}
        </Menu>);
    }

    const view_specific_layers_onclick = (view_option) => {
        set_current_view_layers(view_option.submenu);
    }

    // <LayersetPanelPopup json_item={json_item} update_view={update_view} set_layerset_mode={set_layerset_mode} set_given_layerset={set_given_layerset} set_modal_mode={set_modal_mode} common_onchange_handler={common_onchange_handler} handle_ui_response={handle_ui_response}/>

    return (
        <div>
            {/* <button style={{display:'none'}} id="update_view_trigger" onClick={update_view}></button>
            <button style={{display:'none'}} id="handle_ui_response_trigger" onClick={handle_ui_response}></button> */}
            <button style={{display:'none'}} id="update_sku_thumbnails_trigger" onClick={update_sku_thumbnails}></button>
            <Modals 
                org_key={org_key} 
                set_page_loader={set_page_loader} 
                submit_for_delete_confirm={submit_for_delete_confirm} 
                handle_ui_response={handle_ui_response} 
                update_view={update_view} 
                update_modal_json={update_modal_json} 
                modal_type={modal_properties.type} 
                handle_close={close_modal} 
                modal_json={modal_properties.json} 
                presentation_sheets={presentation_sheets} 
                boq_schedules={boq_schedules} 
                board_layout_sheet_types={board_layout_sheet_types} 
                board_layout_sheets={board_layout_sheets} 
                metric_system={metric_system} 
                approximation_ft={approximation_ft}
                approximation_mm = {approximation_mm}
                approxMM = {approxMM}
                mmToFeetParts = {(mm) => mmToFeetParts(mm, approximation_ft)}
                feetTomm = {feetTomm}
                interface_mode={interface_mode}
                modal_mode = {modal_mode}
                layerset_mode = {layerset_mode}
                given_layerset = {given_layerset}
                perspective = {perspective}
            />
            <ToolTipCanvas display={tooltip_for_cursor} info={tooltip_for_cursor_content} x={tooltip_for_cursor_x} y={tooltip_for_cursor_y} color={tooltip_for_cursor_color}></ToolTipCanvas>
            <InfurniaPlansModal open={show_current_subscription} toggle={() => set_show_current_subscription(false)}/>
            <Account_Settings_Modal open={show_account_settings} handle_close={() => set_show_account_settings(false)} store_details={store_details} set_page_loader={set_page_loader}></Account_Settings_Modal>
            <Design_Revert_Modal open={show_design_revert_modal} handle_close={() => {}} />
            <DesignNameModal design_name={design_name} handle_ui_response={handle_ui_response} show_rename_design_modal={show_rename_design_modal} set_show_rename_design_modal={set_show_rename_design_modal} set_page_loader={set_page_loader} project_client_name={client_name} project_id={project_id}> </DesignNameModal>
            <div style={styles.main_div}>
            <div style={{display:'flex',flexFlow:'row',borderBottom: 'solid 1.6px ' + COLORS.gray3, height:'52px',alignItems:'center', backgroundColor: 'white', position:'relative', zIndex:3}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', maxWidth: '20%',paddingLeft:'16px', paddingRight: '5px'}}>
                        <img style={styles.infurnia_logo} src={store_details&&store_details.logo_path?store_details.logo_path :"/resources/images/infurnia_black.svg"} />
                        {/* <div style={{paddingTop: '15px', width: '1px', height: '15px', backgroundColor: 'black', transform: 'skewX(-15deg)'}}></div> */}
                        <div style={{backgroundColor: '#C5C7CF', paddingBottom: '2px', minWidth: '1px', height: '24px'}}></div>
                        <img id='header_project_info' style={{marginTop: '2px', maxWidth: '25px', maxHeight: '20px', objectFit: 'contain'}} src={"/resources/images/file.svg"} />
                        <UncontrolledTooltip autohide={false} placement='bottom' target={'header_project_info'}>
                            <div style={{marginBottom:'16px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray2}}>
                                <div>Project Name: <b>{project_name ? project_name : '-'}</b></div>
                            </div>
                            <div style={{marginBottom:'16px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray2}}>
                                <div>Project ID: <b>{project_id ? project_id : '-'}</b></div>
                            </div>
                            <div style={{marginBottom:'4px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray2}}>
                                <div>Client Name: <b>{client_name ? client_name : '-'}</b></div>
                            </div>
				        </UncontrolledTooltip>
                        <div style={{backgroundColor: '#C5C7CF', paddingBottom: '2px', minWidth: '1px', height: '24px'}}></div>
                        {mes_editor?(
                                <div id='work_order_name_header' className='gray_hover' style={{padding: '0 4px', marginTop: '2px', fontSize: '13px', color: 'black', border: 'none', borderRadius: '5px', maxWidth: '60%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{work_order_details ? work_order_details.name : ''}</div>
                        ):(
                            <React.Fragment>
                                <div id='design_name_header' className='gray_hover' onClick={() => {console.log("The div is clicked!"); set_show_rename_design_modal(true)}} style={{padding: '0 4px', marginTop: '2px', fontSize: '13px', color: 'black', border: 'none', borderRadius: '5px', maxWidth: '60%', cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{design_name}</div>
                                <UncontrolledTooltip autohide={false} placement='bottom' target={'design_name_header'}>
                                    <div style={{marginBottom:'4px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray2}}>
                                        <div>Design Name: <b>{design_name ? design_name : '-'}</b></div>
                                    </div> 
                                </UncontrolledTooltip>
                            </React.Fragment>
                        )}
                    </div>
                <Navbar style={styles.nav_bar}>
                    <Nav  style={{height:'30px', backgroundColor: '#F5F5F5', borderTopRightRadius:'15px', borderTopLeftRadius:'6px', borderBottomLeftRadius:'6px', borderBottomRightRadius:'15px'}}>
                        {/* {!seller_specific && (<NavItem style={{padding:'8px', ...styles.nav_item}}>
                            <a style={{fontSize:'12px',padding:'0px',paddingLeft:'10px',display:'flex'}}>
                                <span style={{cursor:'pointer'}}>{project_name}</span>
                                <span><i className="fa fa-chevron-right" style={{color: COLORS.black,margin:'auto auto auto 5px'}}></i></span>
                            </a>
                        </NavItem>)} */}
                        {!seller_specific&&!mes_editor ? 
                            <NavBar_Dropdown_New
                                id="mode_dropdown"  
                                before_open={update_level_arrays}
                                on_select={switch_mode}
                                options={level_arrays.mode}
                                display_name={level_arrays.active_mode?level_arrays.active_mode.name: 'Design'}
                                className="blue_hover black_inf_text design_border_radius" 
                            />
                            :
                            <NavBar_Dropdown_New
                                id="mode_dropdown"  
                                // before_open={update_level_arrays}
                                // on_select={switch_mode}
                                // options={level_arrays.mode}
                                display_name={mes_editor?'MES Editor':'Component Editor'}
                                className="blue_hover black_inf_text" 
                            />
                        }
                        {mes_editor?(<React.Fragment>
                            {/* <div style={{display:'flex', alignItems:'center'}}><img src='/resources/icons/level_separator.svg'></img></div> */}
                            <div style={{display:'flex', alignItems:'center'}}><i className="fa fa-circle" style={{color: COLORS.gray4,margin:'auto 5px', fontSize:'6px'}}></i></div>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.category_name : ''}</span>
                                </a>
                            </NavItem>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.sub_category_name : ''}</span>
                                </a>
                            </NavItem>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.group_name : ''}</span>
                                </a>
                            </NavItem>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.sku_name : ''}</span>
                                </a>
                            </NavItem>
                        </React.Fragment>):''}
                        {seller_specific?(<React.Fragment>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.category_name : ''}</span>
                                </a>
                            </NavItem>
                            {/* <div style={{display:'flex', alignItems:'center'}}><img src='/resources/icons/level_separator.svg'></img></div> */}
                            <div style={{display:'flex', alignItems:'center'}}><i className="fa fa-circle" style={{color: COLORS.gray4,margin:'auto 5px', fontSize:'6px'}}></i></div>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.sub_category_name : ''}</span>
                                </a>
                            </NavItem>
                            {/* <div style={{display:'flex', alignItems:'center'}}><img src='/resources/icons/level_separator.svg'></img></div> */}
                            <div style={{display:'flex', alignItems:'center'}}><i className="fa fa-circle" style={{color: COLORS.gray4,margin:'auto 5px', fontSize:'6px'}}></i></div>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.group_name : ''}</span>
                                </a>
                            </NavItem>
                            {/* <div style={{display:'flex', alignItems:'center'}}><img src='/resources/icons/level_separator.svg'></img></div> */}
                            <div style={{display:'flex', alignItems:'center'}}><i className="fa fa-circle" style={{color: COLORS.gray4,margin:'auto 5px', fontSize:'6px'}}></i></div>
                            <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                <a className="black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                    <span style={{cursor:'pointer'}}>{seller_sku_details ? seller_sku_details.sku_name : ''}</span>
                                </a>
                            </NavItem>
                        </React.Fragment>):''}
                        {interface_mode=="design_edit_mode" && !seller_specific && !mes_editor && (
                            <React.Fragment>
                                <NavItem style={{padding:'8px 12px 8px 12px', ...styles.nav_item, height:'30px'}}>
                                    <a className="blue_hover black_inf_text" onClick={switch_world} style={{fontSize:'12px',padding:'0px',display:'flex',gap:'8px', cursor: 'pointer'}}>
                                        {/* <img onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3} style={{maxWidth: '30px', minHeight: '12px', maxHeight: '20px', objectFit: 'contain', paddingLeft: '4px', paddingRight: '5px'}} src={hovered3?"/resources/icons/world_navbar_hover.svg":"/resources/icons/world_navbar.svg"} /> */}
                                        <Icons name='world' style={{maxWidth: '30px', maxHeight: '20px', objectFit: 'contain', margin: 'auto 0', fontSize: '12px'}}/>
                                    </a>
                                </NavItem>
                                {/* <div style={{display:'flex', alignItems:'center'}}><img src='/resources/icons/level_separator.svg'></img></div> */}
                                {/* <div style={{display:'flex', alignItems:'center'}}><i className="fa fa-circle" style={{color: COLORS.gray4,margin:'auto 5px', fontSize:'6px'}}></i></div> */}
                            </React.Fragment>
                        )}
                        {interface_mode=="presentation" && !seller_specific &&  (
                            <React.Fragment>
                                {/* <NavItem style={{padding:'8px', ...styles.nav_item}}>
                                    <a className="blue_hover" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                        <span style={{cursor:'pointer', color: COLORS.black}}>Presentation</span>
                                        <span><i className="fa fa-chevron-right" style={{color: COLORS.black,margin:'auto auto auto 5px'}}></i></span>
                                    </a>
                                </NavItem> */}
                                <NavBar_Dropdown_Left 
                                    id="sheets_dropdown"  
                                    before_open={update_level_arrays}
                                    on_select={switch_presentation_sheet}
                                    options={presentation_sheets.sheets}
                                    display_name={presentation_sheets.active_sheet?presentation_sheets.active_sheet.name:'Select Page'}
                                    className="blue_hover black_inf_text" 
                                />
                            </React.Fragment>
                        )}
                        {interface_mode=="boq_mode" && !seller_specific &&  (
                            <React.Fragment>
                                <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px'}}>
                                    <a className="blue_hover black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                        <span style={{cursor:'pointer'}}>Schedule</span>
                                    </a>
                                </NavItem>
                                {/* <div style={{display:'flex', alignItems:'center'}}><img src='/resources/icons/level_separator.svg'></img></div> */}
                                <div style={{display:'flex', alignItems:'center'}}><i className="fa fa-circle" style={{color: COLORS.gray4,margin:'auto 5px', fontSize:'6px'}}></i></div>
                                <NavBar_Dropdown_Left 
                                    id="schedules_dropdown"  
                                    on_select={switch_boq_schedule}
                                    options={boq_schedules.schedules}
                                    display_name={boq_schedules.active_schedule?boq_schedules.active_schedule.name:'Select Schedule'}
                                    className="blue_hover black_inf_text" 
                                />
                            </React.Fragment>
                        )}
                        {interface_mode=="board_layout_mode" && !seller_specific &&  (
                            <React.Fragment>
                                <NavItem style={{padding:'8px', ...styles.nav_item, height:'30px', background: 'linear-gradient(to right, #F3F4F6, #EBEDF1)'}}>
                                    <a className="blue_hover black_inf_text" style={{fontSize:'12px',padding:'0px',display:'flex'}}>
                                        <span style={{cursor:'pointer'}}>Board Layout</span>
                                    </a>
                                </NavItem>
                                {/* <div style={{display:'flex', alignItems:'center'}}><img src='/resources/icons/level_separator.svg'></img></div> */}
                                <div style={{display:'flex', alignItems:'center'}}><i className="fa fa-circle" style={{color: COLORS.gray4,margin:'auto 5px', fontSize:'6px'}}></i></div>
                                <NavBar_Dropdown_Left
                                    id="board_layout_sheet_type_dropdown"  
                                    on_select={switch_board_layout_sheet_type}
                                    options={board_layout_sheet_types.sheet_types}
                                    display_name={board_layout_sheet_types.active_sheet_type?board_layout_sheet_types.active_sheet_type.name:'Select Sheet Type'}
                                    className="blue_hover black_inf_text" 
                                />
                                <NavBar_Dropdown_Left
                                    id="board_layout_sheet_dropdown"  
                                    on_select={switch_board_layout_sheet}
                                    options={board_layout_sheets.sheets}
                                    display_name={board_layout_sheets.active_sheet?board_layout_sheets.active_sheet.name:'Select Sheet'}
                                    className="blue_hover black_inf_text" 
                                />
                            </React.Fragment>
                        )}
                        {interface_mode=="design_edit_mode" && !seller_specific && !mes_editor && (
                                <NavBar_Dropdown_BF
                                    id="buildings_dropdown"  
                                    icon="building"
                                    before_open={update_level_arrays}
                                    on_select={switch_building}
                                    options={level_arrays.buildings}
                                    display_name={level_arrays.active_building?level_arrays.active_building.name:'Select Building'}
                                    className="blue_hover black_inf_text"
                                    toolbar_onclick={toolbar_on_click}
                                    type='building'
                                    add_on_click = {level_arrays.add_building_route}
                                />
                        )}
                        {interface_mode=="design_edit_mode" && !seller_specific && !mes_editor && (
                            <React.Fragment>
                                {(viewing_level == "building" || viewing_level == "floor" || viewing_level == "room")&&(
                                    <NavBar_Dropdown_BF
                                        id="floors_dropdown" 
                                        icon="floor"
                                        before_open={update_level_arrays}
                                        on_select={switch_floor}
                                        options={level_arrays.floors}
                                        display_name={level_arrays.active_floor?level_arrays.active_floor.name:'Select Floor'}
                                        className="blue_hover black_inf_text" 
                                        toolbar_onclick={toolbar_on_click}
                                        type='floor'
                                        add_on_click = {level_arrays.add_floor_route}
                                    />
                                )}
                                {(viewing_level == "floor" || viewing_level == "room")&&(
                                    <NavBar_Dropdown_BF
                                        id="rooms_dropdown" 
                                        icon='room'
                                        before_open={update_level_arrays}
                                        on_select={switch_room}
                                        options={level_arrays.rooms}
                                        display_name={level_arrays.active_room?level_arrays.active_room.name:'Select Room'}
                                        className="blue_hover black_inf_text"
                                        toolbar_onclick={toolbar_on_click}
                                        type='room'
                                    />
                                )}
                            </React.Fragment>
                        )}
                        
                    </Nav>
                    <Nav className="justify-content-end" style={{alignItems:'center'}}>
                        {/* Perspective Switcher */}
                        {
                            interface_mode == "design_edit_mode" ?
                                <div className='perspective_switcher_container DMSans' style={{ marginRight: '16px'}}>
                                    {/*<a onClick={navbar_component_click} className="blue_hover" style={{cursor:'pointer', lineHeight:'20px', marginBottom:'0px', padding:'8px', fontSize:'12px', borderRight:'1px solid white'}} title="Component" >Component</a>*/}
                                    {/* <a onClick={navbar_floorplan_click} className="" style={{ cursor: 'pointer', lineHeight: 'unset', marginBottom: '0px', padding: '5px 10px 5px 10px', fontSize: '12px', marginRight: '2px', borderRadius: '8px', backgroundColor: perspective == 'floorplan' ? COLORS.blue6 : 'transparent', color: perspective == 'floorplan' ? 'white' : 'black', textDecoration: 'none' }} title="Floor Plan" >Floorplan</a> */}
                                    {/* <a onClick={navbar_3d_click} className="" style={{ cursor: 'pointer', lineHeight: 'unset', marginBottom: '0px', padding: '5px 10px 5px 10px', fontSize: '12px', marginRight: '2px', borderRadius: '8px', backgroundColor: perspective == '3d' ? COLORS.blue6 : 'transparent', color: perspective == '3d' ? 'white' : 'black', textDecoration: 'none' }} title="3D View" >3D</a> */}
                                    <div onClick={navbar_floorplan_click} className={`perspective_item ${perspective == 'floorplan' ? 'perspective_item_active' : '' }`} title="Floor Plan" >Floorplan</div>
                                    <div onClick={navbar_3d_click} className={`perspective_item ${perspective == '3d' ? 'perspective_item_active' : '' }`} title="3D View" >3D</div>
                                    {
                                        <NavBar_Dropdown
                                            id="elevation_cameras_dropdown"
                                            before_open={() => {update_elevation_cameras(); update_custom_plan_cameras()}}
                                            on_select={navbar_elevation_click}
                                            elevation_options={elevation_cameras}
                                            custom_plan_options={custom_plans_cameras}
                                            display_name={'Elevation'}
                                            className={''}
                                            submit_for_delete_confirm={submit_for_delete_confirm}
                                            update_view={update_view}
                                            active={perspective === 'elevation'}
                                            handle_ui_response={handle_ui_response}
                                        />
                                    }
                                </div>
                                : ''
                        }

	                    {/*<a className="blue_hover" style={{cursor:'pointer', lineHeight:'20px', marginBottom:'0px', padding:'8px', fontSize:'13px', marginRight:'10px'}} title="Elevation" >Elevation</a>*/}
                        {/*<a className="blue_hover" style={{cursor:'pointer'}}><i style={styles.nav_bar_icons} data-toggle="tooltip" data-placement="bottom" title="Folder" className="fa fa-nav fa-folder fa-2x"></i></a>*/}
                        <a style={{borderLeft: '2px solid ' + COLORS.gray3, paddingLeft:'8px',height:'34px'}}></a>
                        {!remote_view && !seller_specific?(<Dropdown isOpen={is_profile_dropdown_open} toggle={() => set_is_profile_dropdown_open(!is_profile_dropdown_open)}>
                            {/* <DropdownToggle> */}
                                <DropdownToggle style={{borderRadius:'50%', height:'32px', width:'32px', margin:'2px 0px', color:'rgba(0, 120, 255, 1)', backgroundColor:'rgba(0, 120, 255, 0.1)', border:'1px solid white', justifyContent:'center', display:'flex', alignItems:'center',marginLeft:'8px',marginRight:'8px', textTransform:'uppercase', fontWeight: 500}}>{store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim()[0] : ':)'}</DropdownToggle>
                            {/* </DropdownToggle> */}
                            <DropdownMenu style={{marginTop:'11px',zIndex:10001}}>
                                <DropdownItem onClick={() => set_show_account_settings(true)}>
                                    Account Settings
                                </DropdownItem>
                                {is_user_admin?(<DropdownItem onClick={toggle_preview_as_designer}>
                                    {get_preview_as_designer_button_name()}
                                </DropdownItem>):''}
                                <DropdownItem onClick={() => set_show_current_subscription(true)}>
                                    Current Subscription
                                </DropdownItem>
                                {/* <DropdownItem>
                                    Switch Organization
                                </DropdownItem> */}
                                <DropdownItem onClick={logout}>
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>):''}
                        {window.Module.API.segment_id && (!(window.location.pathname.split('/')[1]) || (window.location.pathname.split('/')[1] != "livspace" && window.location.pathname.split('/')[1] != "zest"))?(
                        <Dropdown isOpen={is_help_section_dropdown_open} toggle={() => set_is_help_section_dropdown_open(!is_help_section_dropdown_open)}>
                            <DropdownToggle tag={"div"} className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons}}>
                                <img data-toggle="dropdown" data-placement="bottom" title="Need Help" src={'/resources/nav_icons_new/need_help.svg'}></img>
                            </DropdownToggle>
                            <DropdownMenu style={{marginTop:'11px',zIndex:10001}}>
                                <DropdownItem onClick={() => {document.getElementById("my_custom_intercom_link").click()}}>
                                    Chat with us
                                </DropdownItem>
                                {/* <DropdownItem onClick={() => {window.coview('screenshot');}}>
                                    Share screenshot
                                </DropdownItem>
                                <DropdownItem onClick={() => {window.coview('screencast');}}>
                                    Share screen recording
                                </DropdownItem> */}
                                {/* <DropdownItem onClick={() => {window.coview('screensharing');}}>
                                    Share live screen
                                </DropdownItem> */}
                            </DropdownMenu>
                        </Dropdown>):''}
                        {window.Module.API.segment_id && (!(window.location.pathname.split('/')[1]) || (window.location.pathname.split('/')[1] != "livspace" && window.location.pathname.split('/')[1] != "zest")) && (<a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons, display:"none"}} id="my_custom_intercom_link"><img data-toggle="tooltip" data-placement="bottom" title="Need Help" src={'/resources/nav_icons_new/need_help.svg'}></img></a>)}
                        <a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons}} onClick={toggle_fullscreen}><img data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" src={'/resources/nav_icons_new/fullscreen.svg'}></img></a>
                        {!remote_view && !seller_specific && !mes_editor && interface_mode=="design_edit_mode" && (<a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons}} onClick={close_design}><img data-toggle="tooltip" data-placement="bottom" title="Back" src={'/resources/nav_icons_new/close.svg'}></img></a>)}
                        {!remote_view && interface_mode!="design_edit_mode" && (<a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons}} onClick={go_back_onclick}><img data-toggle="tooltip" data-placement="bottom" title="Back To Designing" src={'/resources/nav_icons_new/back.svg'}></img></a>)}
                    </Nav>
                </Navbar>
                </div>
                {!remote_view && !is_comment_mode && (
                    <div style={{display:'flex',flexFlow:'row',borderBottom: '0px' + COLORS.gray3, height:'24px',alignItems:'center', padding: '0px 64px', position:'relative', zIndex:2, backgroundColor: 'white'}}>
                        {
                            toolbar_json_file && toolbar_json_file.length ? toolbar_json_file.map((item, idx) => (
                                <Tooltip title={item.disabled == 'true' ? item.disabled_message : ''} placement="bottom" mouseEnterDelay={0.5}>
                                    <div
                                        key={idx}
                                        className='infurnia_ribbon_tab'
                                        // title={item.disabled == 'true' ? item.disabled_message : ''} 
                                        style={{position:'relative', fontSize: '12px', margin:'0px 4px', backgroundColor: item.active == 'true' ? COLORS.toolbar_background_color : '', paddingLeft:'8px', paddingRight:'8px', height:'100%', display:'flex', alignItems:'space-between', cursor:item.disabled == 'true'?'not-allowed':'pointer', opacity:item.disabled == 'true' ? 0.3:1}} 
                                        onClick={item.disabled == 'false' ? () => {set_active_tab(idx);toolbar_on_click(item.on_click_route + item.id)} : null}> 
                                        
                                        <div style={{visibility: item.active == 'true' ? 'visible' : ''}} className='triangle-topleft'></div> 
                                        
                                        <div className='black_inf_text' style={{textAlign:'center', display:'flex', alignItems:'center', fontSize:'12px'}}> 
                                            {t(item.name)} 
                                        </div> 
                                        
                                        <div style={{visibility: item.active == 'true' ? 'visible' : ''}} className='triangle-topright'></div> 
                                        
                                    </div>
                                </Tooltip>
                                )) : ''
                        }
                        {
                            <div style={{ marginLeft: 'auto', marginRight: '-48px', fontSize: '14px' }} className='DMSans'>
                                <span style={{ color: COLORS.gray5, marginRight: '12px' }}>Project Units:</span>
                                <Tooltip title={'To change, go to Project Units under View tab'} mouseEnterDelay={0.5}>
                                    <span style={{ color: COLORS.gray6 }}>{get_units_preview(metric_system, metric_system === 'mm' ? approximation_mm : approximation_ft)}</span>
                                </Tooltip>
                            </div>
                        }
                    </div>
                )}


                <div id="canvas_container_div" style={{backgroundColor:"rgba(0, 0, 0, 0.2)",position:'absolute',top: (remote_view || is_comment_mode)?'52px':'136px',left:((remote_view && !is_comment_mode) || panel_collapsed)?0:'270px',width: ((remote_view && !is_comment_mode) || panel_collapsed)?'100%':'calc(100% - 270px)',height: (remote_view || is_comment_mode)?'calc(100% - 52px)':'calc(100% - 136px)'}}>
                    {/* <div id="canvas_container_3d" style={{width: '100%',height: '100%',display: (interface_mode!="presentation" && perspective=='3d'?'block':'none')}}>
                        <canvas id="webgl_main_canvas" style={{width:"100%",height:"100%"}}/>
                        <div id="canvasSphere" style={{height:'100px',width:'100px',lineHeight: 0,position: 'absolute',right: '40px',bottom: '40px',zIndex: '1000'}}>
                            <canvas id="webgl_navigation_sphere_canvas" />
                        </div>
                    </div>
                    <div id="canvas_container_2d" style={{width: '100%',height: '100%',display: (interface_mode!="presentation" && (perspective=='component'||perspective=='floorplan'||perspective=='elevation')?'block':'none')}}>
                        <canvas id="canvas_2d" />
                    </div>
                    <div id="canvas_container_presentation" style={{backgroundColor:"rgba(0, 0, 0, 0.2)",width: '100%',height: '100%',display: (interface_mode=='presentation'?'block':'none'),overflow:'scroll'}}
                        onMouseDown={handleMouseDownPresentation}
                        onMouseMove={handleMouseMovePresentation}
                        onMouseUp={handleMouseUpPresentation}
                        // onMouseLeave={handleMouseLeavePresentation}
                        onContextMenu={handleContextMenuPresentation}
                    >
                        <canvas style={{marginTop:"20px", border: "2px solid black",backgroundColor:"white"}} width={presentation_width} height={presentation_height} id="canvas_presentation" />
                    </div> */}
                    <div id="canvas_container_inner" style={{width: '100%', height: '100%', overflow: interface_mode=='presentation' ? 'auto' : ''}}
                        onMouseDown={interface_mode=='presentation' ? handleMouseDownPresentation : null}
                        onMouseMove={interface_mode=='presentation' ? handleMouseMovePresentation : null}
                        onMouseUp={interface_mode=='presentation' ? handleMouseUpPresentation : null}
                        // onMouseLeave={interface_mode=='presentation' ? handleMouseLeavePresentation : null}
                        onContextMenu={interface_mode=='presentation' ? handleContextMenuPresentation : null}
                    >
                        <canvas style={interface_mode=='presentation'?{marginTop:"20px", border: "2px solid black",backgroundColor:"white"}:{}} id="webgl_main_canvas" />
                    </div>

                    <div id="canvasSphere" style={{display: (interface_mode!="presentation" && perspective=='3d'?'block':'none'), height:'100px',width:'100px',lineHeight: 0,position: 'absolute',right: '40px',bottom: '40px',zIndex: '1000'}}>
                        <canvas id="webgl_navigation_sphere_canvas" />
                    </div>
                </div>

                {apply_discard ?
                    <div style={{position:'absolute', zIndex:5, top:'190px', left:'280px', padding:'8px', backgroundColor:'white', borderRadius:'6px', boxShadow:'1px 1px 16px 2px rgba(0, 0, 0, 0.05)'}}>
                        <div style={{padding: '6px 12px', backgroundColor:COLORS.gray2, borderRadius:'4px', fontSize:'12px'}}>{apply_discard_mode}</div>
                        {
                            left_menu_json_file.map((json_item, idx) => (
                                <div style={{cursor:'pointer',opacity:'1'}} key={json_item.id + String(idx)}>
                                    <Left_menu_Image_Icon
                                        onClick={() => {!json_item.disabled?left_menu_on_click(json_item.id):''}}
                                        unit_id={json_item.id?json_item.id:''}
                                        unit_name={json_item.name?json_item.name:''}
                                        unit_content={json_item.icon_src?json_item.icon_src:''}
                                        unit_root={json_item.route?json_item.route:''}
                                        unit_show={true}
                                        unit_disabled={false}
                                        unit_active={false}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    : ''

                }

                {show_status_bar ?
                    <div className='DMSans flex_property statusbar_container' style={{position:'absolute', zIndex:5, top: (remote_view || is_comment_mode)?'60px':'144px',left:((remote_view && !is_comment_mode) || panel_collapsed)?'50%':'calc( ( 100% + 270px ) / 2 )', transform:'translateX(-50%)', backgroundColor: status_bar_type == 'error' ? '#F9DADE' : status_bar_type == 'success' ? '#CCF4E3' : 'white', color: status_bar_type == 'error' ? '#D00D00' : status_bar_type == 'success' ? '#19894C' : '#323338'}}>
                        <div id='statusbar_content' className={status_bar_buttons && status_bar_buttons.length && status_bar_content ?'mr-4':''} style={{fontWeight:500}}>{status_bar_content}</div>
                        {
                            status_bar_tooltip ?
                            <UncontrolledTooltip autohide={false} placement='bottom-start' target={'statusbar_content'}>
                                <React.Fragment>
                                {
                                    status_bar_tooltip ? status_bar_tooltip.split('\n').map((x, i) => (
                                        <div>
                                            <span>{x}</span>
                                            <br/>
                                        </div>
                                    ))
                                    :''
                                }
                                </React.Fragment>
                            </UncontrolledTooltip>
                            :''
                        }
                        {
                            status_bar_buttons && status_bar_buttons.length ? status_bar_buttons.map((item, idx) => (
                                item.type == 'text' ? 
                                <div
                                    key={idx}
                                    onClick={() => toolbar_on_click(item.route)}
                                    className={idx != status_bar_buttons.length - 1 ? 'mr-3' : ''}
                                    style={{color: '#0078ff', cursor:'pointer'}}
                                >
                                    {item.content}
                                </div>
                                :
                                <img
                                    key={idx}
                                    src={item.content}
                                    alt={`Image ${idx}`}
                                    className={idx != status_bar_buttons.length - 1 ? 'mr-3' : ''}
                                    style={{color: '#0078ff', cursor:'pointer'}}
                                    onClick={() => toolbar_on_click(item.route)}
                                />
                            ))
                            :''
                        }
                    </div>
                    : ''

                }
                {
                    show_view_options() ? 
                        <>
                            <div style={{height: '100%', display:((interface_mode=="design_edit_mode" && !seller_specific && !mes_editor)?"flex":"none"), borderRight: '1px solid #8E8F9E', borderTop: '1px solid #8E8F9E',background: 'rgb(240, 242, 244, 0.5)', width: 'calc(100% - 204px)', right: '204px', height: '36px', position: 'absolute', bottom: '0px'}}></div>
                            <div className='dynamic_view_options' style={{position: 'absolute', display:((interface_mode=="design_edit_mode" && !seller_specific && !mes_editor)?"flex":"none"), background: 'white', right: '204px', bottom: '0px', height: '36px'}}>
                                <div className='inf-px-2 tool_guide' style={{gap: '8px', height: '84%', position: 'absolute', top: '-100%', right: '0%', backgroundColor: (['Suggestions will appear here when you select a tool.', 'No message'].includes(bottom_status_bar_message)) ? '#E6E9EE80' : '#E6E9EE', color: COLORS.gray6, fontSize: '13px', display: selected_tool_guide?'flex':'none', alignItems: 'center', borderRadius: '4px'}}>
                                    <img style={{ opacity: (['Suggestions will appear here when you select a tool.', 'No message'].includes(bottom_status_bar_message))?'0.5':'1'}} src='/resources/view_bar_icons/view_options/tool_guide.svg'/>
                                    <div style={{whiteSpace: 'nowrap', opacity: (['Suggestions will appear here when you select a tool.', 'No message'].includes(bottom_status_bar_message))?'0.5':'1', fontStyle: 'italic'}}>{bottom_status_bar_message}</div>
                                    <div>
                                        <Tooltip title={window.Module.is_feature_enabled('help_center_popup') ? 'Watch a Video' : ''} mouseEnterDelay={0.5} placement='top'>
                                            {window.Module.is_feature_enabled('help_center_popup') ? <Icons className="red_hover" name={"youtube"} onClick={toggle_help_menu} style={{ cursor: 'pointer', padding: '2px', borderRadius: '1px', fontSize: '20px' }} /> : ''}
                                        </Tooltip>
                                    </div>
                                </div>
                                {/* {all_view_options.map((view_item, idx) => (
                                <Tooltip title={view_options_tooltip_map[view_item]} mouseEnterDelay={0.5} placement='top'>
                                {(common_viewport_options.map(item => view_options_map[item.id]).includes(view_item) || view_item == 'tool_guide') ?
                                <div className= {((common_viewport_options.map(item => view_options_map[item.id]).includes(view_item) && !common_viewport_options.find(item => view_options_map[item.id] == view_item).disabled && common_viewport_options.find(item => view_options_map[item.id] == view_item).selected) || selected_tool_guide)?'active_view_option':'inactive_view_option'} onClick={(common_viewport_options.map(item => view_options_map[item.id]).includes(view_item) && !common_viewport_options.find(item => view_options_map[item.id] == view_item).disabled) ? (e) => {toolbar_on_click(common_viewport_options.find(item => view_options_map[item.id] == view_item).id)} : (view_item == 'tool_guide') ? (e) => {set_selected_tool_guide(!selected_tool_guide)} : null} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', aspectRatio: '1', borderTop: '1px solid #8E8F9E', borderRight: (view_item == 'tool_guide') ? '1px solid #8E8F9E' : '1px solid white', borderLeft: (view_item == 'tool_guide') ? '1px solid #8E8F9E' : ''}}>
                                <Icons name={view_item} style={{fontSize: '20px', color: ((common_viewport_options.map(item => view_options_map[item.id]).includes(view_item) && !common_viewport_options.find(item => view_options_map[item.id] == view_item).disabled && common_viewport_options.find(item => view_options_map[item.id] == view_item).selected) || selected_tool_guide)?"#214097":"#676878"}}/>
                                </div> :
                                <div className= {(view_options.map(item => view_options_map[item.id]).includes(view_item) && !view_options.find(item => view_options_map[item.id] == view_item).disabled && view_options.find(item => view_options_map[item.id] == view_item).selected)?'active_view_option':'inactive_view_option'} onClick={(view_options.map(item => view_options_map[item.id]).includes(view_item) && !view_options.find(item => view_options_map[item.id] == view_item).disabled) ? (e) => {toolbar_on_click(view_options.find(item => view_options_map[item.id] == view_item).id)} : null} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '1', borderTop: '1px solid #8E8F9E', borderRight: '1px solid white', cursor: (view_options.map(item => view_options_map[item.id]).includes(view_item) && !view_options.find(item => view_options_map[item.id] == view_item).disabled)?'pointer':'not-allowed'}}>
                                <Icons name={view_item} style={{fontSize: '20px', opacity: (view_options.map(item => view_options_map[item.id]).includes(view_item) && !view_options.find(item => view_options_map[item.id] == view_item).disabled)?'':'0.2', color: (view_options.map(item => view_options_map[item.id]).includes(view_item) && !view_options.find(item => view_options_map[item.id] == view_item).disabled && view_options.find(item => view_options_map[item.id] == view_item).selected)?"#214097":"#676878"}}/>
                                </div>}
                                </Tooltip>
                                ))} */}
                                {
                                    view_options && view_options.length ? view_options.map((view_option) => (
                                        view_option.submenu ?
                                        <Tooltip title={is_view_layers_open ? '' : is_free_view_active ? 'View Specific Layers is not available for free view. Check Global Layers instead' : 'View Specific Layers'} mouseEnterDelay={0.5} placement='top'>
                                            <div className={is_view_layers_open ? 'active_view_option' : 'inactive_view_option'} onClick={() => {send_analytics({category: 'Quick Action Menu Toggled', button_id: view_option.id, button_action: view_option.active ? "OFF" : "ON", button_visibility: view_option.disabled ? "Disabled" : "Enabled"}); if(!view_option.disabled){ if(view_option.submenu) {view_specific_layers_onclick(view_option);} toolbar_on_click(view_option.id); }}} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '1', borderTop: '1px solid #8E8F9E', borderRight: '1px solid #8e8f9e', cursor: !(view_option.disabled) ? 'pointer' : 'not-allowed' }}>
                                                <AntDropdown
                                                    open={is_view_layers_open}
                                                    onOpenChange={(open) => {set_is_view_layers_open(open);}}
                                                    placement='topLeft'
                                                    disabled={view_option.disabled}
                                                    overlayClassName='view_layer_menu'
                                                    menu={{items:view_layers_menu, expandIcon: <img src='/resources/view_bar_icons/toggle_view_manager.svg'/>}} trigger={['click']}
                                                    // style={{height: '500px', background: 'red', overflow: 'auto'}}
                                                    >
                                                    <a onClick={(e) => e.preventDefault()} style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                                                    <Icons id={view_option.id} name={view_option.src} style={{ fontSize: '20px', opacity: !view_option.disabled ? '1' : '0.2', color: is_view_layers_open ? "#214097" : "#676878" }} />
                                                    </a>
                                                </AntDropdown>
                                                {/* <Dropdown direction='up' disabled={view_option.disabled} isOpen={is_view_layers_open} toggle={() => {set_is_view_layers_open((open) => !open)}} style={{height: '100%', width: '100%'}}>
                                                    <DropdownToggle style={{background:'unset', borderRadius: 'unset', outline: 'unset', boxShadow: 'unset', padding: 'unset', margin: 'unset', border: 'unset', height: '100%', width: '100%'}}>
                                                    <a onClick={(e) => e.preventDefault()} style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    <Icons name={view_option.src} style={{ fontSize: '20px', opacity: !view_option.disabled ? '1' : '0.2', color: is_view_layers_open ? "#214097" : "#676878" }} />
                                                    </a>
                                                    </DropdownToggle>
                                                    <DropdownMenu container={'body'} className='DMSans' style={{padding: '0px 0px 8px 0px', border: 'none', background: 'transparent'}}>
                                                    {
                                                        current_view_layers ?
                                                        <div style={{ background: COLORS.gray3, borderRadius: '8px' }}>
                                                        <div style={{ paddingTop: '4px', paddingLeft: '8px', color: 'black', fontFamily: 'DM Sans', fontWeight: 700 }}>View Specific Layers</div>
                                                        <LayersetPanelPopup json_item={current_view_layers} update_view={update_view} set_layerset_mode={set_layerset_mode} set_given_layerset={set_given_layerset} set_modal_mode={set_modal_mode} common_onchange_handler={update_view_options} handle_ui_response={handle_ui_response} options={{ show_overridden: true }} />
                                                        </div>
                                                        : ''
                                                        }
                                                        </DropdownMenu>
                                                        </Dropdown> */}
                                            </div>
                                        </Tooltip> :
                                        <Tooltip title={view_option.tooltip} mouseEnterDelay={0.5} placement='top'>
                                            <div className={(view_option.selected) ? 'active_view_option' : 'inactive_view_option'} onClick={() => {send_analytics({category: 'Quick Action Menu Toggled', button_id: view_option.id, button_action: view_option.active ? "OFF" : "ON", button_visibility: view_option.disabled ? "Disabled" : "Enabled"}); if(!view_option.disabled){ if(view_option.submenu) {view_specific_layers_onclick(view_option);} toolbar_on_click(view_option.id); }}} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '1', borderTop: '1px solid #8E8F9E', borderRight: '1px solid white', cursor: !(view_option.disabled) ? 'pointer' : 'not-allowed' }}>
                                                <Icons id={view_option.id} name={view_option.src} style={{ fontSize: '20px', opacity: !view_option.disabled ? '1' : '0.2', color: view_option.selected ? "#214097" : "#676878" }} />
                                            </div>
                                        </Tooltip>
                                    ))

                                    :''
                                }

                                <Tooltip title='Tool Guide' mouseEnterDelay={0.5} placement='top'>
                                    <div className= {selected_tool_guide ? 'active_view_option' : 'inactive_view_option'} onClick={ (e) => { set_selected_tool_guide(!selected_tool_guide) } } style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', aspectRatio: '1', borderTop: '1px solid #8E8F9E', borderRight:'1px solid #8E8F9E', borderLeft: '1px solid #8E8F9E'}}>
                                        <Icons name={'tool_guide'} style={{ fontSize: '20px', color: selected_tool_guide ? "#214097" : "#676878" }} />
                                    </div>
                                </Tooltip>
                                {/* <Isometric/> */}
                            </div>
                        </>
                    :''
                }

                <div className = "infurnia_logo" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', bottom: bottom_status_bar_show && !remote_view ? '4px' : 0, right: '0', bottom: '0px', fontSize: '13px', fontWeight: 'normal', width: '205px', gap: '4px', height: '36px', borderBottom: '1px solid #8E8F9E'}}>
                    <span style={{marginBottom: '-2px'}}> Powered By </span>
                    <img src="/resources/images/infurnia.png" id = "infurnia_logo_link" style = {{width : '90px'}}></img>
                </div>

                {
                    !remote_view && !is_view_locked() ?
                        camera_positioning_mode && perspective == 'floorplan' && interface_mode == 'design_edit_mode' ?
                        <div className="camera_input_container">
                            {/* <div className='slider_camera_container'> */}
                                {/* <AngleInput radius={10} className={'camera_rotation'}/> */}
                                <RotatableSlider radius={18} maxYPosition={100} minYPosition={0} InitialValue={camera_position} InitialAngle={camera_angle} canvasHeight={150} canvasWidth={50} update_function={update_camera_and_render_preview} camera_max={camera_max_height} camera_min={camera_min_height}/>
                                {/* <input
                                    type="range"
                                    min={camera_details && camera_details.min ? camera_details.min : 0}
                                    max={camera_details && camera_details.max ? camera_details.max : 100}
                                    step={1}
                                    defaultValue={camera_details ? camera_details.value : 60}
                                    // onChange={handleChange}
                                    // onMouseUp={on_click}
                                    className='slider_camera_position'
                                /> */}
                                {/* </div> */}
                                <div>
                                    <div className='flex_center'>
                                        <div style={{fontSize: '12px', cursor:'pointer'}} title='Height'>{'H'}</div>
                                        <input className='rotatable_slider_input'
                                            // type='number'
                                            min={0}
                                            max={10000}
                                            step={1}
                                            style={{ height: '24px', border: 'none', fontSize: '11px', padding: '0px 0px 0px 4px', textAlign:'center', width: '5ch', outline: 'none' }}
                                            value={Math.round(camera_position_input)}
                                            onBlur={(e) => { if(e.target.value >= camera_min_height && e.target.value <= camera_max_height){window.Module.rotatable_slider_set_camera_height(Number(e.target.value)); update_camera_position_value(); set_render_mini_url()}else{set_camera_position_input(camera_position_input_copy); setTimeout(() => {alert.error("Value not in range.")}, 0)} }}
                                            onChange={(e) => { set_camera_position_input(e.target.value) }}
                                            onKeyDown={(e) => {
                                                if (e.key == 'Enter') {
                                                    e.target.blur()
                                                } else if (e.key == 'Escape') {
                                                    set_camera_position_input(camera_position_input_copy)
                                                    e.target.blur()
                                                }
                                            }}>
                                        </input>
                                    </div>
                                    <div className='flex_center'>
                                        <div style={{fontSize: '12px', cursor: 'pointer'}} title='Angle'>{'<'}</div>
                                        <input className='rotatable_slider_input'
                                            style={{ height: '24px', border: 'none', fontSize: '11px', padding: '0px 2px 0px 4px', textAlign:'right', width: '4ch', outline: 'none'}}
                                            min={-90}
                                            max={90}
                                            step={1}
                                            value={camera_angle_input == '-' ? '-' :Math.round(((Number(camera_angle_input) ? Number(camera_angle_input) : 0) / Math.PI) * 180)}
                                            onBlur={(e) => { window.Module.rotatable_slider_change_3d_camera_vertical_angle(((e.target.value == '-' ? 0 : e.target.value) / 180) * Math.PI); update_camera_position_value(); set_render_mini_url() }}
                                            onChange={(e) => { if (e.target.value == '-' || e.target.value == '0-' || (e.target.value >= -90 && e.target.value <= 90)) { set_camera_angle_input(e.target.value == '-' || e.target.value == '0-' ? '-' : (Number(e.target.value) ? Number(e.target.value) : 0) / 180 * Math.PI) } }}
                                            onKeyDown={(e) => {
                                                if (e.key == 'Enter') {
                                                    e.target.blur()
                                                } else if (e.key == 'Escape') {
                                                    set_camera_angle_input(camera_angle_input_copy)
                                                    e.target.blur()
                                                }
                                            }}>
                                        </input>
                                        &deg;
                                    </div>
                                </div>
                                {/* <div>
                                    <div style={{height: '24px', fontSize: '12px'}}>{(camera_position_input.toFixed(2))}</div>
                                    <div style={{height: '24px', fontSize: '12px'}}>{(camera_angle_input/Math.PI * 180).toFixed(2)}</div>
                                </div> */}
                            <div className='camera_image_container flex_center' onClick={toggle_camera_positioning_mode} title='Exit Camera Position for Render'>
                                <img src="/resources/RotatableSliderIcons/camera_exit.svg" style={{ width: '24px' }}></img>
                            </div>
                        </div>
                        : perspective == 'floorplan' && interface_mode == 'design_edit_mode' ?
                        <div className='camera_entry_image_container flex_center' onClick={toggle_camera_positioning_mode} title='Set Camera Position for Render'>
                            <img src="/resources/RotatableSliderIcons/camera_enter.svg" style={{ width: '24px' }}></img>
                        </div>
                        :''
                    :''
                }

                {
                    (!remote_view && !seller_specific && !mes_editor) ?
                        camera_positioning_mode && perspective == 'floorplan' && interface_mode == 'design_edit_mode' ? 
                        <div className="render-mini-view">
                            <div>
                                <div className='flex_property justify-content-between mb-2 px-1' style={{fontSize: '10px'}}>
                                    <div>Camera of <b>{active_view_name}</b></div>
                                    <div>Aspect Ratio: <b>{aspect_ratios && aspect_ratios.active}</b></div>
                                </div>
                                <div style={{height:'152px', textAlign: 'center'}} className='mb-2'>
                                    <img src={render_preview_url} height={'100%'}></img>
                                </div>
                                <div style={{color: '#0078ff', cursor:'pointer', fontWeight: 500, textDecoration: 'underline', fontSize: '14px'}} onClick={() => {toolbar_on_click('show_rtx_render_request_modal');
                                    var analytics_data = {};
                                    analytics_data.category = "Fire Render Button Click (Mini Frame)";
                                    send_analytics(analytics_data,true);
                                }}>
                                    Fire Render
                                </div>
                            </div>
                        </div>
                        :''
                    :''
                }

                {
                    remote_view || is_comment_mode ?
                        <div onClick={toggle_comment_mode} className={"comment_logo " + (is_comment_mode ? 'comment_mode' : '')}>
                            <img src={is_comment_mode ? "/resources/comments_icons/comment_close.svg" : "/resources/comments_icons/comment_icon_in_share.svg"} id="comment_mode_icon" style={{ width: '24px', zIndex:2 }}></img>
                        </div>
                        :''
                }

                {
                    is_comment_mode && perspective == '3d' ?
                        comment_position_json && comment_position_json.length ? comment_position_json.map(comment => (
                            <div
                                key={comment.id}
                                style={{
                                    position: 'absolute',
                                    left: typeof(comment.x) == 'number' ? `${convertToAbsolutePosition(comment.x, canvasWidth) + canvasOffsetX}` + 'px' : 0,
                                    top: typeof(comment.y) == 'number' ? `${convertToAbsolutePosition(comment.y, canvasHeight) + canvasOffsetY}` + 'px' : 0,
                                    zIndex:1,
                                    display: comment.z > 1 ? 'none' : 'flex',
                                    cursor:'pointer',
                                    textTransform:'capitalize'
                                }}
                                className='flex_center comment-pin'
                                id={comment.id}
                                onClick={() => clicking_of_comment(comment)}
                                onScroll={(e) => e.preventDefault()}
                            >
                                <div className='comment-user'>{comment.user[0]}</div>
                                <div className='comment-preview'>{comment.comment}</div>
                            </div>
                        )) :''
                        // <div className={"comment_logo " + (is_comment_mode ? 'comment_mode' : '')}>
                        //     <img src="/resources/comments_icons/comment_icon_in_share.svg" id="comment_mode_icon" style={{ width: '24px' }}></img>
                        // </div>
                        : ''
                }
                
                {
                    // bottom_status_bar_show && !remote_view ?
                    //     !bottom_status_bar_collapsed && bottom_status_bar_message ?
                    //         <React.Fragment>
                    //             <div className="bottom_status_bar DMSans" style={{ position: 'absolute', left: 'calc( 50% + 135px )', width: 'calc( 50% - 135px - 225px )', zIndex: 3 }}>
                    //                 <Trapezium height={32} rightTriangleBorderXWidth={8} backgroundColor={'white'} text={bottom_status_bar_message} style={{ color: 'black', fontSize: '12px', lineHeight: 1.1 }} style_inner={{ borderTop: '1px solid var(--bottom_status_bar_border_color)', padding: '0px 30px 0px 30px', textAlign:'left' }}></Trapezium>
                    //             </div>
                    //             <div className="bottom_status_bar" style={{ position: 'absolute', left: 'calc( 50% + 134px )', width: 'calc( 50% - 135px - 225px + 2px)' }}>
                    //                 <Trapezium height={32} rightTriangleBorderXWidth={8} backgroundColor={'var(--bottom_status_bar_border_color)'} text={bottom_status_bar_message} style={{ color: 'black', fontSize: '12px', lineHeight: 1.1 }}></Trapezium>
                    //             </div>
                    //             <div className='bottom_status_bar' style={{ height: 'var(--bottom_status_bar_offset_on_logo)', backgroundColor: 'white', position: 'absolute', bottom: 0, right: 0, width: '240px', zIndex: 2, borderTop: '1px solid var(--bottom_status_bar_border_color)' }}></div>
                    //             <img src='/resources/icons/bottom_status_bar_collapse.svg' onClick={toggle_bottom_status_bar_collapsed} style={{left: 'calc( 50% + 145px )', bottom: '4.6px', zIndex: 4, position: 'absolute', cursor: 'pointer'}}/>
                    //             {/* Help center to be not shown when no URL is provided and when org is white-labelled*/}
                    //             {help_center_url && window.Module.is_feature_enabled('help_center_popup') ? <img width={'24px'} height={'24px'} className='hover_toolbar' src={show_help_center ? '/resources/icons/expand_down.svg' : '/resources/icons/expand_up.svg'} onClick={toggle_help_menu} style={{right: 'calc( 225px + 24px )', bottom: '4px', zIndex: 4, position: 'absolute', cursor: 'pointer', padding: '2px', borderRadius: '1px'}}/> : ''}
                    //         </React.Fragment>
                    //     : bottom_status_bar_collapsed ? 
                    //         <React.Fragment>
                    //             <div className="bottom_status_bar DMSans" style={{ position: 'absolute', right: '225px', width: '114px', zIndex: 3 }}>
                    //                 <Trapezium height={32} rightTriangleBorderXWidth={8} backgroundColor={'white'} text={""} style={{ color: 'black', fontSize: '12px' }} style_inner={{ borderTop: '1px solid var(--bottom_status_bar_border_color)', padding: '0px 12px' }}></Trapezium>
                    //             </div>
                    //             <div className="bottom_status_bar" style={{ position: 'absolute', right: '224px', width: '116px' }}>
                    //                 <Trapezium height={32} rightTriangleBorderXWidth={8} backgroundColor={'var(--bottom_status_bar_border_color)'} text={""} style={{ color: 'black', fontSize: '12px' }}></Trapezium>
                    //             </div>
                    //             {/* <div className="bottom_status_bar" style={{ position: 'absolute', right: '225px', width: '100px', scale: '0.9', bottom: '1.6px', zIndex: 4 }}>
                    //                 <Trapezium height={32} backgroundColor={'var(--bottom_status_bar_border_color)'} text={"Tool Guide"} style={{ color: 'black', fontSize: '12px' }}></Trapezium>
                    //             </div> */}
                    //             <img src='/resources/icons/tool_guide.svg' onClick={toggle_bottom_status_bar_collapsed} style={{right: '231px', bottom: '1.6px', zIndex: 4, position: 'absolute', cursor: 'pointer'}}/>
                    //             <div className='bottom_status_bar' style={{ height: 'var(--bottom_status_bar_offset_on_logo)', backgroundColor: 'white', position: 'absolute', bottom: 0, right: 0, width: '240px', zIndex: 2, borderTop: '1px solid var(--bottom_status_bar_border_color)' }}></div>
                    //         </React.Fragment>
                    //     : 
                    //         <React.Fragment>
                    //             <div className="bottom_status_bar DMSans" style={{ position: 'absolute', left: 'calc( 50% + 135px )', width: 'calc( 50% - 135px - 225px )', zIndex: 3 }}>
                    //                 <Trapezium height={32} rightTriangleBorderXWidth={8} backgroundColor={'white'} text={""} style={{ color: 'black', fontSize: '12px' }} style_inner={{ borderTop: '1px solid var(--bottom_status_bar_border_color)', padding: '0px 12px 0px 30px', textAlign:'left' }}></Trapezium>
                    //                 <div className='lines1_elipsis bottom_status_bar_default_state' title='Suggestions will appear here when you select a tool.' style={{maxWidth: '100%', background: '#E6E9EE', color: '#676878'}}>
                    //                     <img src='/resources/icons/bottom_status_bar_collapse.svg' onClick={toggle_bottom_status_bar_collapsed} style={{cursor: 'pointer'}}/>
                    //                     <div className='lines1_elipsis' style={{flex: 1, minWidth:'12px'}}>Suggestions will appear here when you select a tool.</div>    
                    //                 </div>
                    //             </div>
                    //             <div className="bottom_status_bar" style={{ position: 'absolute', left: 'calc( 50% + 134px )', width: 'calc( 50% - 135px - 225px + 2px)' }}>
                    //                 <Trapezium height={32} rightTriangleBorderXWidth={8} backgroundColor={'var(--bottom_status_bar_border_color)'} text={"Suggestions will appear here when you select a tool."} style={{ color: 'black', fontSize: '12px' }}></Trapezium>
                    //             </div>
                    //             <div className='bottom_status_bar' style={{ height: 'var(--bottom_status_bar_offset_on_logo)', backgroundColor: 'white', position: 'absolute', bottom: 0, right: 0, width: '240px', zIndex: 2, borderTop: '1px solid var(--bottom_status_bar_border_color)' }}></div>
                    //             {/* <img src='/resources/icons/bottom_status_bar_collapse.svg' onClick={toggle_bottom_status_bar_collapsed} style={{left: 'calc( 50% + 145px )', bottom: '4.6px', zIndex: 4, position: 'absolute', cursor: 'pointer'}}/> */}
                    //         </React.Fragment>
                    //     :''
                }

                {
                    show_help_center && bottom_status_bar_show?
                        <div id='infurnia_help_center' style={{ position: 'absolute', boxShadow: '0px 4px 16px 0px #00000040', height: (remote_view || is_comment_mode) ? 'calc(100% - 52px - 40px - 8px - 36px)' : 'calc(100% - 136px - 40px - 8px - 36px)', width: 'calc( 50% - 135px - 8px )', right: '8px', bottom: '76px', zIndex: 5, padding: '40px', borderRadius: '8px' }}>
                            <img onClick={toggle_help_menu} style={{ cursor: 'pointer', position: 'absolute', right: '1rem', top: '1rem' }} height={'12px'} width={'12px'} src={'/resources/icons/modal_cross.svg'}></img>
                            <a style={{ display: 'flex', cursor: 'pointer', position: 'absolute', right: '2.5rem', top: '1rem' }} href={help_center_url || "https://help.infurnia.com/en/collections/6276842-design"} target='_blank'><img height={'12px'} width={'12px'} src={'/resources/nav_icons_new/newtab.svg'}></img></a>
                            <iframe style={{ border: 'none', width: '100%', height: '100%' }} src={help_center_url || "https://help.infurnia.com/en/collections/6276842-design"}></iframe>
                        </div>
                        : ''
                }

                {!remote_view && !is_comment_mode && (
                    <div style={{position:'absolute',top:'76px',left:'0px',width: '100%', borderBottom: '0.5px solid ' + COLORS.gray3, zIndex: 2}}>
                        <DynamicToolbar handle_ui_response={handle_ui_response} update_view={update_view} json_file={toolbar_json_file && toolbar_json_file.length && toolbar_json_file.filter(item => item.active == 'true').length ? toolbar_json_file.filter(item => item.active == 'true')[0] : []} set_page_loader={set_page_loader} />
                    </div>
                )}

                {
                    seller_specific && interface_mode == "design_edit_mode" ? 
                    <div style={{position:'absolute', right:'24px', top:'50%', transform:'translateY(-50)', display:'flex', flexDirection:'column'}}>
                        {is_seller_component_a_user_element()?(
                            <div onClick={show_seller_parameters_modal} className='canvas_icons_container flex_center mb-3' style={{flexDirection:'column'}}>
                                <img width="24px" className='mb-1' src='/resources/icons/parametric.svg'></img>
                                <div className='lines1_elipsis' title='Parameters'>{t(`Param..`)}</div>
                            </div>
                        ):''}
                        <div onClick={component_editor_save_onclick} className='canvas_icons_container flex_center mb-3' style={{flexDirection:'column'}}>
                            <img width="24px" className='mb-1' src='/resources/icons/save.svg'></img>
                            <div>{t(`Save`)}</div>
                        </div>
                        <div onClick={refresh_seller_sku} className='canvas_icons_container flex_center' style={{flexDirection:'column'}}>
                            <img width="24px" className='mb-1' src='/resources/icons/reset.svg'></img>
                            <div>{t(`Reset`)}</div>
                        </div>
                    </div>
                    :''
                }
                {
                    show_command_line ? 
                    <React.Fragment>
                        <div className='command_line'>
                            <img src={command_line_icon!=''?command_line_icon:'/resources/icons/command_line_icon.svg'} height='28px' />
                            <Input id='command_line_text' placeholder='command / search' value={command_line_content} onChange={(e) => onchange_command(e.target.value)} className='command_line_text' onBlur={command_line_blur} onKeyDown={handleKeyDown}></Input>
                            {
                                command_line_valid ?
                                <div className='command_valid'>
                                    <img src='/resources/icons/enter.svg' height='14px' width='14px' />
                                </div>:""
                            }
                        </div>
                        <div className='command_line_options'>
                        {
                            command_line_options && command_line_options.length ? command_line_options.map((single_option, idx) => (
                                <div key={idx} onMouseDown={command_line_mousedown} onClick={() => handleOptionSelect(single_option)} id={'command_line_option'+idx} className={'command_line_option'} style={{outline:focusedOptionIndex===idx?'2px solid #0078FF':'', outlineOffset: '-2px', opacity: single_option.disabled ? '0.5' : '1' , display: idx > 3 && !command_line_view_more ? 'none' : 'flex'}}>
                                    <img src={single_option.icon} style={{marginRight:'10px'}} className='command_line_option_icon'/>
                                    <div className='command_line_option_text'>{single_option.content}</div>
                                </div>
                            )):''
                        }
                            <div onMouseDown={(e) => {e.stopPropagation(); e.preventDefault(); onclick_view_more()}} className={'command_line_option'} style={{border:focusedOptionIndex===4?'2px solid #0078FF':'', outlineOffset: '-2px', display: command_line_options && command_line_options.length > 4 && !command_line_view_more ? 'flex' : 'none', justifyContent:"center" }}>
                                <div className='command_line_option_text'>{t(`View more`)}</div>
                            </div>
                        </div>
                    </React.Fragment>:""
                }
                {/* <div className='flex_center' style={{display:((interface_mode=="design_edit_mode" && !seller_specific)?"flex":"none"), position:'absolute', bottom:'8px', left:remote_view && !is_comment_mode?'8px':'278px', height:'39px', borderRadius:'4px', zIndex: 4, background: 'white', border: '1px solid ' + COLORS.gray3}}>
                    <UncontrolledDropdown direction='up'>
                        <DropdownToggle nav style={{ padding: '4px 8px', border: 0, backgroundColor: 'transparent' }}>
                            <img src='/resources/toolbar_icons_new/view_settings.svg' height='24px'></img>
                        </DropdownToggle>
                        <DropdownMenu className='free_view_dropdown4'>
                            {
                                common_viewport_options && common_viewport_options.length ? common_viewport_options.map((x, idx) => (
                                    <DropdownItem disabled={x.disabled} title={x.disabled_message ? x.disabled_message : ''} key={idx} onClick={x.disabled ? null : (e) => { toolbar_on_click(x.id)  }} style={{ fontSize: '13px', pointerEvents: 'auto', paddingLeft: '6px', paddingRight: '6px' }}><div className='flex_property'> <i className={x.selected ? 'fa fa-check-square' : 'fa fa-square-o'} style={{ marginRight: '8px', color: x.selected ? '#0078ff' : '#C5C7CF' }}></i> <img src={x.src} className='mr-2' height={"16px"}></img>  {x.name} </div></DropdownItem>
                                ))
                                    : ''
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div> */}
                <div className='DMSans' id='all_views_div_parent' style={{display:((interface_mode=="design_edit_mode" && !seller_specific && !mes_editor)?"flex":"none"), position:'absolute', bottom:'0px', left:remote_view && !is_comment_mode?'0px':'270px', height:'36px', backgroundColor:COLORS.active_view_background_inactive, borderRadius:'4px', zIndex: 4, maxWidth:'calc(50% - 150px)'}}>
                            
                    <div className='ant-dropdown-tour' style={{display: 'flex'}}>
                        <AntDropdown
                            menu={{items:workbench_menu, expandIcon: <img src='/resources/view_bar_icons/toggle_view_manager.svg'/>}} trigger={['click']}
                            style={{height: '100%'}}
                        >
                            <a onClick={(e) => e.preventDefault()} style={{height: '100%'}}>
                                <img src='/resources/view_bar_icons/workbench.svg' style={{height: '100%', cursor: 'pointer'}}></img>
                            </a>
                        </AntDropdown>
                        <div id='free_view_div' className={is_free_view_active?'active_view_container flex_property':'flex_property'} style={{borderTop: is_free_view_active ? '' : '1px solid #8E8F9E', borderBottom: is_free_view_active ? '1px solid #8E8F9E' : '', height:'100%', /*borderBottom: is_free_view_active ? '1px solid #0078FF' : ''*/ borderRight: '1px solid #C5C7CF', borderLeft: '2px solid ' + COLORS.gray3}}>
								<div className={is_free_view_active?'active_view flex_center':'flex_center inactive_view'} style={{fontSize:'12px', color:COLORS.active_view_text, padding:'4px 6px', height:'100%', textDecoration:'none', cursor:is_free_view_active?'':'pointer', borderTopLeftRadius:'4px', borderBottomLeftRadius:'4px', whiteSpace:'nowrap', overflow: 'hidden'}} onClick={(e) => switch_free_view(e)}>
                                    <div className={is_free_view_active?'active_view':'inactive_view'} style={{display: 'flex', alignItems: 'center'}}>
                                                {/* {is_free_view_active? <img src='/resources/view_bar_icons/view_lock.svg' style={{padding: '4px 8px 4px 4px'}}></img> : ''} */}
                                                <img src='/resources/view_bar_icons/workbench_type.svg' style={{padding: '4px 0px 4px 0'}}></img>
                                    </div>
                                    <div style={{maxWidth: '100%', padding: '4px 8px 4px 8px'}}>Free View</div>
                                </div>
								{/* {
									is_free_view_active ?
										<div className='active_view flex_property' style={{padding:'4px 8px 4px 0px', height:'100%',}}>
											<div className='flex_property' style={{backgroundColor: '#F6F7F9', borderRadius:'4px'}}>
												<div className='flex_property' style={{height:'100%'}}>
													{
														view_options && is_free_view_active && view_options.length ? view_options.map((x,idx) => (
															x.src && !x.disabled ? 
																<img height={'16px'} className={'mr-2' + (idx == 0 ? ' ml-2' : '')} key={idx} src={x.src} title={x.tooltip_icon ? x.tooltip_icon : ''}></img> 
															: ''
														))
														:''
													}
												</div>
												<Dropdown direction='up' nav isOpen={dropdownOpen} toggle={toggle} className='flex_center' style={{height:'100%', position:'unset'}}>
													<DropdownToggle nav style={{fontSize:'9px', padding:'4px 8px 4px 0px', border:0, backgroundColor:'transparent'}}>
														<img src='/resources/icons/cog_blue_bg.svg' height='16px'></img>
													</DropdownToggle>
													<DropdownMenu className='free_view_dropdown1' style={{padding:'4px', marginBottom:'9px', marginLeft:'-55px', }}>
														{
															view_options && view_options.length ? view_options.map((x,idx) => (
																x.id === 'lock_view' ? '' : <DropdownItem toggle={false} disabled={x.disabled} title={x.disabled_message ? x.disabled_message : ''} key={idx} onClick={x.disabled ? null : (e) => {toolbar_on_click(x.id)}} style={{fontSize:'13px', pointerEvents:'auto', paddingLeft:'6px', paddingRight:'6px'}}><span> <i className={x.selected ? 'fa fa-check-square' : 'fa fa-square-o'} style={{marginRight: '4px', color:x.selected ? '#0078ff' : '#C5C7CF'}}></i>  {x.name} </span></DropdownItem>
															))
															:''
														}
													</DropdownMenu>
												</Dropdown>
											</div>
										</div>
									:''
								} */}
						</div>
                    <div>
					<Nav  className='all_views_div_outer' id='all_views_div_outer' tabs style={{height:'100%', borderRadius:'4px', border:'none', flexWrap:'nowrap', position:'relative'}}>
						{/* <NavItem className="black_white_nav_item flex_center" style={{height: '100%', borderLeft: '1px solid rgba(228, 228, 227, 1)', borderRight:'1px solid rgba(228, 228, 227, 1)', borderTopLeftRadius:'4px', borderBottomLeftRadius:'4px', overflow:'hidden'}} onClick={(e) => switch_free_view(e)}>
							<a className={is_free_view_active?'active_view flex_center':'flex_center inactive_view'} style={{fontSize:'12px', color:COLORS.active_view_text, padding:'4px 10px 4px 10px', height:'100%', textDecoration:'none'}} href="#" active="true">Free View</a>
                            <div className='active_view' style={{height:'100%'}}>
                                {
                                    view_options && is_free_view_active && view_options.length ? view_options.map((x,idx) => (
                                        x.src ? 
                                            <img height={'16px'} className='mr-2 active_view' key={idx} src={x.src}></img> 
                                        : ''
                                    ))
                                    :''
                                }
                            </div>
                            {
                                is_free_view_active ? 
                                <Dropdown nav isOpen={dropdownOpen} toggle={toggle} className='flex_center' style={{height:'100%', backgroundColor:'white'}}>
                                    <DropdownToggle nav className={is_free_view_active?'active_view':''} style={{fontSize:'9px', padding:'4px 10px 4px 0px', border:0, backgroundColor:'transparent'}}>
                                        <img src='/resources/icons/cog_blue_bg.svg' height='16px'></img>
                                    </DropdownToggle>
                                    <DropdownMenu style={{padding:'4px', marginBottom:'9px', marginLeft:'-55px'}}>
                                        {
                                            view_options && view_options.length ? view_options.map((x,idx) => (
                                                <DropdownItem toggle={false} disabled={x.disabled} title={x.disabled_message ? x.disabled_message : ''} key={idx} onClick={x.disabled ? null : (e) => {toolbar_on_click(x.id);set_view_options(JSON.parse(window.Module.get_view_specific_options()));update_view()}} style={{fontSize:'13px', pointerEvents:'auto'}}><span> <i className={x.selected ? 'fa fa-check-square' : 'fa fa-square-o'} style={{marginRight: '4px'}}></i>  {x.name} </span></DropdownItem>
                                            ))
                                            :''
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                                : ''
                            }
						</NavItem> */}
						{
                            show_arrows ?
                            <NavItem className='flex_center' style={{height: '100%', /*borderLeft: '1px solid rgba(228, 228, 227, 1)', borderRight:'1px solid rgba(228, 228, 227, 1)',*/ backgroundColor:COLORS.panel_header_background, borderTop: '1px solid #8E8F9E'}}>
                                <a className='scale_110' onClick={() => {document.getElementById('all_views_div_inner').scrollBy({top: 0,left: -160,behavior: 'smooth'})}} style={{ fontSize:'24px', color:COLORS.white, padding:'0px 10px', textDecoration:'none'}} href="#" active="true">
                                    <img src='/resources/icons/left_arrow.svg' />
                                </a>
                            </NavItem>:''
                        }
                        {/* {
                            !isActiveViewVisible && scrollToActiveViewButtonPosition === 'left' ?
                                <Tooltip title={'Click to scroll to active view'} mouseEnterDelay={0.3}>
                                    <NavItem className='flex_center' onClick={scrollToActiveView} style={{ height: '100%', borderLeft: '1px solid rgba(228, 228, 227, 1)', borderBottom: '1px solid #0078ff', backgroundColor: COLORS.white, cursor: 'pointer' }}>
                                        <div style={{ width: '16px' }}></div>
                                    </NavItem>
                                </Tooltip>
                            :''
                        } */}
                        <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction='horizontal'>
                        {(provided, snapshot) => (
                        <div className='all_views_div_inner flex_property' id='all_views_div_inner' onScroll={updateButtonVisibility} {...provided.droppableProps} ref={provided.innerRef} style={{flexWrap:'nowrap', overflow:'auto', height:'100%', maxWidth: `${canvasWidth/2 - 100}px`}}>
							{design_views_open.map((view,idx) => (
                                <Draggable styles={{height:'100%'}} key={view.id} draggableId={view.id} index={idx}>
                                    {(provided) => (
                                    <div style={{height:'100%'}} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <DesignView_Dropdown
                                            submit_for_delete_confirm={submit_for_delete_confirm}
                                            handle_ui_response={handle_ui_response}
                                            key={idx}
                                            idx={idx}
                                            view={view}
                                            set_all_design_views = {set_design_views}
                                            all_design_views = {design_views}
                                            number_of_views={design_views_open.length}
                                            update_view={update_view}
                                            perspective={perspective}
                                            switch_free_view={switch_free_view}
                                            remote_view={remote_view}
                                        />
                                    </div>
                                    )}
                                </Draggable>
							))}
                            {provided.placeholder}
						</div>
                        )}
                        </Droppable>
                        </DragDropContext>
                        {/* {
                            !isActiveViewVisible && scrollToActiveViewButtonPosition === 'right'?
                                <Tooltip title={'Click to scroll to active view'} mouseEnterDelay={0.3}>
                                    <NavItem className='flex_center' onClick={scrollToActiveView} style={{ height: '100%', borderLeft: '1px solid rgba(228, 228, 227, 1)', borderBottom: '1px solid #0078ff', backgroundColor: COLORS.white, cursor: 'pointer' }}>
                                        <div style={{ width: '16px' }}></div>
                                    </NavItem>
                                </Tooltip>
                            :''
                        } */}
						{
							show_arrows ?
							<NavItem className='flex_center' style={{height: '100%', borderLeft: '1px solid rgba(228, 228, 227, 1)', /*borderRight:'1px solid rgba(228, 228, 227, 1)',*/ backgroundColor:COLORS.panel_header_background, borderTop: '1px solid #8E8F9E'}}>
								<a className='scale_110' onClick={() => {document.getElementById('all_views_div_inner').scrollBy({top: 0,left: 160,behavior: 'smooth'})}} style={{ fontSize:'24px', color:COLORS.white, padding:'0px 10px', textDecoration:'none'}} href="#" active="true">
									<img src='/resources/icons/right_arrow.svg' />
								</a>
							</NavItem>:''
						}
                        <Tooltip title={'Click to add new view'} mouseEnterDelay={0.3}>
                            <NavItem className='flex_center add_view_button' style={{height: '100%', borderLeft: '1px solid rgba(228, 228, 227, 1)', /*borderRight:'1px solid rgba(228, 228, 227, 1)',*/ backgroundColor:COLORS.panel_header_background, borderTop: '1px solid #8E8F9E', display: remote_view ? 'none' : ''}}>
                                <a onClick={add_design_view} style={{ fontSize:'24px', color:COLORS.white, padding:'0px 10px', textDecoration:'none'}} href="#" active="true">+</a>
                            </NavItem>
                        </Tooltip>
					</Nav>
                    </div>
                    </div>
                    
			    </div>

                {
                    (remote_view && !is_comment_mode) ?
                        (
                            <div className='px-3 py-2 flex_center DMSans' onClick={() => {toggle_show_share_navigation_info()}} style={{ position: 'absolute', top: `calc( 100% - ${canvasHeight - 32}px )`, left: '24px', fontSize: '14px', fontStyle: 'italic', fontWeight: 700, color: '#676878', cursor: 'pointer', boxShadow: '0px 1px 16px 0px #00000033', background: 'white', borderRadius: '6px', minWidth: '132px'}}>
                                {show_share_navigation_info ? "Hide UI Guide" : "Show UI Guide"}
                            </div>
                        )
                        : ''
                }

                {
                    (remote_view && show_share_navigation_info && !is_comment_mode) ?
                        (
                            <div className='px-3 my-3 navigation_info_share_view' style={{ position: 'absolute', top: `calc( 100% - ${canvasHeight / 2}px )`, transform: 'translateY(-50%)', left: '24px' }}>
                                <NavigationInfo perspective={perspective} remote_view={remote_view} panel_info_setting={panel_info_setting} set_panel_info_setting={set_panel_info_setting} />
                            </div>
                        )
                        : ''
                }

                {!(remote_view && !is_comment_mode) && (<div style={{margin: '0px', width:"100%", maxWidth:"none",height:'100%', overflowY:'auto'}} >
                    
                    <Panel 
                        set_modal_mode={set_modal_mode}
                        seller_specific={seller_specific} 
                        mes_editor={mes_editor}
                        panel_key={panel_key} 
                        key={panel_key} 
                        set_page_loader={set_page_loader} 
                        handle_ui_response={handle_ui_response} 
                        metric_system={metric_system} 
                        approximation_mm={approximation_mm} 
                        approximation_ft={"1/16_inch"}
                        // approximation_ft={approximation_ft} 
                        update_view={update_view} 
                        // panel_json={dummy_panel_json} 
                        panel_json={panel_json} 
                        set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} 
                        panel_heading={panel_heading} 
                        panel_show={panel_show} 
                        set_active_left_menu_item={set_active_left_menu_item}
                        scroll_pos={scroll_pos}
                        set_scroll_pos={set_scroll_pos}
                        approxMM={approxMM}
                        mmToFeetParts={mmToFeetParts}
                        panel_collapsed={panel_collapsed}
                        json_file={panel_toolbar_json}
                        panel_explode={panel_explode}
                        project_name={project_name}
                        project_client_name={client_name}
                        project_id={project_id}
                        design_name={design_name}
                        panel_show_tree={panel_show_tree}
                        selected_index={selected_index}
                        set_selected_index={set_selected_index}
                        panel_json_id={panel_json_id}
                        set_panel_json_id = {set_panel_json_id}
                        show_tree={show_tree}
                        set_show_tree={set_show_tree}
                        panel_has_unapplied_edits={panel_has_unapplied_edits}
                        panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string}
                        panel_heading_label={panel_heading_label}
                        panel_heading_icon={panel_heading_icon}
                        panel_heading_icon_route={panel_heading_icon_route}
                        panel_json_default_tree_selection={panel_json_default_tree_selection}
                        perspective={perspective}
                        show_panel_info_at_bottom={show_panel_info_at_bottom}
                        panel_info_list={panel_info_list}
                        is_comment_mode={is_comment_mode}
                        panel_info_setting={panel_info_setting}
                        set_panel_info_setting={set_panel_info_setting}
                        panel_pop_up_show={panel_pop_up_show}
                        // panel_heading_back_show={panel_heading_back_show}
                        panel_current_page_heading={panel_current_page_heading}
                        panel_current_page_show={panel_current_page_show}
                        panel_current_page_json={panel_current_page_json}
                        panel_current_page_key={panel_current_page_key}
                        set_panel_current_page_has_unapplied_edits={set_panel_current_page_has_unapplied_edits}

                    />

                    {/* <div onClick={() => set_show_tree(false)} style={{position:'absolute', width:'270px', height:seller_specific? 'calc(100% - 94px)': 'calc(100% - 178px)', top:seller_specific ? '94px' : '178px', left:seller_specific?'0px':'0px', display: show_tree ? 'block' : 'none', backgroundColor:'rgba(0,0,0,0.2)', zIndex:10}}></div> */}
                    <div style={{position:'absolute', width:'270px', height:/*seller_specific? 'calc(100% - 100px)': */'calc(100% - 178px)', top:/*seller_specific ? '52px' : */'136px', left:seller_specific?'0px':'0px', display: panel_pop_up_show ? 'block' : 'none', backgroundColor:'rgba(0,0,0,0.2)', zIndex:6}}></div>

                   <PanelPopup 
                        seller_specific={seller_specific} 
                        panel_key={panel_pop_up_key} 
                        key={panel_pop_up_key} 
                        set_page_loader={set_page_loader} 
                        handle_ui_response={handle_ui_response} 
                        metric_system={metric_system} 
                        approximation_mm={approximation_mm} 
                        approximation_ft={"1/16_inch"} 
                        // approximation_ft={approximation_ft} 
                        update_view={update_view} 
                        panel_json={panel_pop_up_json} 
                        perspective={perspective}
                        set_panel_has_unapplied_edits={set_panel_pop_up_has_unapplied_edits} 
                        panel_heading={panel_pop_up_heading} 
                        panel_show={panel_pop_up_show} 
                        set_active_left_menu_item={set_active_left_menu_item} 
                        scroll_pos={scroll_pos_pop_up} 
                        set_scroll_pos={set_scroll_pos_pop_up} 
                        approxMM={approxMM} 
                        mmToFeetParts={mmToFeetParts} 
                        project_name={project_name} 
                        project_client_name={client_name} 
                        project_id={project_id} 
                        design_name={design_name} 
                        set_modal_mode = {set_modal_mode}
                        set_layerset_mode = {set_layerset_mode}
                        set_given_layerset = {set_given_layerset}
                        panel_has_unapplied_edits={panel_pop_up_has_unapplied_edits}
                        panel_search_json={panel_pop_up_search_json} is_search_mode={is_search_mode_panel_pop_up && panel_pop_up_heading == 'Preferences'} set_is_search_mode={set_is_search_mode_panel_pop_up} search_string_panel={search_string_panel_pop_up} set_search_string_panel={set_search_string_panel_pop_up} panel_search_string={panel_pop_up_search_string}
                    />
                   {/* {!seller_specific && (<Left_menu handle_ui_response={handle_ui_response} update_view={update_view} json_file={left_menu_json_file} active_left_menu_item={active_left_menu_item} set_active_left_menu_item={set_active_left_menu_item} set_page_loader={set_page_loader} />)} */}

                   <div style= {{backgroundColor:"#e6f7ff"}}></div>
                    
                </div>)}
                {
                    carousel_show ?
                    <BottomCarousel
                        active_category_id={active_category_id}
                        set_active_category_id={set_active_category_id}
                        carousel_active_tab={carousel_active_tab}
                        set_carousel_active_tab={set_carousel_active_tab}
                
                        carousel_eligible_categories={carousel_eligible_categories}
                        carousel_subcategories={carousel_subcategories}
                
                        carousel_sku_parameters_show={carousel_sku_parameters_show}
                        set_carousel_sku_parameters_show={set_carousel_sku_parameters_show}
                        carousel_sku_json={carousel_sku_json}
                
                        carousel_onclick={carousel_onclick}
                        carousel_onclick_stringified_json={carousel_onclick_stringified_json}
                
                        carousel_search_mode={carousel_search_mode}
                        carousel_search_string={carousel_search_string}
                        set_carousel_search_string={set_carousel_search_string}
                        carousel_search_category_filter_options={carousel_search_category_filter_options}
                        carousel_search_category_filter_value={carousel_search_category_filter_value}
                        set_carousel_search_category_filter_value={set_carousel_search_category_filter_value}
                        carousel_search_sub_category_filter_options={carousel_search_sub_category_filter_options}
                        carousel_search_sub_category_filter_value={carousel_search_sub_category_filter_value}
                        set_carousel_search_sub_category_filter_value={set_carousel_search_sub_category_filter_value}
                
                        caraousel_loading={caraousel_loading}
                        set_caraousel_loading={set_caraousel_loading}
                        carousel_minimize={carousel_minimize}
                
                        modal_type={modal_properties.type}
                        update_carousel_json={update_carousel_json}
                        update_view={update_view}
                        handle_ui_response={handle_ui_response}
                        set_page_loader={set_page_loader}
                
                        perspective={perspective}
                        panel_pop_up_show={panel_pop_up_show}
                        repopulate_panel={repopulate_panel}
                        repopulate_panel_pop_up={repopulate_panel_pop_up}
                    />
                        : ''
                }

                {
					interface_mode=="design_edit_mode" && mes_editor ? 
					<div style={{position:'absolute', top:'140px', right:'4px', zIndex:'1001', height:'calc(100vh - 140px)', display:'flex'}}>
						<div style={{alignSelf:'flex-end', marginRight:'12px', backgroundColor:COLORS.gray2, color:COLORS.gray6, borderRadius:'6px', padding:'4px 8px', marginBottom:'4px'}}>All changes are auto saved</div>
						<div style={{height:'100%', flexDirection:'column', padding:'0px 4px', backgroundColor:'white', borderRadius:'8px'}} className='flex_center'>
							<div className="p-3 px-3 text-sm text-inf_theme_gray_500">Panels ({mes_component && mes_component.panels && mes_component.panels.length})</div>
							<div style={{flex:1, minHeight:'0px', overflow:'auto'}}>
							{
								mes_component && mes_component.panels && mes_component.panels.length ? mes_component.panels.map(panel => (
									<div id={"modular_elements_panel_listing_div_" + panel.id} className={`mb-3 p-2 mx-2 mes_panel`} style={{border:((active_panel && active_panel.id===panel.id) || core_selection_ids.includes(panel.id)) ? '2px solid #676878' : '', }} onClick={() => {window.Module.mes_select_element_by_id(panel.id);}}>
										<div className='mb-2' style={{color: COLORS.gray9, fontWeight:700}}>{panel.name}</div>
										<div className="flex_between  mb-2">
											<div>{to_fixed(panel.width) + ' W * ' + to_fixed(panel.depth) + ' D * ' + to_fixed(panel.thickness) + ' T'}</div>
											<div style={{fontSize:'12px', color: COLORS.gray9, marginLeft:'16px'}}>({panel.unique_label})</div>
										</div>
									</div>)):''
							}
							</div>
						</div>
					</div>:''
				}

                
            </div>
        </div>    
    );
}

export default EditDesign;
